

export class Customer{

    customerId: number;
    email:string;
    firstName: string;
    lastname: string;
    middlename: string = "";
    company = "";
    storeId: number;
    phoneNumber: string;
    token: string;
    stripeCustomerId: string;
    storeCode: string;
    udidString: string;
    storePhone: string;
    customerAttributes : CustomerAttributes;
}
export class UpdateCustomerAttributesRequest {
    customerAttributes:CustomerAttributes ;
	storeSummary:StoreSummary ;
    customerId:number;
}
export class StoreSummary {

}

export class CustomerAttributes {
    addressDetails:Array<Address>; 
}

export class Address {
    id : number;
	tag : string;
	fieldOne : string;
	fieldTwo : string;
	city : string;
	state : string;
	zip : string;
	country : string;
	isDefault: boolean = false;
	latitude: number;
	longitude: number;
	distance: number;

}