
export class OrderReportCriteria {
    storeId:number;
    startDate: Date;
    endDate:Date;
}

export class MenuCriteria {
    storeId:number;
    categoryName:string;
}

export class PrebookReportCriteria {
    menuIds:number[];
    startDate: Date;
    endDate:Date;
    menuCode:string;
}	

export class ReportDataUpdate{
    orgPrice:number;
    salePrice:number;
	changeMasterRecord: boolean;
	changeForDate: Date;
	menuId: number;
}
