export class ExpenseItem {
   
    id:number;
	name: string;
	description:string;
	isActive: number;
    frequency: number;
	expenseType: number;
	createdDate: Date;
	endDate: Date;
	frequencyString: string;
	expenseTypeString:string;
	amount:number;
}

export class ExpenseType {
    id: number;
    name: string
}

export class ExpenseFrequency {
    id: number;
    name: string
}