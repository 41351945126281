<p>test works!</p>


<mat-tab-group style="width: 100%;" >
    <mat-tab label = "Customer">
<br>
<div style="padding-left: 40px;">
    <div  *ngIf="isLoading">
        <button mat-button>
          <img src="assets/loading.gif"  width="35px" height="35px"/>
        </button>
      </div>
        <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="fill">
                <mat-label >firstname</mat-label>
                <input matInput placeholder="Placeholder" formControlName="firstname" required ng-valid> 
              </mat-form-field>
              &nbsp;
              <br>
            <mat-form-field appearance="fill">
              <mat-label >Phone</mat-label>
              <input matInput placeholder="Placeholder" formControlName="phone" required ng-valid> 
            </mat-form-field>
            &nbsp;
            <br>
            
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Placeholder" formControlName="email" required ng-valid> 
            </mat-form-field>
            <br>
            <br>
          <button mat-raised-button color="primary" type="submit">Create</button>
          </form> 
        </div>
    </mat-tab>
    <mat-tab label = "Refer">
        <div  *ngIf="isLoading">
            <button mat-button>
              <img src="assets/loading.gif"  width="35px" height="35px"/>
            </button>
          </div>
        <div style="width: 40%; float: left; margin-left: 20px;" >
            <div style="width: 30%; float: left; margin-top:20px; margin-left: 20px;" >
                <button mat-raised-button color="primary" type="button" (click)="createTestOrder()">Create Order</button>
             </div>
            <div
              cdkDropList 
              [cdkDropListData]="customers"
                    class="movie-list" >
                    <div [ngClass]="{'movie-block-href_gray': item.selected == true, 'movie-block-href_white': item.selected == false}"
                      *ngFor="let item of customers" (click)="selectItem(item)">
                      {{item.customerId}} ({{item.firstName}})
                    </div>
          
            </div>
          </div>

          <div style="width: 30%; float: left; margin-top:50px; margin-left: 20px;" >
            <form [formGroup]="inviteForm" (ngSubmit)="onInviteSubmit()">
                <mat-form-field appearance="fill">
                  <mat-label >Phone</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="phone" required ng-valid> 
                </mat-form-field>
                &nbsp;
                <br>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Placeholder" formControlName="email" required ng-valid> 
                  </mat-form-field>
                  br
               <button mat-raised-button color="primary" type="submit">Invite </button>
              </form> 
          </div>
          
    </mat-tab>
    <mat-tab label = "Customer Order">
    </mat-tab>
</mat-tab-group>