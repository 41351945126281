import {MenuCategory} from './_models/menu-changes';

export class RequestStatus {
    result:boolean;
    payload:Object;
};

export class Wrapper {
    items:Array<Object>;
    categories:Array<MenuCategory>;
}
export class PerkzAddRequest {
    customerId: number;
	orderId: number;
	storeId: number;
	amount: number;
	comment: string;
}


export class ReportUrlWrapper{
    reportUrl: string;
}
export class TestCustomerWrapper {
    customers:Array<Customer>;
}

export class PerkzWrapper {
    perkzItems:Array<PerkzWeb>;
    customer:Customer;
    availableRewards:string;
}

export class PerkzWeb {
    id:number;
	customerId:number;
	orderId:number;
	storeId:number;
	totalReward:number;
	rewardUsed:number;
	status:string;
    comments:string;
}
export class Customer{
    customerId:number;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    selected:boolean = false;
}

export class CustomerViewWrapper {
    storeName:string;
    logoUrl:string;
    categories:Array<MenuCategory>;
}



export class CONSTANTS {
    public static readonly ALLOWED_FILE_TYPES =["gif", "jpg", "jpeg", "png"];
    public static readonly SUCCESS_MESSG_STYLE ="my-snack-bar";
    public static readonly FAILURE_MESSG_STYLE ="my-snack-bar-error";
}

export class FileWrapper {
    fileContents:any;
    fileName: string;
    encodedFileConents: string
}