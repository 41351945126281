import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { User } from '../_models/user';
import { AuthenticationService } from '../authentication.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    user:User;
    hide = true;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { 
        // redirect to home if already logged in
       if (this.authenticationService.currentUserValue) { 
     //   alert(this.authenticationService.currentUserValue.id);
            if (this.authenticationService.currentUserValue.id == 100 || this.authenticationService.currentUserValue.id == 7  ) {
                this.router.navigate(['/sales']);
            } else if(this.authenticationService.currentUserValue.id == 101 || this.authenticationService.currentUserValue.id == 102){
                this.router.navigate(['/todays']);
            } else {
                this.router.navigate(['/']);
            }
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.authenticationService.currentUser.subscribe(x => this.user = x);
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.authenticationService.updateStoreStatus(this.user.id, 1).subscribe(
                        result => {
                           // alert("done")
                        }
                    )
                    if (this.user.id == 100 || this.user.id == 7) {
                       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/sales';
                    }  

                    if (this.user.id == 103) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/report';
                     } 

                    if (this.user.id == 101 || this.user.id == 102) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/todays';
                    }  
                    if (this.user.id == 0 || this.user.id == 25) {
                        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/superadmin';
                     }  
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

}
