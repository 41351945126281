<h1 mat-dialog-title>
        <p>Processed By</p>
</h1>

<div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onclick('Gopi')" cdkFocusInitial>
        <div>
            <img src="/assets/Gopi.jpeg" width="100px" height="100px" />
        </div>
        
        </button> 
        <button mat-raised-button color="primary" (click)="onclick('Suma')" cdkFocusInitial>
            <div>
                <img src="/assets/Suma.jpeg" width="100px" height="100px" />
            </div>
            
            </button> 
</div>
<br>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onclick('Priya')" cdkFocusInitial>
    <div>
        <img src="/assets/Priya.jpeg" width="100px" height="100px" />
    </div>
    
    </button> 
    <button mat-raised-button color="primary" (click)="onclick('Geetha')" cdkFocusInitial>
        <div>
            <img src="/assets/Geeta.jpeg" width="100px" height="100px" />
        </div>
        
        </button> 
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onclick('Ajay')" cdkFocusInitial>
    <div>
        <img src="/assets/Ajay.jpeg" width="100px" height="100px" />
    </div>
    
    </button> 
    <button mat-raised-button color="primary" (click)="onclick('Pragya')" cdkFocusInitial>
        <div>
            <img src="/assets/Pragya.jpeg" width="100px" height="100px" />
        </div>
        
        </button> 
</div>
<br>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onclick('Nathan')" cdkFocusInitial>
    <div>
        <img src="/assets/Nathan.jpeg" width="100px" height="100px" />
    </div>
    
    </button> 
    
</div>
<br>
