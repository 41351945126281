<p>Add Additions</p>

<form [formGroup]="addAdditionsForm" >
    <mat-form-field appearance="fill">
        <mat-label >Name</mat-label>
        <input matInput placeholder="Placeholder" formControlName="additionsName"
         required ng-valid> 
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="fill">
        <mat-label >Description</mat-label>
        <input matInput placeholder="Placeholder" formControlName="description"
         required ng-valid> 
      </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Sale Price</mat-label>
    <input matInput type="number" class="example-right-align"  formControlName="price" required>
    <span matPrefix>$&nbsp;</span>
  </mat-form-field>
<br>
<div >
    <mat-form-field appearance="fill">
      <mat-label>Select Parent 1</mat-label>
      <mat-select  formControlName="parent" required >
        <mat-option  value="-1">NO PARENT</mat-option>
        <mat-option  *ngFor="let addtn of menu.additions" value="{{addtn.additionsId}}">{{addtn.name}} - {{addtn.parentId}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br>
  <div >
    <mat-form-field appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select  formControlName="selectionType" required >
        
        <mat-option  *ngFor="let selType of selectionTypeList" value="{{selType}}">{{selType}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
    <br>
    <button mat-raised-button color="primary" (click)="submit()">Submit</button>&nbsp;
    <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
</form>

