<div class="banner">
    <h2 >Update "SALE" Price</h2>
</div>
<div style="width: 100%; margin-left: 20px;" >
    <h4>{{reportRow.menuName}} - {{reportRow.menuCode}}</h4>
    
    <form [formGroup]="salePriceUpdateForm" >
        <mat-form-field appearance="fill">
            <mat-label >Sale Price</mat-label>
            <input matInput type="number" class="example-right-align"  formControlName="salePrice" required>
            <span matPrefix>$&nbsp;</span>
        </mat-form-field> &nbsp;
    <br>
    <button mat-raised-button color="primary" (click)="onSubmit()">Update</button>&nbsp;
    <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
    </form>
</div>