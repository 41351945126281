<head>
    <link href='https://fonts.googleapis.com/css?family=Khand:400,300,500,600,700'
     rel='stylesheet' type='text/css'>
</head>
<body style="margin-top: 0px;width: 100%; height: 420px;"> 
<div class="banner">
    <h3>Order Details</h3>
</div>
<br>
<div class="left">
    <div class="left_first">
        <span><b>Order#: </b>{{order?.customerOrderId}} </span><br>
        <span><b>Name: </b>  {{order?.firstName}} , {{order?.lastName}}</span><br>
        <span><b>Phone #: </b>  {{order?.phone}}</span><br>
        <span *ngIf="order?.paymentMode == 'CARD'" class="focusedText">Paid by Card</span>
        <span *ngIf="order?.paymentMode == 'CASH'" class="blinking" >Not Paid</span><br>
        <span *ngIf="order?.isFutureOrder == 1" class="blinking" >{{order?.requestedDeliveryDateString}}</span>
        

    </div>
    <div class="left_second">
       <span><b>Sale Amt&nbsp;&nbsp;&nbsp;:</b> {{order?.totalSalePrice | currency:'USD':'symbol':'1.2-2'}}</span>
       <br>
       <span><b>Taxes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b> {{order?.tax | currency:'USD':'symbol':'1.2-2'}}</span>
       <span [ngClass]="{'hiddenText': order?.chargeMode =='STORE'}"><br><b>Card Fee&nbsp;&nbsp;&nbsp;: </b>{{order?.transactionFee | currency:'USD':'symbol':'1.2-2'}} </span>
       <br>
       <span><b>Discount :</b> {{ order?.discount | currency:'USD':'symbol':'1.2-2'}}</span>
       <br>
       <span><b>Tip :</b> {{ order?.tipAmount | currency:'USD':'symbol':'1.2-2'}}</span>
       <br>
       <span *ngIf="order?.paymentMode == 'CASH'" class="focusedText" class="focusedText"><b>Customer Pays: </b>{{ (order?.amountChargedToCustomer -  order?.discount ) | currency:'USD':'symbol':'1.2-2'}} </span>
       <span *ngIf="order?.paymentMode != 'CASH'" class="focusedText" class="focusedText"><b>Pre-paid: </b>
            <span *ngIf="order?.chargeMode =='STORE'">
            {{ ( (order?.totalSalePrice + order?.tax + order?.tipAmount) -  order?.discount ) | currency:'USD':'symbol':'1.2-2'}} 
            </span>
            <span *ngIf="order?.chargeMode =='CUSTOMER'">
                {{ ( (order?.totalSalePrice + order?.tax + order?.tipAmount + order?.transactionFee) -  order?.discount ) | currency:'USD':'symbol':'1.2-2'}} 
            </span>
        </span>
    </div>
   
    <br>
    <p style="color: blue;"><b>Order Details:</b></p>
    <div class="left_orderDetails">
        <li *ngFor="let menuItem of order.menuList">
            <b>(#{{menuItem.count}})</b> - {{menuItem.menuCode}} -{{menuItem.menuItemName}} <span *ngIf="menuItem.additionsNames?.length >0">with '{{menuItem.additionsNames}}'</span>- {{menuItem.salePrice | currency:'USD':'symbol':'1.2-2'}}
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b> Instructions:</b> <span *ngIf="menuItem.specialInstructions?.length > 0">{{menuItem.specialInstructions}}</span> <span *ngIf="menuItem.specialInstructions?.length == 0">None</span>
        </li>
    </div>
    <div class="left_orderType">
        <div *ngIf="order?.takeOut == 1">
            <div *ngIf="user?.storeType == 1">
                <img  src="/assets/take_out.png" width="80px" height="80px" />
                <p>&nbsp;&nbsp;&nbsp;Take Out</p>
            </div>
            <div *ngIf="user?.storeType != 1">
                <img  src="/assets/take-away-generic.png" width="80px" height="80px" />
                <p>&nbsp;&nbsp;&nbsp;Pick up</p>
            </div>
            
        </div>
        <div *ngIf="order?.takeOut == 0">
            <img  src="/assets/dine_in_correct.png" width="80px" height="80px" />
            <p style="width: 100%;">Dine In</p>
        </div>
        <div *ngIf="order?.takeOut == 2">
            <img  src="/assets/delivery.jfif" width="80px" height="80px" />
            <p style="width: 100%;">Delivery</p>
            <span *ngIf="order?.takeOut == 2" class="blinking" >{{order?.address}}</span>
        </div>
    </div>
    
</div>
<!--
<div *ngIf="user?.storeType != 2" class="right">
    <button mat-fab color="primary" class="time" (click)="confirm(10)"> 
        10<span style="vertical-align: super; font-size: 12px;">min</span></button>
     <br>   <br>  <br> 
    <button mat-fab color="primary" class="time" (click)="confirm(20)">20<span style="vertical-align: super; font-size: 12px;">min</span></button>
    <br>   <br>  <br> 
    <button mat-fab color="primary" class="time" (click)="confirm(30)">30<span style="vertical-align: super; font-size: 12px;">min</span></button>
    <br>   <br>  <br> 
    <button mat-fab color="primary" class="time" (click)="confirm(40)">45<span style="vertical-align: super; font-size: 12px;">min</span></button>
    <br>   <br>  <br> 
    <button mat-fab color="primary" class="time" (click)="confirm(60)">60<span style="vertical-align: super; font-size: 12px;">min</span></button>
</div>
-->
<div  class="right">
    <div *ngIf="order?.isFutureOrder == 1">
        <button  mat-raised-button color="primary" class="time" (click)="confirm(0)"> 
            Accept</button>
        <br><br> <br>
        <button  mat-raised-button color="warn" class="time" (click)="rejectOrder()"> 
                Reject</button>
    </div>  
    <div *ngIf="order?.isFutureOrder == 0">
        <button mat-fab color="primary" class="time" (click)="confirm(10)"> 
            10<span style="vertical-align: super; font-size: 12px;">min</span></button>
         <br>   <br>  <br> 
        <button mat-fab color="primary" class="time" (click)="confirm(20)">20<span style="vertical-align: super; font-size: 12px;">min</span></button>
        <br>   <br>  <br> 
        <button mat-fab color="primary" class="time" (click)="confirm(30)">30<span style="vertical-align: super; font-size: 12px;">min</span></button>
        <br>   <br>  <br> 
        <button mat-fab color="primary" class="time" (click)="confirm(40)">45<span style="vertical-align: super; font-size: 12px;">min</span></button>
        <br>   <br>  <br> 
        <button mat-fab color="primary" class="time" (click)="confirm(60)">60<span style="vertical-align: super; font-size: 12px;">min</span></button>
    </div>
</div>
<div class="footer" >
    <div *ngIf="showError==true">
        <span class="error">{{errorMessage}}<button mat-raised-button color="accent" (click)="close()">Close</button></span>
    </div>
    <!-- PRINTING
    <div  id="print-section" class="hidden-div">
        <div class="container" style="width:100%; display: flex;border-style: solid;border-width: 1px;">
            <div style="width: 70%; background: green;">
                Date: 11/9/2020 12:16 PM
            </div>
            <div style="flex-grow: 1; background: blue;text-align: right;">
                Iperkz
            </div>
        </div>
        <div style="text-align: center;font-weight: bold;"> Takeout &nbsp;&nbsp; #24</div>
        <div style="text-align: center;">&#9733; &#9733; &#9733; &#9733;&#9733; &#9733; &#9733; &#9733;&#9733; &#9733; &#9733; &#9733;&#9733;</div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;">
            <div style="width: 50%; background: green; text-align: left;padding-left: 15px;">
                Bottle Water :
            </div>
            <div style="flex-grow: 1; background: blue;text-align: right;">
               7.50
            </div>
        </div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: left;padding-left: 15px;">
                Fried Rice :
            </div>
            <div style="flex-grow: 1; background: blue;text-align: right;">
              17.50
            </div>
        </div>
      
        <br>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: left;margin-left: 0px;">
                Tax(%):
            </div>
            <div style="flex-grow: 1; background: blue;text-align: right;">
                (6.625%)
            </div>
        </div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: right;">
                Item#:
            </div>
            <div style="flex-grow: 1; background: blue;text-align: left;">
             6
            </div>
        </div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: right;">
                Subtotal:
            </div>
            <div style="flex-grow: 1; background: blue;text-align: left;">
             6
            </div>
        </div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: right;">
                Tax:
            </div>
            <div style="flex-grow: 1; background: blue;text-align: left;">
             6
            </div>
        </div>
        <div class="container" style="width:100%;display: flex;padding-bottom: 0px;padding-top: 0px;">
            <div style="width: 50%; background: green; text-align: right;">
                Total:
            </div>
            <div style="flex-grow: 1; background: blue;text-align: left;">
             6
            </div>
        </div>
        <div style="text-align: center;">&#9733; &#9733; &#9733; &#9733;&#9733; &#9733; &#9733; &#9733;&#9733; &#9733; &#9733; &#9733;&#9733;</div>
    </div>
    
<button  
    printTitle="Print"  
    printSectionId="print-section"  
    ngxPrint>print</button>

    -->
<!--
        <button mat-raised-button color="primary" class="reject">Print</button>   
    <button mat-raised-button color="warn" class="print">Reject</button>   
-->  


</div> 
</body>
