import { Component,ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from  '@angular/forms';
import {AdminService} from '../admin.service';
import { Validators } from '@angular/forms';
import { PrebookReportCriteria } from '../OrderReportCriteria';
import { ConsolidateVendorSummaryItem, PrebookOrderReportItem, VendorOrderItem } from '../order';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';

@Component({
  selector: 'app-investor-report',
  templateUrl: './investor-report.component.html',
  styleUrls: ['./investor-report.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class InvestorReportComponent implements OnInit {
  user:User;
  contactForm: FormGroup;
  isLoading:boolean = false;
  disableSearchButton:boolean = false;
  prebookReportItems: Array<PrebookOrderReportItem> = new Array();
  reportSummary: Array<PrebookOrderReportItem> = new Array();
  //disableSearch:boolean = true;
  consolidatedByOrders: Array<ConsolidateVendorSummaryItem> = new Array();

  reportDataForDisplay:any;

  reportSummaryForDisplay:any;
  reportSummaryHeaders: string[] =['customerOrderIds','menuName','quantity','orgPrice',
                              'salePrice', 'address'];
  columnsToDisplay = ['order_number', 'customer_name',  'item_count', 'order_date_str', 'delivery_date_str'];
  expandedElement: ConsolidateVendorSummaryItem | null;
  reportHeaders: string[] =['customerOrderId','customer-name','address', 'quantity','orgPrice',
                              'salePrice','menuName', 'orderCreateTime', 'orderCloseTime' ];
  checkMark:string = "../../assets/tick.png";

  totalSales:number;
  totalProfit:number;
  
  dataSource:any;

  constructor(private authService: AuthenticationService, private router: Router,
     private adminService:AdminService, private formBuilder: FormBuilder){ 
      this.authService.currentUser.subscribe(x => this.user = x);
      this.createContactForm();
      this.fetchTodaysData();
  }

  
  logout() 
  {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit(): void {
  }
  createContactForm(){
    this.contactForm = this.formBuilder.group({
      startDate: ['', Validators.required],  
      endDate: ['', Validators.required]
    });
  }
  onRefresh() {
    this.fetchTodaysData();
  }
  onSubmit() {
    var criteria:PrebookReportCriteria = new PrebookReportCriteria();

    criteria.startDate = this.contactForm?.get('startDate').value;
    criteria.endDate = this.contactForm?.get('endDate').value;
    //
    criteria.startDate.setSeconds(0);
    criteria.startDate.setMilliseconds(0);
    criteria.startDate.setMinutes(0);

    criteria.endDate.setSeconds(0);
    criteria.endDate.setMilliseconds(0);
    criteria.endDate.setMinutes(0);
    if (this.user.id == 100) {
      criteria.menuCode = "Sukhadia"
    } else if (this.user.id == 7) {
      criteria.menuCode = "Hot Breads"
    }
    this.totalSales = 0;
    this.totalProfit = 0;
    this.reportSummary = new Array();
    this.disableSearchButton = true;
    this.adminService.getPrebookReportItems(criteria)
    .subscribe(result => {
      this.isLoading = false;
      this.prebookReportItems = new Array();
      let map = new Map<number, PrebookOrderReportItem>(); 
      let  ordersMap = new Map<number, ConsolidateVendorSummaryItem>(); 

      this.prebookReportItems = result.items as PrebookOrderReportItem[];
        this.reportDataForDisplay = new MatTableDataSource(this.prebookReportItems);
        this.prebookReportItems.forEach(item => {
          this.totalSales = this.totalSales + (item.originalPrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          const array = item.address.split(',');
          if (array[array.length-2].toUpperCase() == 'NJ') {
            item.address = array[array.length-3];
          } else {
            item.address = array[array.length-2];
          }
          map.get(item.menuId)
          console.log("before");
          if (item.orderCloseTime == null)  {
            if (map.get(item.menuId) === undefined ) {
              map.set(item.menuId, this.clone(item));
            } else {
              let fromMap:PrebookOrderReportItem = map.get(item.menuId) as PrebookOrderReportItem;
                fromMap.quantity = fromMap.quantity + item.quantity;
                fromMap.address = fromMap.address + ", " + item.address;
                fromMap.customerOrderIds = fromMap.customerOrderIds + "-- " + item.customerOrderId;
             }
          }
          /*
          if (map.get(item.menuId) === undefined ) {
            if (item.orderCloseTime == null)  {
              map.set(item.menuId, this.clone(item));
            }
          } else {
            if (map.get(item.menuId) === undefined ) {
              //do nothing 
            } else {
              let fromMap:PrebookOrderReportItem = map.get(item.menuId) as PrebookOrderReportItem;
              if (fromMap.orderCloseTime == null)  {
                fromMap.quantity = fromMap.quantity + item.quantity;
                fromMap.address = fromMap.address + ", " + item.address;
              }
           }
          } 
           */
          console.log(map.get(item.menuId));
          //Sukhadia request
          if (ordersMap.get(item.customerOrderId) === undefined) {
            ordersMap.set(item.customerOrderId, this.convert(item))
          } else {
            let orderItem:ConsolidateVendorSummaryItem = ordersMap.get(item.customerOrderId) as ConsolidateVendorSummaryItem;
            orderItem.item_count = orderItem.item_count + item.quantity;
            var detailsItem: VendorOrderItem = new VendorOrderItem();
            detailsItem.menuId = item.menuId;
            detailsItem.menuName = item.menuName;
            detailsItem.quantity = item.quantity;
            orderItem.details.push(detailsItem);
          //  orderItem.address = orderItem.address + ", " + item.address;
          }
          //Sukhadia end
        

        });
        this.reportSummary = new Array()
        
        map.forEach((value,key) =>{
          if (value.orderCloseTime == null) {
            this.reportSummary.push(value);
          }
        })
          
        this.consolidatedByOrders = new Array()
        ordersMap.forEach((value,key) =>{
          if (value.delivery_date == null) {
            this.consolidatedByOrders.push(value);
          }
        })
        
        this.consolidatedByOrders.forEach(item => {
          /*
          console.log("==>" + item.order_number + "  - children: " + item.details.length)
          var consolidatedIem:PrebookOrderReportItem = new PrebookOrderReportItem();
          consolidatedIem.salePrice = 0;
          consolidatedIem.originalPrice = 0;
          var itemCt = 0
          item.details.forEach (innerItem=> {
            itemCt = itemCt + innerItem.
          })
          */
        })
        this.consolidatedByOrders.sort((a,b) => a.order_number < b.order_number ? 1 : a.order_number > b.order_number ? -1 : 0)
        //x.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        this.dataSource = new MatTableDataSource(this.consolidatedByOrders);

        this.reportSummaryForDisplay = new MatTableDataSource(this.reportSummary);
        this.disableSearchButton = false;
      }
      );
  }

  public clone(source:PrebookOrderReportItem): PrebookOrderReportItem {
    var clonedItem:PrebookOrderReportItem = new PrebookOrderReportItem();
    clonedItem.customerOrderId = source.customerOrderId;
    clonedItem.customerId = source.customerId;
    clonedItem.menuId = source.menuId;
    clonedItem.menuName = source.menuName;
    clonedItem.salePrice = source.salePrice;
    clonedItem.orderCreateTime = source.orderCreateTime;
    clonedItem.orderCloseTime = source.orderCloseTime;
    clonedItem.address = source.address;
    clonedItem.firstname = source.firstname;
    clonedItem.lastname = source.lastname;
    clonedItem.quantity = source.quantity;
    clonedItem.originalPrice = source.originalPrice;
    clonedItem.customerOrderIds = source.customerOrderId + "";
     return clonedItem
 }

 public convert(source:PrebookOrderReportItem): ConsolidateVendorSummaryItem {
  var converterItem:ConsolidateVendorSummaryItem = new ConsolidateVendorSummaryItem();
  converterItem.order_number = source.customerOrderId;
  converterItem.customerId = source.customerId;
  converterItem.totlaSalePrice = source.salePrice;
  converterItem.totalProcurementPrice = source.originalPrice;
  converterItem.order_date = source.orderCreateTime;
  converterItem.delivery_date = source.orderCloseTime;
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };
  if (source.orderCreateTime != null) {
    var test:Date = new Date(source.orderCreateTime);
    converterItem.order_date_str = test.toLocaleString("en-US", options);
  }
  if (source.orderCloseTime != null) {
    var test:Date = new Date(source.orderCloseTime);
    converterItem.delivery_date_str = test.toLocaleString("en-US", options);
  }
  converterItem.address = source.address;
  converterItem.customer_name = source.firstname + ", " + source.lastname;

  converterItem.item_count = source.quantity;
  var detailsItem: VendorOrderItem = new VendorOrderItem();
  detailsItem.menuId = source.menuId;
  detailsItem.menuName = source.menuName;
  detailsItem.quantity = source.quantity;
  converterItem.details.push(detailsItem);
   return converterItem
   /*
    customerOrderId:number;
    customerId: number;
	  totlaSalePrice: number;
	  totalProcurementPrice:number;
    orderCreateTime: Date;
    orderCloseTime: Date;
	  address: string;
	  firstname: string;
	  lastname: string;
    itemCount: number;
    details:ConsolidateVendorItem[];
    */
}

onTabChange($event){
 if ($event.index == 0 || $event.index == 1) {
    this.fetchTodaysData();
 } 
}
fetchTodaysData() {
  var criteria:PrebookReportCriteria = new PrebookReportCriteria();
  var startDate = new Date();
  var endDate = new Date();
  startDate.setDate(endDate.getDate() -1);

  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setHours(12);

  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setHours(12);
  console.log("Start Date : " + startDate);
  console.log("End Date : " + endDate);

  criteria.startDate = startDate;
  criteria.endDate = endDate;

  if (this.user.id == 100) {
    criteria.menuCode = "Sukhadia"
  } else if (this.user.id == 7) {
    criteria.menuCode = "Hot Breads"
  }
  this.totalSales = 0;
  this.totalProfit = 0;
  this.reportSummary = new Array();
  this.disableSearchButton = true;
  this.adminService.getPrebookReportItems(criteria)
  .subscribe(result => {
    this.isLoading = false;
    this.prebookReportItems = new Array();
    let map = new Map<number, PrebookOrderReportItem>(); 
    let  ordersMap = new Map<number, ConsolidateVendorSummaryItem>(); 

    this.prebookReportItems = result.items as PrebookOrderReportItem[];
      this.reportDataForDisplay = new MatTableDataSource(this.prebookReportItems);
      this.prebookReportItems.forEach(item => {
        this.totalSales = this.totalSales + (item.originalPrice * item.quantity);
        this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
        const array = item.address.split(',');
        try { 
        if (array[array.length-2].toUpperCase() == 'NJ') {
          item.address = array[array.length-3];
        } else {
          item.address = array[array.length-2];
        }
      } catch {
        item.address = "unknown"
      }
        map.get(item.menuId)
        console.log("before");
        if (item.orderCloseTime == null)  {
          if (map.get(item.menuId) === undefined ) {
            map.set(item.menuId, this.clone(item));
          } else {
            let fromMap:PrebookOrderReportItem = map.get(item.menuId) as PrebookOrderReportItem;
              fromMap.quantity = fromMap.quantity + item.quantity;
              fromMap.address = fromMap.address + ", " + item.address;
              fromMap.customerOrderIds = fromMap.customerOrderIds + ", " + item.customerOrderId;
          }
        }
    
        console.log(map.get(item.menuId));
        //Sukhadia request
        if (ordersMap.get(item.customerOrderId) === undefined) {
          ordersMap.set(item.customerOrderId, this.convert(item))
        } else {
          let orderItem:ConsolidateVendorSummaryItem = ordersMap.get(item.customerOrderId) as ConsolidateVendorSummaryItem;
          orderItem.item_count = orderItem.item_count + item.quantity;
          var detailsItem: VendorOrderItem = new VendorOrderItem();
          detailsItem.menuId = item.menuId;
          detailsItem.menuName = item.menuName;
          detailsItem.quantity = item.quantity;
          orderItem.details.push(detailsItem);
        //  orderItem.address = orderItem.address + ", " + item.address;
        }
        //Sukhadia end
      

      });
      this.reportSummary = new Array()
      
      map.forEach((value,key) =>{
        if (value.orderCloseTime == null) {
          this.reportSummary.push(value);
        }
      })
        
      this.consolidatedByOrders = new Array()
      ordersMap.forEach((value,key) =>{
        if (value.delivery_date == null) {
          this.consolidatedByOrders.push(value);
        }
      })
      
      this.consolidatedByOrders.sort((a,b) => a.order_number < b.order_number ? 1 : a.order_number > b.order_number ? -1 : 0)
      //x.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
      this.dataSource = new MatTableDataSource(this.consolidatedByOrders);

      this.reportSummaryForDisplay = new MatTableDataSource(this.reportSummary);
      this.disableSearchButton = false;
    }
    );
}
/*
  public clone(): any {
    var cloneObj = new (this.constructor() as any);
    for (var attribut in this) {
        if (typeof this[attribut] === "object") {
            cloneObj[attribut] = this[attribut].clone();
        } else {
            cloneObj[attribut] = this[attribut];
        }
    }
    return cloneObj;
}*/

}
