<mat-toolbar  color="primary"  style="height:40px;float: right;" >
    <img src="../../assets/scooter-with-box.png" width="40px;" height="40px;">
    <span class="example-spacer"></span>
   
            <div style="float:right;margin-right: 20px; max-height: 20px;" >
                <span style="color:yellow">{{user.firstName}}&nbsp; {{user.lastName}}</span>&nbsp;&nbsp;
                <a (click)="logout()" style="vertical-align:top;font-size: 18px;"> <mat-icon  color="blue" style="font-size: 22px;" >lock icon</mat-icon>Logout</a>
            </div>
</mat-toolbar>

&nbsp;

<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

    <mat-form-field appearance="fill">
        <mat-label>Choose a start date</mat-label>
        <input matInput [matDatepicker]="stardate" formControlName="startDate" required>
        <mat-datepicker-toggle matSuffix [for]="stardate"></mat-datepicker-toggle>
        <mat-datepicker #stardate></mat-datepicker>
    </mat-form-field>
    &nbsp;
    <mat-form-field appearance="fill">
        <mat-label>Choose a end date</mat-label>
        <input matInput [matDatepicker]="enddate" formControlName="endDate" required>
        <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
        <mat-datepicker #enddate></mat-datepicker>
    </mat-form-field>
    &nbsp;
    <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit" [disabled]="disableSearchButton">Search</button> &nbsp;
    <span *ngIf="totalSales>0" style="margin-left: 5px;font-size: 18px; font-weight: bold;color: black;">Total Sales:</span><span *ngIf="totalSales>0" style="margin-left: 2px;font-size: 22px; color: green;">{{totalSales | currency:'USD':'symbol':'1.2-2'}}</span> 
    
    <span *ngIf="totalProfit>0" style="margin-left: 20px;font-size: 18px; font-weight: bold;color: black;">Total Profit:</span><span *ngIf="totalProfit>0" style="margin-left: 2px;font-size: 22px; color: green;">{{totalProfit | currency:'USD':'symbol':'1.2-2'}}</span> 
   
</form>
<mat-tab-group style="width: 100%;"  >
  
  <mat-tab label = "Sale Details">

    <div>
        <section class="example-section" [formGroup]="filters" >  
          <p style="margin-left: 20px;margin-top:5px; font-size:19px;color: gray;"># of items: {{this.prebookReportItems?.length}}. &nbsp;&nbsp;Show only :
          <mat-checkbox formControlName="provisions" (change)="onFilterChange()">Provisions</mat-checkbox>&nbsp;&nbsp;
          <mat-checkbox formControlName="veggies" (change)="onFilterChange()">Veggies</mat-checkbox>&nbsp;&nbsp;
          <mat-checkbox formControlName="frozen" (change)="onFilterChange()">Frozen</mat-checkbox>&nbsp;&nbsp;
          <mat-checkbox formControlName="fresh_cut" (change)="onFilterChange()">Fresh Cut</mat-checkbox>
          </p>
        </section>
      <h3></h3>
      
        <table mat-table [dataSource]="reportDataForDisplay"  class="mat-elevation-z8" >

          <ng-container matColumnDef="customerOrderId">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Order#</th>
            <td mat-cell *matCellDef="let element" 
            [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}"
              >
            {{element.customerOrderId}}</td>

          </ng-container>
            <ng-container matColumnDef="customer-name">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Customer</th>
              <td mat-cell *matCellDef="let element" 
              [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
              {{element.firstname}}, {{element.lastname}}</td>

            </ng-container>
            <ng-container matColumnDef="address">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >City</th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
              {{element.address}}</td>
            </ng-container>
            <ng-container matColumnDef="orderCreateTime">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Ordered On</th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
              {{element.orderCreateTime  | date:'MMM d, y, h:mm a'}}</td>
            </ng-container>
            <ng-container matColumnDef="orderCloseTime">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef   >Delivered On</th>
                <td mat-cell *matCellDef="let element">
                {{element.orderCloseTime |  date:'MMM d, y, h:mm a'}}</td>
              </ng-container>
              <ng-container matColumnDef="quantity">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Count</th>
                <td mat-cell *matCellDef="let element"
                [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
                  {{element.quantity}}</td>
              </ng-container>
              <ng-container matColumnDef="salePrice">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Sale Price</th>
                <td mat-cell *matCellDef="let element"  
                [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
                  <div (mouseenter) ="onMouseEnterForSalePrice(element)" (mouseleave)="onMouseLeaveForSalePrice(element)">
                    <section >

                  {{element.salePrice | currency:'USD':'symbol':'1.2-2'}}
                  <img *ngIf="element.isOnFocusForSalePrice==true" src="../../assets/edit-icon-1.png" widh="25px" height="25px" 
                  (click)="showSalePriceEditScreen(element)">
                </section>
                </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="orgPrice">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  >
                  <img src="../../assets/dollar-sign.png" width="25px" height="22px">Org. Price</th>
                <td mat-cell *matCellDef="let element"  
                [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
                  <div (mouseenter) ="onMouseEnter(element)" (mouseleave)="onMouseLeave(element)">
                    <section >

                  {{element.originalPrice | currency:'USD':'symbol':'1.2-2'}}
                  <img *ngIf="element.isOnFocus==true" src="../../assets/edit-icon-1.png" widh="25px" height="25px" 
                  (click)="showPriceEditScreen(element)">
                </section>
                </div>
                </td>

              </ng-container>
            <ng-container matColumnDef="%">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">%</th>
                <td mat-cell *matCellDef="let element"
                [ngClass]="getClass(element)">
                  {{ (element.salePrice - element.originalPrice) /element.originalPrice | percent }}</td>
             </ng-container>
            <ng-container matColumnDef="menuName">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Sale Item</th>
              <td mat-cell *matCellDef="let element"
              [ngClass]="{'profit-style': element.originalPrice < element.salePrice && element.originalPrice !=0, 
              'no-profit-style': element.originalPrice==0, 'loss-style': element.originalPrice > element.salePrice}">
                {{element.menuName}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="reportHeaders; sticky: true"></tr>
              <tr mat-row *matRowDef="let row;columns: reportHeaders"></tr>
          </table>
    </div>
    </mat-tab>
    <mat-tab label = "Sale Summary">
      
      <div>
        <p style="margin-left: 20px;margin-top:5px; font-size:19px;color: gray;"># of items: {{this.reportSummary?.length}} </p>
          <table mat-table [dataSource]="reportSummaryForDisplay"  class="mat-elevation-z8" >
  
            <ng-container matColumnDef="menuName">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Item</th>
              <td mat-cell *matCellDef="let rowItem" >
              {{rowItem.menuName}}</td>
              </ng-container>

              <ng-container matColumnDef="address">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Cities</th>
                <td mat-cell *matCellDef="let rowItem" >
                {{rowItem.address}}</td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Count</th>
                  <td mat-cell *matCellDef="let rowItem">
                    {{rowItem.quantity}}</td>
                </ng-container>

                <ng-container matColumnDef="salePrice">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Sale Price</th>
                  <td mat-cell *matCellDef="let rowItem">
                    {{rowItem.salePrice | currency:'USD':'symbol':'1.2-2'}}</td>
                </ng-container>
                <ng-container matColumnDef="orgPrice">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Org. Price</th>
                  <td mat-cell *matCellDef="let rowItem" >
                    {{rowItem.originalPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                </ng-container>
              <tr mat-header-row *matHeaderRowDef="reportSummaryHeaders; sticky: true"></tr>
              <tr mat-row *matRowDef="let row;columns: reportSummaryHeaders"></tr>
          </table>
        </div>     
  </mat-tab>
</mat-tab-group>