<div>
<div style="float:left;width: 75%;" *ngIf="userType == 'Admin'">
    <form [formGroup]="messageForm" >
<mat-form-field appearance="fill" style="width: 100%;">
      <mat-label >Subject</mat-label>
      <input matInput placeholder="Placeholder" formControlName="subject" 
       required ng-valid> 
    </mat-form-field>
    
    <mat-form-field appearance="fill" style="width: 100%;height: 100px;">
      <mat-label>Message </mat-label>
      <textarea matInput  class="example-right-align"  formControlName="message"
      required> {{message}}</textarea>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 30%;">
        <mat-label >Refund Amount</mat-label>
        <input matInput placeholder="Placeholder" formControlName="refund" value="{{refundAmount}}"
         required ng-valid> 
      </mat-form-field>
      </form>
</div >
<div style="float:right;width: 20%;" *ngIf="userType == 'Regular'">
    <button mat-raised-button color="primary" style="float:right; padding-top:0px;height: 90px;" (click)="onSubmitForApproval()">Submit for Approval</button>
</div>
<div style="float:right;width: 20%;" *ngIf="userType == 'Admin'">
    <button mat-raised-button color="primary" style="float:right; padding-top:0px;height: 90px;" (click)="onSendCustomer()">&nbsp;&nbsp;&nbsp;Send&nbsp;&nbsp;&nbsp;</button>
</div>
</div>
<button style="float:left;" mat-raised-button color="primary" (click)="closeWindow()">Close</button>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                     (change)="setSendButtonStatus($event, row)"
                      [checked]="row.status == 1"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container> 
    <ng-container matColumnDef="Order ID">
      <th class="w-order-no" mat-header-cell *matHeaderCellDef>Menu Item Name</th>
      <td mat-cell *matCellDef="let element" 
      [ngClass]="{'startedBackground': element.status == 1}">
      {{element.menuItemName}}</td>
    </ng-container>
    
      <ng-container matColumnDef="count">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Count</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.status == 1}">
        {{element.count}}</td>
      </ng-container>
      <ng-container matColumnDef="Sale Price">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.status == 1}">
        {{element.salePrice | currency:'USD':'symbol':'1.2-2'}}</td>
      </ng-container>
      <ng-container matColumnDef="message">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Change</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.status == 1}">
        <mat-form-field appearance="fill">
            <mat-label >Comment</mat-label>
            <input matInput placeholder="Input" [(ngModel)]="element.message" [required] = "element.status==1" (change) = "onMessageChange(element)"
              ng-valid> 
          </mat-form-field> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Change</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.status == 1}"> {{element.action}}
        <mat-radio-group aria-label="Select an option" [(model)]="element.action" (change)="actionChanged($event, element)"  disabled="{{element.status != 1}}">
            <mat-radio-button class="radio-button-style" value="1" [checked]="element.action==1" >Refund</mat-radio-button>&nbsp;&nbsp;
            <mat-radio-button class="radio-button-style" value="2" [checked]="element.action==2">Other</mat-radio-button>
        </mat-radio-group>
        </td>
        </ng-container>
        <ng-container matColumnDef="price">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>price</th>
            <td mat-cell *matCellDef="let element" 
            [ngClass]="{'startedBackground': element.status == 1}">
            <mat-form-field appearance="fill" *ngIf="element.status == 1">
                <mat-label >Comment</mat-label>
                <input matInput placeholder="Input" [(ngModel)]="element.refundAmt" [required] = "element.status==1" (change) = "onPriceChanged(element)"
                  ng-valid> 
              </mat-form-field> </td>
          </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
  </table>

