import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { DailyspecialsService } from '../dailyspecials.service';
import { DailySpecial } from '../dailyspecial';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { throws } from 'assert';
import { MessageComponent } from '../message/message.component';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { MatSelectConfig } from '@angular/material/select';
import {CONSTANTS} from '../constants';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {AuthenticationService} from '../authentication.service';
import { CdkDragDrop, moveItemInArray, copyArrayItem, transferArrayItem } from '@angular/cdk/drag-drop';
import { User } from '../_models/user';
import { isNumber } from 'util';


@Component({
  selector: 'app-dailyspecials',
  templateUrl: './dailyspecials.component.html',
  styleUrls: ['./dailyspecials.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DailyspecialsComponent implements OnInit {
  contactForm: FormGroup;
  mealTypes: any = ['BREAKFAST', 'LUNCH', 'ALL DAY'];
  menuCategories: any = ['EGG PLATTER', 'OMELETTES', 'APPETIZERS',
   'SALAD', 'BEVERAGES','SANDWICHES','CHINEESE', 'INDIAN'];

  menuCodes: any = [ 'Veggies', 'Provisions', 'Frozen', 'Spices', 'Fresh Cut', 'Fresh Juice'];
  result: false;
  isLoading:boolean = false;
  private user:User;

  private response: any;
  public selectedMoment:any;
  public specials : DailySpecial[];
  public originalSpecials : DailySpecial[];
  selection = new SelectionModel<DailySpecial>(true, []);
  dataSource:any = null;
  enableNotificationBtn:boolean = false;
  displayedColumns: string[] =['select','menu', 'description', 'salePrice',
    'mealType', 'menuCategory', 'date', 'picture', 'delete'];

  public categoryList: string[] = [];
  public subCategoryList: any = {};
  private categoryIdMap: any = {};
  private subCategoryIdMap: any = {};
  public menuCodeList: string[] = ['Fresh Cut'];
  public currentCategory: string = 'Select Category';
  public error="";
  public showForm:boolean = false;
  public isSorting:boolean = false;
  public isSubmitting:boolean = false;
  
  constructor(private formBuilder: FormBuilder, private dailyspecials:DailyspecialsService,
    private authService: AuthenticationService,
    public dialog: MatDialog, private _snackBar: MatSnackBar) {

    this.createContactForm();
    
   }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => this.user = x);
    this.getDailySpecials();
    //calling the api to get the categories data
    this.getCategoriesList();
  }

  createContactForm(){
    this.contactForm = this.formBuilder.group({
      menuItemName: ['', Validators.required],  
      suffix: ['', Validators.required],
      menuItemDesc: ['', ],
      message: [''],
      menuCategory :['', this.validateMealType],
      menuSubCategory :['', this.validateCategory],
      menuCode :[''],
      origPrice :['',Validators.required],
      salePrice :['', Validators.required],
      quantity :['n/a', this.validateQuantity]
    });
  }

  onCategoryChange(): void {
    this.contactForm.get('menuCategory')?.valueChanges.subscribe(val => {
      this.currentCategory = val;
    });
  }

  toggleDisplayOrder():void {
    if(this.isSorting) {
      this.specials = this.originalSpecials;
      this.dataSource = new MatTableDataSource<DailySpecial>(this.specials);
    }
    this.isSorting = !this.isSorting;
  }

  submitDisplayOrder():void {
    this.isSubmitting = true;
    let data:any = {};
    this.specials.forEach((item:DailySpecial, index:number) => {
     data[item.menuId] = index;
    });
    let finalData = {};
    finalData['menuIds'] = data;
    console.log(finalData);
    this.dailyspecials.updateDisplayOrder(25, finalData).subscribe(result => {
      console.log(result);
      this.isSorting = false;
        this.isSubmitting = false;
      if (result.result == true) {
        this.openSnackBar('Display Order Updated', CONSTANTS.SUCCESS_MESSG_STYLE);
      } else {
        this.openSnackBar('Failed to update Display Order', CONSTANTS.FAILURE_MESSG_STYLE);
      }
    }, error => {
      this.isSorting = false;
        this.isSubmitting = false;
      console.error('Failed to update Display Order', error);
      this.openSnackBar('Failed to update Display Order', CONSTANTS.FAILURE_MESSG_STYLE);
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      this.swapArrayElements(this.specials, event.previousIndex, event.currentIndex);
      this.dataSource = new MatTableDataSource<DailySpecial>(this.specials);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

    }
  }   

  swapArrayElements(arr:any, index1:number, index2:number) {
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;
  }


  /*
  openSnackBar(message: string, action: string) {
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = ['my-snack-bar'];
    
    this._snackBar.open(message, action, matConfig);
  }
*/
openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
}

removeDailySpecialsCreation(message:string){
  this.contactForm = this.formBuilder.group({});
  this.showForm = false;
  this.error = message;
}

  onSubmit() {
    console.log('Your form data : ', this.contactForm.value );

    
    if (this.contactForm.status == 'VALID') {
   var dailyspecial:DailySpecial = new DailySpecial();

    dailyspecial.menuItemName = this.contactForm.get('menuItemName')?.value;
    dailyspecial.menuItemDesc = this.contactForm.get('menuItemDesc')?.value;
    dailyspecial.suffix = this.contactForm.get('suffix')?.value;
    dailyspecial.storeId = this.user?.id;
    dailyspecial.menuCode = this.contactForm.get('menuCode')?.value;
    dailyspecial.origPrice = this.contactForm.get('origPrice')?.value;
    dailyspecial.salePrice = this.contactForm.get('salePrice')?.value;
    dailyspecial.menuSubCategory = this.contactForm.get('menuSubCategory')?.value;
    dailyspecial.subCategoryDisplaySeq = this.subCategoryIdMap[this.contactForm.get('menuSubCategory')?.value];
    dailyspecial.menuCategory = this.contactForm.get('menuCategory')?.value;
    dailyspecial.mealType = "ALL DAY";
    var quantity = this.contactForm.get('quantity')?.value;
    if (quantity > 0 || quantity <= 0 ) { //check if is number
      if (quantity < 0 ) {
        this.openSnackBar('Quantity can be either greater than zero OR n/a', CONSTANTS.FAILURE_MESSG_STYLE)
        return false;
      }
      dailyspecial.quantity = quantity
    } else {
      alert (quantity)
       if (quantity == 'n/a' || quantity == 'N/A') {
       //  return true;
       dailyspecial.quantity = -10000;
       } else {
        this.openSnackBar('Quantity can be either greater than zero OR n/a', CONSTANTS.FAILURE_MESSG_STYLE)
        return false;
       }
    }
    
    var startDt:Date = new Date();
    var endDt:Date = new Date();

    startDt.setSeconds(0);
    startDt.setMilliseconds(0);
    startDt.setHours(5);
    endDt.setSeconds(0);
    endDt.setMilliseconds(0);
    endDt.setHours(23);
    
    dailyspecial.startTime=startDt.getHours() +':'+startDt.getMinutes();
    dailyspecial.endTime=endDt.getHours() + ':' + endDt.getMinutes();
/*
    if (dailyspecial.menuCode == 'Select' ) {
      this.openSnackBar('Please select Menu Code', CONSTANTS.FAILURE_MESSG_STYLE)
      return false;
    }
*/
    if (dailyspecial.origPrice < dailyspecial.salePrice) {
      this.openSnackBar('Sale Price cannot be greater than Original Price', CONSTANTS.FAILURE_MESSG_STYLE)
      return false;
    }
    if (dailyspecial.salePrice < 0 || dailyspecial.origPrice < 0 ) {
      this.openSnackBar('Price cannot be less than Zero', CONSTANTS.FAILURE_MESSG_STYLE)
      return false;
    }
    if (dailyspecial.salePrice > 999 || dailyspecial.origPrice > 999 ) {
      this.openSnackBar('Price cannot be greater than 999', CONSTANTS.FAILURE_MESSG_STYLE)
      return false;
    } 

   if (endDt <= startDt) {
    this.openSnackBar('End Time should be greater than Start Time', CONSTANTS.FAILURE_MESSG_STYLE);
    return false;
   }
    this.dailyspecials.createDailySpecial(dailyspecial)
      .subscribe(result => {
        console.log(result);
         if (result.result == true) {
          this.openSnackBar('Daily Spcial Created', CONSTANTS.SUCCESS_MESSG_STYLE)
          this.getDailySpecials();
         } else {
          this.openSnackBar('Failed to create Daily Special', CONSTANTS.FAILURE_MESSG_STYLE)
         }
        }
        );
    } else {
      this.openSnackBar('Please fill the required fields', CONSTANTS.FAILURE_MESSG_STYLE);
     return;
    }
    
  }
  getDailySpecials(): void {
    this.isLoading = true;
    this.dailyspecials.getDailySpecials(this.user.id +'')
        .subscribe(result =>  {
          this.isLoading = false; 
          console.log(result);
          if (result.result == true) {
            this.specials = result.payload["DAILY_SPECIAL"];
            this.originalSpecials = result.payload["DAILY_SPECIAL"];
            this.dataSource = new MatTableDataSource<DailySpecial>( result.payload["DAILY_SPECIAL"]);
          } else {
            this.specials = [];
            this.originalSpecials = [];
            this.dataSource = new MatTableDataSource<DailySpecial>([]);
          }
        });
  }

  getCategoriesList():void{
    this.dailyspecials.getCategories().subscribe(result => {
      let payload = result?.items;
      if(payload!=null && payload.length > 0){
        const res = payload[0];
        const categoryIDsMap = res["categoryIDsMap"];
        const subCategoryIDsMap = res["subCategoryIDsMap"];
        const catAndSubCat = res["categories"];
        this.categoryIdMap = categoryIDsMap;
        this.subCategoryIdMap = subCategoryIDsMap;
      this.categoryList = catAndSubCat.map((item: any) => item.categoryName);
      this.categoryList.sort()
      catAndSubCat.forEach((item: any) => {
        this.subCategoryList[item?.categoryName] = item?.subcategories;
        this.subCategoryList[item?.categoryName].sort();
      });
      this.menuCodeList = this.menuCodeList.concat(this.categoryList);
      this.showForm = true;
      this.onCategoryChange();
     }
     else{
      console.error('Failed to get Categories');
      this.openSnackBar('Categories List is null', CONSTANTS.FAILURE_MESSG_STYLE);
     }
    }, error => {
      console.error('Failed to get Categories', error);
      this.showForm = false;
      this.removeDailySpecialsCreation('Hiding the DailySpcials Form because could not able to get the categories and subcategories list from the DB');
      this.openSnackBar('Failed to get Categories', CONSTANTS.FAILURE_MESSG_STYLE);
    });
  }


  
  validateMealType(c: FormControl) {
   return c.value != 'Select Meal Type' ? null : {
      validateMealType: {
          valid: false
          }
        };
  }

  validateCategory(c: FormControl) {
    return c.value != 'Select Category' ? null : {
      validateCategory: {
          valid: false
          }
        };
  }

  validateStartTime(c:FormControl) {
    return c.value != '' ? null : {
     
      validateStartTime: {
          valid: false
          
          }     
        };
  }

  validateQuantity(c: FormControl) {


    var quantity =  c.value;
    if (quantity > 0 || quantity <= 0 ) { //check if is number
      if (quantity < 0 ) {
        this.openSnackBar('Quantity can be either greater than zero OR n/a', CONSTANTS.FAILURE_MESSG_STYLE)
        return false;
      }
    } else {
       if (quantity == 'n/a' || quantity == 'N/A') {
       //  return true;
       } else {
        this.openSnackBar('Quantity can be either greater than zero OR n/a', CONSTANTS.FAILURE_MESSG_STYLE)
        return false;
       }
    }
    return true;



    /*
    return (c.value == 'n/a' || c.value == 'N/A' || c.value > 0 ) ? true : {
      validateQuantity: {
           valid: false
           }
         };
         */
   }

   process(event) {
     /*
    if (event < 0  || event >= 0) {
      this.quantity = "Not Applicable";
    }
    */
   }
/*
  salePriceValidate (c:FormControl) {
   //var orgiPrice:Number = new Number(this.contactForm.get('salePrice').value);
   
    return (Number (c.value) <= this.origPriceVar) ? null : {
      salePriceValidate: {
          valid: false
          }     
        };
  }
  */
/*
  validateMealType(c: FormControl) {
    return c.value != 'Select Meal Type' ? null : {
       validateMealType: {
           valid: false
           }
         };
   }
   */

  isAllSelected() {

   
    if (this.dataSource == null) {
      return;
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  setSendButtonStatus(ev:Event, row:any){
    var list:number[] = new Array();
   
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        list.push(row.menuId )
      }
    });
    var selCt:number = 0;
    if ( this.selection.isSelected(row)) {
      selCt = list.length-1;
    } else {
      selCt = list.length+1;
    }
    if (selCt ==0)  { 
         this.enableNotificationBtn  = false;
    } else {
      this.enableNotificationBtn = true;
    }
   
    if (ev) {
      this.selection.toggle(row) 
    }else {
      return null;
    } 
  
  }

  setSendButtonStatusMasterCheckbox(){
    if(this.selection.hasValue() ) {
      this.enableNotificationBtn  = true;
    } else {
      this.enableNotificationBtn  = false;
    }
    return this.selection.hasValue() && this.isAllSelected();
  }

  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.dataSource == null) {
      return;
    }
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: DailySpecial): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.menuItemName + 1}`;
  }

  uploadImage(event, item:DailySpecial) {
    
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var num:number = Math.round(file.size/1024);
      if (num.valueOf() > 2000) {
       // alert("File size exceed maximum allowed size of 2 MB");
        this.openSnackBar('File size exceed maximum allowed size of 2 MB','Failure');
        return;
      }
      var splitFileName:string[] = file.name.split(".");
      if (splitFileName?.length != 2) {
        this.openSnackBar('Invalid File','Failure');
        return;
      }

      var validFileExtn:boolean = false;
      var fileExtension:string = splitFileName[1];
      for(var extn of CONSTANTS.ALLOWED_FILE_TYPES ) {
        if (fileExtension == extn) {
          validFileExtn = true;
          break;
        }
      }
      if ( validFileExtn == false) {
        this.openSnackBar('Invalid File','Failure');
        //var str:string = 'Allowed File extensions are :';
        //str = str + CONSTANTS.ALLOWED_FILE_TYPES.toString();
       //this.openSnackBar(str , '');
        return;
      }

      
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append("item", item.menuId + "");
      item.isLoading = true;
      this.dailyspecials.uploadImage(formData).subscribe (
        result=> {
          item.isLoading = false;
          var imageUrl:string = result.payload["IMAGE_URL"];
          item.imageUrl = imageUrl;
        }
      );
    }
  }



  sendNotifications():void {
    //this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.SUCCESS_MESSG_STYLE);
    // return;

    var list:number[] = new Array();
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        list.push(row.menuId )
      }
    });
    if (list.length == 0) {
     
      this.openSnackBar('Please select one ore more Daily Specials to send Notifications',CONSTANTS.FAILURE_MESSG_STYLE);
      return;
    }
    this.isLoading = true;
    this.dailyspecials.sendNotifications(list, this.user.id)
    .subscribe(result => {
      this.isLoading = false;
        if (result.result) {
          this.openSnackBar('Notifications sent', CONSTANTS.SUCCESS_MESSG_STYLE);
          this.selection.clear();

       } else {
        this.openSnackBar('Send Notification Failed', CONSTANTS.FAILURE_MESSG_STYLE);
       }
    }
    );
  }

  delete(dailySpl:DailySpecial):void {
    //alert("clicked" + order.customerOrderId);
   var  bodyMessage:Array<string> = new Array();

   bodyMessage.push(dailySpl.menuItemName );
   
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"delete_forever",
        title: "Delete?", message: bodyMessage, 
        yesButtonMessage:"Delete",
        noButtonMessage:"No", 
        result:this.result
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.callDelete(this.user.id, dailySpl.menuId);
        // alert('Mark complete ' + order.customerOrderId);
      }
    });
  }

  callDelete(storeId:number, menuId:number):void {
    this.dailyspecials.callDelete(storeId, menuId)
    .subscribe(result => {
        this.response = result;
        this.getDailySpecials();
        }
      );
  }

  
  copyValueToSalePrice(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.contactForm.patchValue({'salePrice':filterValue});

  }


 
}
