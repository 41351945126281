<div class="banner">
  <h3 *ngIf="displayMode!='NEW'">Change Menu</h3>
  <h3 *ngIf="displayMode=='NEW'">Add Menu</h3>
</div>
<div style="width: 100%; margin-left: 20px;" >
  
    <form *ngIf="showForm" [formGroup]="menuUpdateForm" >
      <div *ngIf="displayMode=='CHANGE'">
        <mat-form-field appearance="fill">
          <mat-label >Menu ID</mat-label>
          <input matInput placeholder="Placeholder" formControlName="menuID"
           required ng-valid readonly> 
        </mat-form-field> &nbsp;
      </div>
        <mat-form-field appearance="fill">
            <mat-label >Menu Title</mat-label>
            <input matInput placeholder="Placeholder" formControlName="menuItemName"
             required ng-valid> 
          </mat-form-field> &nbsp;
          <mat-form-field appearance="fill">
            <mat-label >Suffix</mat-label>
            <input matInput placeholder="Placeholder" formControlName="suffix"
             required ng-valid> 
          </mat-form-field> &nbsp;
          <br>
          <br>
          <mat-form-field appearance="fill">
            <mat-label>Original Price</mat-label>
            <input matInput type="number" class="example-right-align" 
             formControlName="orgPrice" required>
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>&nbsp;
          <mat-form-field appearance="fill">
            <mat-label>Sale Price</mat-label>
            <input matInput type="number" class="example-right-align" 
             formControlName="salePrice" required>
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          <br>
<!--
          <div *ngIf="displayMode=='NEW'">

            <mat-form-field appearance="fill">
              <mat-label>Select Category</mat-label>
              <mat-select  formControlName="menuCategory" required >
                <mat-option  *ngFor="let category of CatagoriesList" value="{{category.categoryName}}">{{category.categoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          -->
        
          <mat-form-field appearance="fill">
            <mat-label >Menu Code</mat-label>
            <mat-select  formControlName="menuCode" required >
              <mat-option  *ngFor="let item of menuCodeList" value="{{item}}">{{item}}</mat-option>
            </mat-select>
          </mat-form-field>&nbsp;
          <br>
            <mat-form-field appearance="fill">
              <mat-label>Select Category</mat-label>
              <mat-select [value]="currentCategoryID" required (selectionChange)="onCategoryChange($event.value)" >
                <mat-option  *ngFor="let cat of categoryList" [value]="cat.categoryId" >{{cat.categoryName}}</mat-option>
              </mat-select>
            </mat-form-field>
            &nbsp;
          <mat-form-field appearance="fill">
            <mat-label>Select Sub Category</mat-label>
            <mat-select [value]="currentSubCategoryID" (selectionChange)="onSubCategoryChange($event.value)"  required >
              <mat-option  *ngFor="let category of subCategoryList[currentCategory]" [value]="category.subCategoryId">{{category.subCategoryName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field appearance="fill" style="width: 70%;">
            <mat-label>Description</mat-label>
            <textarea matInput  class="example-right-align"  formControlName="menuItemDiscription" 
            required>{{menu.menuItemDescription}}</textarea>
            <span matPrefix>&nbsp;</span>
          </mat-form-field>
          <br>
          <div *ngIf="displayMode=='NEW'">
          <mat-form-field appearance="fill">
            <mat-label>Start Time</mat-label>
           <input matInput [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="Date Time" formControlName="availableStartTime" required
            width="50px">
           </mat-form-field>
           <owl-date-time [pickerType]="'both'" #dt1></owl-date-time>&nbsp;
           <mat-form-field appearance="fill">
             <mat-label>End Time</mat-label>
             <input matInput [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"  placeholder="Date Time" formControlName="availableEndTime" required>
           </mat-form-field>
         <owl-date-time [pickerType]="'both'" #dt2></owl-date-time>
        </div>
    <br>
    <div *ngIf="displayMode=='CHANGE'">
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select  formControlName="status" required >
          <mat-option  *ngFor="let status of itemStatus" value="{{status}}">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <br>
    <button mat-raised-button color="primary" (click)="onSubmit()" >{{submitButtonLabel}}</button>&nbsp;
    <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
    </form>
    
  </div>
