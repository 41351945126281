
//import * as _ from "../../../node_modules/@types/dymo-label-framework";
//import * from '../../../node_modules/@types/dymo-label-framework/index';
import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { Validators } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {OrderInfoChangeComponent} from '../order-info-change/order-info-change.component';
import {Customer, PerkzAddRequest, PerkzWeb} from "../constants";
import { OrderReportCriteria } from '../OrderReportCriteria';
//import { GroceryOrderPackerSelectionComponent} from '../grocery-order-packer-selection';
import { DialogComponent } from '../dialog/dialog.component';
import {CONSTANTS, FileWrapper} from '../constants';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {MatTableDataSource} from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { CustomerOrderMenu, Order,PopupResult,PrebookItem,PrebookOrder,SelectForTodaysDeliveriesResponse } from '../order';
import { ConversionRequest, GenerateDeliveryRoute } from './DeliveryRoute';
import { OrderService } from '../order.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GroceryOrderPackerSelectionComponent } from '../grocery-order-packer-selection/grocery-order-packer-selection.component';
import { SpecialInstructionsComponent } from '../special-instructions/special-instructions.component';
import { MessageComponent } from '../message/message.component';
import { AuthenticationService } from '../authentication.service';
import { User } from '../_models/user';
import { ActivatedRoute, Route, Router } from '@angular/router';
@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css']
})
export class SuperAdminComponent implements OnInit {
  searchValue:string = "Search By"
   // test = require('../../../node_modules/@types/dymo-label-framework/index.d.ts');
 

 // dymoLabelFramework = require("dymo-label-framework");

 disableSearch:boolean = true;
  result: PopupResult;
  isLoading:boolean = false;
  links = ['Order Processing', 'GenerateRoute', 'Tracking URLs', 'Search', 'Perkz', 'Pre Orders'];
  stores:Array<Object>;
  orders : Order[];
  prebookOrders : PrebookOrder[];
  perkzItems: PerkzWeb[];
  trackingUrls: SelectForTodaysDeliveriesResponse[];
  isCopiedtoClipboard:boolean = false;
  sourceTodaysOrders: Order[] ;

  selection = new SelectionModel<Order>(true, []);
  activeLink:string;
  //reOrderedList: Order[];
  contactForm: FormGroup;
  customSearchForm: FormGroup;
  perkzSearchForm: FormGroup;
  addPerkzForm: FormGroup;

  todaysOrders:Array<Order> =new Array<Order>();
  routeOrders:Array<Order> =new Array<Order>();
  selectedPrebookOrders:Array<PrebookOrder> =new Array<PrebookOrder>();

  associate:string = "";
  displayedCustomerColumns: string[] =['Id','Order Id', 'Used Perkz $', 'Assigned', 'Comments'];
  displayedColumns: string[] =['Order ID', 'Customer Name','Phone', 'Address', 'CreationDate'];
  searchByItems: Array<string> = ['Address', 'Customer Id'];
  searchCustomerByItems: Array<string> = ['Customer Id'];
  //'Customer Name', 
  //'Order Items', 'Finish Date', 'Status', 'Total Amt', 'Time', 'Payment Mode', 'Order Type','Phone', 'accept',
  //'mark_confirm', 'Delete'
  URLtoShare:string = "";
  dataSource:any;
  perkzDataSource:any;
  searchById:string;
  searchByValue:string;
  customer:Customer = new Customer();
  availableRewards:string = "";
  showAddScreen = false;
  disableConversionButton = false;
  user:User;

  constructor (private adminService:AdminService, private authService: AuthenticationService,
     private orderService:OrderService, private formBuilder: FormBuilder,  private router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) {
      this.authService.currentUser.subscribe(x => this.user = x);
      if (this.user.id != 0 && this.user.id != 25) {
        this.logout();
      }
    //this.getAllStores();
    this.createContactForm();
    this.createCustomContactForm();
    this.createPerkzForm();
    this.createAddPerkzForm();
    this.getTodaysOrders();
  }
  logout() 
  {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  onDrop(event: CdkDragDrop<Order[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  

  showInstructionsScreen(order:Order) {
    var  bodyMessage:Array<string> = new Array();
    
    bodyMessage.push("test");
    
     const dialogRef = this.dialog.open( SpecialInstructionsComponent, {
       width: '500px',
       disableClose: true,
       data: {
        orderItem: order,
        displayMode: "CHANGE"
       }
       
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if (result == true) {
        this.onSubmit();
       } else {
         //alert ("Dont reresh me");
       }
     });
  }

  showUpdateScreen(order:Order) {
    var  bodyMessage:Array<string> = new Array();
    
    bodyMessage.push("test");
    
     const dialogRef = this.dialog.open( OrderInfoChangeComponent, {
       width: '500px',
       disableClose: true,
       data: {
        orderItem: order,
        displayMode: "CHANGE"
       }
       
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if (result == true) {
        this.onSubmit();
       } else {
         //alert ("Dont reresh me");
       }
     });
  }
  destinationOnDrop(event: CdkDragDrop<Order[]>) {
    console.log("hi")
    if (event.isPointerOverContainer == false) {
      var sourceMenuItem:Order = event.previousContainer.data[event.currentIndex];
      const index =  event.container.data.indexOf(sourceMenuItem);
      if (index > -1) {
        event.container.data.splice(index, 1);
      }

      return;
    }
   // alert(event.t);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
       //don't allow duplicates 
       //Start
      var customerOrderId:number = (event.previousContainer.data[event.previousIndex]).customerOrderId;
       var  contains:Boolean = false;
       if ( event.container.data != null) {
        event.container.data.forEach(element => {
          if (element.customerOrderId == customerOrderId ) {
            contains = true;
          }
        });
        if ( contains == true) {
            return ;
        }
      }
       //end
       
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  ngOnInit(): void {
    if (this.user.id != 0 && this.user.id != 25) {
      this.logout() ;
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.activeLink = this.links[event.index];
    /*
    if (event.index == 5) {
      alert (this.activeLink);
      
    }
    */
    // alert(event.index);
    /*
    this.activeLink = this.links[event.index];
    if (event.index == 1) {
          
    } else if ((event.index == 2)) {
        this.isLoading = true;
        this.adminService.getAllMenuItems(this.storeId , -1)
            .subscribe(result =>  {
              this.isLoading = false; 
              this.MenuList = new Array();
              this.MasterMenuList = new Array();
              if (result.items != null && result.items.length > 0) {
                this.MenuList = result.items as MenuItem[];
                this.MasterMenuList = result.items as MenuItem[];
              }
              
            });  
           
    } else  if ((event.index == 3)) {
      this.getCategoriesInformation(this.storeId + "");
    }
    */
  }
  getAllStores(): void {
    
    this.isLoading = true;
    this.adminService.getAllStores()
        .subscribe(result =>  {
          this.isLoading = false; 
          if (result.items != null && result.items.length > 0) {
            this.stores = result.items;
          }
          
        });  
  }
  

  storeSelectionChange(event) {
  }
  markTodaysRoutes()  {
    var postData: GenerateDeliveryRoute = new GenerateDeliveryRoute();
    postData.associate = this.associate;
    postData.orders = this.todaysOrders;
   
    this.orderService.markTodaysRoutes(postData)
        .subscribe(result => {

          this.isLoading = false;
          this.trackingUrls = result.items as SelectForTodaysDeliveriesResponse[];
               //this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
               this.todaysOrders = new Array<Order>();
               this.getTodaysOrders();
               
            }
          );
          
  }

  getDayDiff(passedOrder:Order): number {
    const msInDay = 24 * 60 * 60 * 1000;
    const startDt = new Date(passedOrder.orderCreationTime);
    const endDt = new Date(passedOrder.requestedDeliveryDate);
    var result = Math.round(
          Math.abs(endDt.getTime() - startDt.getTime()) / msInDay,
        );
    if (passedOrder.orderStatus.toUpperCase() ==  "DELIVERED") {
      result = 1; // we dont need color coding as future order as its already delivered
    }
    if(passedOrder.deliveryScheduledOn != null) {
      //if deliveryscheduled on is there then they are already processed and i do not want them to show at top
      result=-3;
    }
    return result;
   
  }

  onGenerateRoute() {
    var postData: GenerateDeliveryRoute = new GenerateDeliveryRoute();
    postData.associate = this.associate;
    postData.orders = this.routeOrders;
    this.isLoading = true;
    this.orderService.generateDeliveryRoute(postData)
      
        .subscribe(result => {
          this.isLoading = false;
               this.URLtoShare = "https://portal.iperkz.com/#/" + result.message
               this.onSubmit();
               
            }
          );
  }
  
  onConvertToOrder() {
    this.disableConversionButton = true;
   // alert (this.selectedPrebookOrders.length);
    var postData: ConversionRequest = new ConversionRequest();
    postData.preorders = this.selectedPrebookOrders;
    this.isLoading = true;
    this.orderService.convertToOrder(postData)
      
        .subscribe(result => {
          this.isLoading = false;
              // this.onSubmit();
              this.selectedPrebookOrders = new Array<PrebookOrder>();
              this.onSubmit();
             
            }
          );
  }
  onAddPerkz() {
    console.log("on ADD PERKZ");
    this.showAddScreen = false;
    this.isLoading = true;
    var request:PerkzAddRequest  = new PerkzAddRequest();
    request.customerId =  this.perkzSearchForm.get("searchByValue")?.value;
    request.orderId = this.addPerkzForm.get("orderId")?.value;
    request.storeId = 25;
    request.amount = this.addPerkzForm.get("amount")?.value;
    request.comment = this.addPerkzForm.get("perkzComment")?.value;
    console.log (request);
    
    this.adminService.addCustomerPerkz(request)
    
    .subscribe(result => {
      this.isLoading = false;

         this.onPerkzSearch();
       }
      );
      
  }
  onPerkzSearch() {
    this.isLoading = true;
    //this.perkzSearchForm.get
    var searchVal = this.perkzSearchForm.get("searchByValue")?.value;
    this.perkzItems = new Array();
    this.customer = new Customer();
    this.availableRewards = "";

    this.adminService.getCustomerPerkz(searchVal)
    
      .subscribe(result => {
        this.isLoading = false;
        this.perkzItems = result.perkzItems;
        this.customer = result.customer;
        //alert (result.availableRewards);
        this.availableRewards = result.availableRewards;
        //alert (this.availableRewards);
        /*
        result.perkzItems.forEach(item => {
          this.perkzItems.push(item);
        }*/
          this.perkzDataSource = new MatTableDataSource( this.perkzItems);
           //this.dataSource = new MatTableDataSource(this.orders);
            
           
         }
        );
}

  getTodaysOrders() {
    this.adminService.getTodaysOrders()
          .subscribe(result => {
            this.isLoading = false;
              this.todaysOrders = result.items as Order[];
              this.todaysOrders.sort((a, b) => (a.customerOrderId < b.customerOrderId) ? 1 : -1);
              this.sourceTodaysOrders =  this.todaysOrders
            }
            );
  }

  onSubmit() {
    const today = new Date();
    if (this.contactForm.status == 'VALID') {
      this.disableSearch = true;
      var criteria:OrderReportCriteria = new OrderReportCriteria();
      this.routeOrders = new Array<Order>();
      this.isCopiedtoClipboard = false;
      //this.URLtoShare = "";
      this.sourceTodaysOrders     =new Array<Order>();
      criteria.storeId = 25;
      criteria.startDate = this.contactForm.get('startDate')?.value;
      criteria.endDate = this.contactForm.get('endDate')?.value;
      
      criteria.startDate.setSeconds(0);
      criteria.startDate.setMilliseconds(0);
      criteria.startDate.setMinutes(0);

      criteria.endDate.setSeconds(0);
      criteria.endDate.setMilliseconds(0);
      criteria.endDate.setMinutes(0);
      
      if (criteria.endDate < criteria.startDate) {
        this.openSnackBar('Start Date should not be greater than End Date', CONSTANTS.FAILURE_MESSG_STYLE);
        return false;
      } 
      this.isLoading = true;
      if (this.activeLink == 'Pre Orders') {
        //getPrebookOrdersByCriteria
        this.adminService.getPrebookOrdersByCriteria(criteria)
        
          .subscribe(result => {
            this.isLoading = false;
            this.disableConversionButton = false;
           // alert ( "fdf :" + result.items?.length );
              this.prebookOrders = result.items as PrebookOrder[];
            //  alert (this.prebookOrders?.length);
              //this.dataSource = new MatTableDataSource(this.orders);
              this.prebookOrders.forEach(item => {
               /*
                 if (
                  today.getFullYear() === new Date(item.deliveryScheduledOn).getFullYear() &&
                  today.getMonth() === new Date(item.deliveryScheduledOn).getMonth() &&
                  today.getDate() === new Date(item.deliveryScheduledOn).getDate()
                ) {
                  this.sourceTodaysOrders.push(item);
                  this.todaysOrders.push(item);
                }
                */
                
              });
              this.disableSearch = false;
            }
            );
      } else {
        this.adminService.getCustomerOrdersByDate(criteria)
        
          .subscribe(result => {
            this.isLoading = false;
              this.orders = result.items as Order[];
              this.orders.sort((a, b) => (a.customerOrderId < b.customerOrderId) ? 1 : -1);
              console.log(this.orders);
              /*
              this.orders.forEach(item => {
                if (
                  today.getFullYear() === new Date(item.deliveryScheduledOn).getFullYear() &&
                  today.getMonth() === new Date(item.deliveryScheduledOn).getMonth() &&
                  today.getDate() === new Date(item.deliveryScheduledOn).getDate()
                ) {
                  this.sourceTodaysOrders.push(item);
                  this.todaysOrders.push(item);
                }
                
              });
              */
              
            }
            );
      }
    }
  }

  getTodays(){
    const today = new Date();
    this.adminService.getCustomerOrdersByTodaysDate()
        
          .subscribe(result => {
            this.isLoading = false;
              this.orders = result.items as Order[];
              this.orders.sort((a, b) => (a.customerOrderId < b.customerOrderId) ? 1 : -1);
              
              //this.orders.sort((a, b) => (this.getDayDiff(a) < this.getDayDiff(b)) ? 1 : -1);
              console.log(this.orders);
              
            }
            );
  }

  getTipColor(tip, totalPrice) {
    // Define the minimum and maximum tip amounts for your scale
    const minTip = 0;
    const maxTip = totalPrice;
  
    // Clamp the tip amount between minTip and maxTip
    const clampedTip = Math.max(minTip, Math.min(maxTip, tip));
  
    // Calculate the percentage of the tip amount in the range [minTip, maxTip]
    const percentage = (clampedTip - minTip) / (maxTip - minTip);
  
    // Calculate the color intensity based on the percentage
    const intensity = Math.round(percentage * 255);
  
    // Return a CSS color in the rgb format
    return `rgb(${intensity}, ${255 - intensity}, 0)`;
  }

  createContactForm(){
    this.contactForm = this.formBuilder.group({
      startDate: ['', Validators.required],  
      endDate: ['', Validators.required]
    });
  }

  createCustomContactForm(){
    this.customSearchForm = this.formBuilder.group({
      searchById:['', Validators.required],
     searchByValue: ['', Validators.required]  
    //  endDate: ['', Validators.required]
    });
  }
  createAddPerkzForm() {
    this.addPerkzForm = this.formBuilder.group({
      orderId:['', Validators.required],
      amount:['', Validators.required],
     perkzComment: ['', Validators.required]  
    //  endDate: ['', Validators.required]
    });
  }
  createPerkzForm(){
    this.perkzSearchForm = this.formBuilder.group({
      searchById:['', Validators.required],
     searchByValue: ['', Validators.required]  
    //  endDate: ['', Validators.required]
    });
  }

  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

  copyValueToClipboard() {
    navigator.clipboard.writeText(this.URLtoShare);
    this.isCopiedtoClipboard = true;
  }

  


  dothis()  {
    //alert("test");
   // this.openSnackBar('Start Date should not be greater than End Date', CONSTANTS.FAILURE_MESSG_STYLE);
   var  bodyMessage:Array<string> = new Array();

   bodyMessage.push("ssilly");
   
   const dialogRef = this.dialog.open(GroceryOrderPackerSelectionComponent, {
    width: '350px',
    data: {
      titleIconText:"fastfood",
      title: "New Order Request", message: bodyMessage, 
      yesButtonMessage:"Accept",
      noButtonMessage:"Decline", 
      result:this.result
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed' + result.userMessage);

  });
  }

  searchByAddress() {
    this.adminService.searchByAddress(this.searchValue).subscribe(result => {
      this.isLoading = false;
        this.orders = result.items as Order[];
        this.dataSource = new MatTableDataSource(this.orders);
          this.orders.forEach(item => {
         });
         
       }
      );
  }
  
  searchByAddressNew(addrText:string) {
    this.adminService.searchByAddress(addrText).subscribe(result => {
      this.isLoading = false;
        this.orders = result.items as Order[];
        this.dataSource = new MatTableDataSource(this.orders);
          this.orders.forEach(item => {
         });
         
       }
      );
  }

  getAllCustomerOrders(customerId:number) {
    this.adminService.getAllCustomerOrders(customerId).subscribe(result => {
      this.isLoading = false;
        this.orders = result.items as Order[];
        this.dataSource = new MatTableDataSource(this.orders);
          this.orders.forEach(item => {
         });
         
       }
      );
  }

  onCustomSearch() {
     var searchById:string = this.customSearchForm.get('searchById')?.value;
      this.searchValue = this.customSearchForm.get("searchByValue")?.value;

     
      if (searchById == 'Address' ) {
        this.searchByAddressNew(this.searchValue);
      } else if (searchById == 'Customer Id'){ 
        var customerId:number = +this.searchValue;
        this.getAllCustomerOrders(customerId)
      }else  {
          alert(searchById);
      }

     // criteria.startDate = this.contactForm.get('startDate').value;
      //criteria.endDate = this.contactForm.get('endDate').value;
  }

  onSelectionChange(ev:any, row:Order) {
   // alert(ev.checked + " - " + row.customerOrderId);
   if (ev.checked) {
    this.routeOrders.push(row);
   } else {
   // this.routeOrders.pop(row);
   var index = 0;
    this.routeOrders.forEach(element => {
      if (element.customerOrderId == row.customerOrderId) {
        this.routeOrders.splice(index, 1);
      }
      index++;
    });
   }
    //this.routeOrder
  }

  moveTodaysOrders(ev:any, row:Order) {
    // alert(ev.checked + " - " + row.customerOrderId);
    if (ev.checked) {
      var found = false;
      this.todaysOrders.forEach(element=>{
        if (element.customerOrderId == row.customerOrderId) {
          found = true;
          return;
        }
      });
      if (found == false) this.todaysOrders.push(row);
    } else {
    // this.routeOrders.pop(row);
    var index = 0;
     this.todaysOrders.forEach(element => {
       if (element.customerOrderId == row.customerOrderId) {
        this.todaysOrders.splice(index, 1);
       }
       index++;
     });
    }
     //this.routeOrder
   }

  onPrebookSelectionChange(ev:any, row:PrebookOrder) {
    // alert(ev.checked + " - " + row.customerOrderId);
    if (ev.checked) {
      this.selectedPrebookOrders.push(row);
    } else {
      var index = 0;
      this.selectedPrebookOrders.forEach(element => {
        if (element.bookingId == row.bookingId) {
          this.selectedPrebookOrders.splice(index, 1);
        }
        index++;
      });
    }
  }
  onEmail() {
    var  bodyMessage:Array<string> = new Array();
    
   bodyMessage.push("test");
   
    const dialogRef = this.dialog.open( MessageComponent, {
      width: '500px',
      disableClose: true,
      data: {
        preOrders: this.selectedPrebookOrders,
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //alert ("refresh me");
        //this.getMenuInformation();
      } else {
        //alert ("Dont reresh me");
      }
    });
  }
  getMenuItems(item:PrebookItem) {
   if (item.quantity > 0) {
    return item.menuName;
   } else {
    return '';
   }
  }
  
}
