import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder} from  '@angular/forms';
import {OrderService} from '../order.service';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {AdditionChangeData} from '../DialogData';
import { AdminService } from '../admin.service';
import { MenuItem, MenuItemAdditions } from '../_models/menu-changes';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-change-addition',
  templateUrl: './change-addition.component.html',
  styleUrls: ['./change-addition.component.css']
})
export class ChangeAdditionComponent implements OnInit {
  changeAdditionsForm: FormGroup;
  addition:MenuItemAdditions;
  constructor(public dialogRef: MatDialogRef<ChangeAdditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AdditionChangeData, private orderService:OrderService,
    private formBuilder: FormBuilder, private adminService:AdminService, private _snackBar: MatSnackBar) {
      this.addition = data.addition;
    this.changeAdditionsForm = this.formBuilder.group({
      price: [this.addition.price, Validators.required]
      });
   }

  ngOnInit(): void {
  }

  submit(){
    this.addition.price =  this.changeAdditionsForm.get('price').value;
    this.adminService.changeAdditionPrice(this.addition)
        .subscribe(result =>  {
          if (result.result == true) {
            this.dialogRef.close(true);
           } else {
           }
          
        }); 
  }

  closeWindow(){
    this.dialogRef.close(false);   
  }
}
