<br>
<div style="width: 100%; margin-left: 20px;" >
  <mat-select (selectionChange)="storeSelectionChange($event)" style="width: 200px;background-color: red; ">
    <mat-option  *ngFor="let storeItem of stores" 
    value="{{storeItem.storeId}}" >{{storeItem.storeName}}</mat-option>
  </mat-select>
 
</div>

<div class="loading-icon-loc" *ngIf="isLoading">
  <img src="assets/loading.gif"  width="70px" height="70px"/>
</div>

<mat-tab-group style="width: 100%;" (selectedTabChange)="tabChanged($event)" >
  <mat-tab label = "{{links[0]}}">  
    <br>
    <div class="container" >
      <div >
        <mat-radio-group aria-label="Select an option" (model)="mealType" (change)="mealTypeSelectionChange($event)">
          <mat-radio-button class="radio-button-style" value="1" [checked]="true">Breakfast</mat-radio-button>
          <mat-radio-button class="radio-button-style" value="2">Lunch</mat-radio-button>
          <mat-radio-button class="radio-button-style" value="3">Dinner</mat-radio-button>
          <mat-radio-button class="radio-button-style" value="4">All Day</mat-radio-button>
          <mat-radio-button class="radio-button-style" value="-1">Show All</mat-radio-button>
          <button  mat-raised-button color="primary" (click) ="changeCategoriesOrder()" 
          style="float:right;margin-right: 40px;">Apply Category Changes</button>
        </mat-radio-group>
        
      </div>  
     
      <div *ngIf="mealType==-1"
      cdkDropList 
      #catagoriesList="cdkDropList" 
      [cdkDropListData]="CatagoriesList"
      class="movie-list" 
      (cdkDropListDropped)="onDrop($event)">
      <div class="movie-block" *ngFor="let catagoriesList of CatagoriesList" cdkDrag>{{catagoriesList.categoryName}}</div>
    </div>

    <div *ngIf="mealType != -1"
    cdkDropList 
    #catagoriesList="cdkDropList" 
    [cdkDropListData]="CatagoriesList"
    class="movie-list" 
    (cdkDropListDropped)="onDrop($event)">
      <div class="movie-block" *ngFor="let catagoriesList of CatagoriesList" >{{catagoriesList.categoryName}}</div>
    </div>
    </div>
  </mat-tab>
  <mat-tab label = "{{links[1]}}">
    <br>
      <div style="width: 40%; margin-left:20px; float: left;" >
        <div fxLayout fxLayoutAlign="center center">
          <mat-form-field fxFlex="40%">
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <img src="/assets/add.png" style="float: right; margin-right: 125px;"
          width="50px" height="50px" (click)="addNew()"/>
        </div>
        <div class="loading-icon-loc" *ngIf="totalMenuItemsLoading">
          <img src="assets/loading.gif"  width="70px" height="70px"/>
        </div>
        <div *ngIf="!totalMenuItemsLoading"> 
        <div
          cdkDropList 
          [cdkDropListData]="MenuList"
          [cdkDropListConnectedTo]="[destList]"
          class="movie-list" 
          (cdkDropListDropped)="sourceOnDrop($event)">
    
          <div class="movie-block-href"
           *ngFor="let menuList of MenuList" 
           [ngClass]="{'deleted': menuList.status === 'DELETED', 'out_of_stock': menuList.status === 'OUT_OF_STOCK'}"
           cdkDrag >
            <input type="file" style="display: none" #file (change)="uploadImage($event, menuList)" />
            <span style="float: left;"> 
              <button mat-button (click)="file.click()" >
                <img src="{{menuList.imageUrl}}"  width="35px" height="35px" />
              </button>
           
            <a (click)="showUpdateScreen(menuList)" > {{menuList.menuItemName}}</a>
          </span>
            <span style="float: right;"> {{menuList.salePrice | currency:'USD':'symbol':'1.2-2'}} </span>
          </div>
        </div>
        </div>
      </div>
      <div style="margin-left: 45%; ">
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Select Category</mat-label>
            <mat-select (selectionChange)="onCategoryChange($event.value)" required [(ngModel)]="currentCategory" >
              <mat-option  *ngFor="let meal of categoryList" value="{{meal}}">{{meal}}</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill">
            <mat-label>Select Sub Category</mat-label>
            <mat-select (selectionChange)="onSubCategoryChange($event.value)" required [(ngModel)]="currentSubCategory" >
              <mat-option  *ngFor="let category of subCategoryList[currentCategory]" value="{{category}}">{{category}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <br>
       <div>
        <span>Sort By: </span>
          <mat-radio-group aria-label="Sort by" [(ngModel)]="sortOption" (ngModelChange)="onSortOptionChange($event)">
            <mat-radio-button *ngFor="let option of sortOptions" [value]="option.value" style="margin-right: 10px;">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
      </div>
      <div>
        <button *ngIf=isOrderedChanges mat-raised-button color="primary" (click)="applyChanges()" >
         Apply Changes
        </button>
      </div>
        <div
        cdkDropList 
        #destList="cdkDropList" 
        [cdkDropListData]="MealTypeMenuList"
        class="movie-list" 
        (cdkDropListDropped)="destinationOnDrop($event)">
        <div class="movie-block" *ngFor="let mealTypeMenuList of MealTypeMenuList"
         cdkDrag>{{mealTypeMenuList.menuItemName}}</div>
      </div>

      </div>
   </mat-tab>
  <mat-tab label = "{{links[2]}}">
    <div style="width: 40%; margin-left:20px; float: left;" >
      <div fxLayout fxLayoutAlign="center center">
        <mat-form-field fxFlex="40%">
          <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
       </div>
     <div [ngClass]="{'custom-block-selected': menuList.menuId==selectedMenu.menuId, 'custom-block': menuList.menuId!=selectedMenu.menuId}"
     (click)="selectMenu(menuList)"
     *ngFor="let menuList of MenuList">{{menuList.menuItemName}} - {{menuList.salePrice | currency:'USD':'symbol':'1.2-2'}}
     
      <span *ngIf="menuList.additions?.length > 0" style="float: right;"> (+ {{menuList.additions?.length}})
        <button  mat-raised-button color="primary" (click) ="onAddAdditions(menuList)" 
         >Add Addition</button>
       </span>
        <span *ngIf="menuList.additions?.length == 0" style="float: right;">
          <button  mat-raised-button color="primary" (click) ="onAddAdditions(menuList)" 
           >Add Addition</button></span>
      </div>
     </div>

     <div style="margin-left: 45%; width: 20%;" >
      <div class="custom-block" 
      *ngFor="let addition of selectedMenu.additions">{{addition.name}}
      <span style="float: right;">
       
       <!-- <button  mat-raised-button color="primary" (click) ="onDeleteAdditions(addition)">Delete</button> -->
        <a [routerLink]="" (click)="onChangePrice(addition)">{{addition.price | currency:'USD':'symbol':'1.2-2'}}</a> &nbsp;
        <button mat-mini-fab color="warn" (click)="onDeleteAdditions(addition)"><mat-icon>delete_forever</mat-icon></button></span>
      </div>
      
      </div>
      <!-- go -->
  </mat-tab>
  <mat-tab label = "{{links[3]}}">
    <div style="width: 40%; margin-left:20px; float: left;" >
      <div [ngClass]="{'custom-block-selected': catagoriesList.categoryName==selectedCategory.categoryName,
         'custom-block': catagoriesList.categoryName!=selectedCategory.categoryName}"
      (click)="selectCategory(catagoriesList)"
      *ngFor="let catagoriesList of CatagoriesList">{{catagoriesList.categoryName}}
      </div>
    </div>
      
    <div style="margin-left: 45%; width: 40%;" >
      <button  mat-raised-button color="primary" (click) ="changeMenuOrderForCategory()" 
          style="float:right;margin-right: 40px;">Apply Changes</button>
    <div 
    cdkDropList 
    #catagoriesList="cdkDropList" 
    [cdkDropListData]="menuItemsByCategory"
    class="movie-list"
    (cdkDropListDropped)="onDrop($event)" >
      <div class="movie-block" *ngFor="let menu of menuItemsByCategory" cdkDrag>{{menu.menuItemName}}
      <span style="float: right;"> {{menu.salePrice| currency:'USD':'symbol':'1.2-2'}} </span>
      </div>
    </div>
    </div>

   
  </mat-tab>
</mat-tab-group>
<button mat-button color="primary" (click) ="itemAt()"> test </button>