import { BrowserModule } from '@angular/platform-browser';
//import * as _ from "dymo-label-framework";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { OrdersComponent } from './orders/orders.component';
import {FormsModule} from '@angular/forms';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { HttpClientModule, HTTP_INTERCEPTORS  }    from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import { DialogComponent } from './dialog/dialog.component'; 
import {MatDialogModule} from '@angular/material/dialog';
import { DailyspecialsComponent } from './dailyspecials/dailyspecials.component';
import {MatFormFieldModule} from '@angular/material/form-field'; 
import  {  ReactiveFormsModule  }  from  '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { MatIconModule } from  '@angular/material/icon';
import { MatCheckboxModule } from '@angular/Material/checkbox';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageComponent } from './message/message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor'
import { ErrorInterceptor } from './_helpers/error.interceptor'
import { fakeBackendProvider } from './_helpers/fake-backend'
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MenuAdminComponent} from './menu-admin/menu-admin.component';
import {MatTabsModule} from '@angular/material/tabs'; 
import {MatRadioModule} from '@angular/material/radio';
import { AboutComponent } from './about/about.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import {MatDatepickerModule, } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { StatuscheckComponent } from './statuscheck/statuscheck.component';
import { PaymentComponent } from './payment/payment.component';
import { StripeModule } from "stripe-angular";
import { MenuInfoChangeComponent } from './menu-info-change/menu-info-change.component'
import {NgxPrintModule} from 'ngx-print';
import { AddAdditionComponent } from './add-addition/add-addition.component';
import { ChangeAdditionComponent } from './change-addition/change-addition.component';
import { RouteComponent } from './route/route.component';
import { TodaysOrdersComponent } from './todays-orders/todays-orders.component';
import { GroceryOrderPackerSelectionComponent } from './grocery-order-packer-selection/grocery-order-packer-selection.component';
import { OrderInfoChangeComponent } from './order-info-change/order-info-change.component';
import { SpecialInstructionsComponent } from './special-instructions/special-instructions.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerChangeComponent } from './customer-change/customer-change.component';
import { InvestorReportComponent } from './investor-report/investor-report.component';
import { EmailComponent } from './email/email.component';
import { RefundComponentComponent } from './refund-component/refund-component.component';
import { ReportComponent } from './report/report.component';
import { ReportDataUpdateComponent } from './report-data-update/report-data-update.component'
import {DatePipe} from '@angular/common';
import { SalePriceUpdateComponent } from './sale-price-update/sale-price-update.component';
import { TestComponent } from './test/test.component';
import { AccountingComponent } from './accounting/accounting.component';

@NgModule({
  declarations: [
    AppComponent,
    OrdersComponent,
    OrderDetailComponent,
    DialogComponent,
    DailyspecialsComponent,
    ImageDialogComponent,
    MessageComponent,
    SnackbarComponent,
    HomeComponent,
    LoginComponent,
    MenuAdminComponent,
    AboutComponent,
    CustomerViewComponent,
    OrderConfirmationComponent,
    SuperAdminComponent,
    StatuscheckComponent,
    PaymentComponent,
    MenuInfoChangeComponent,
    AddAdditionComponent,
    ChangeAdditionComponent,
    RouteComponent,
    TodaysOrdersComponent,
    GroceryOrderPackerSelectionComponent,
    OrderInfoChangeComponent,
    SpecialInstructionsComponent,
    CustomersComponent,
    CustomerChangeComponent,
    InvestorReportComponent,
    EmailComponent,
    RefundComponentComponent,
    ReportComponent,
    ReportDataUpdateComponent,
    SalePriceUpdateComponent,
    TestComponent,
    AccountingComponent
  ],

  imports: [
    DragDropModule,
    MatTabsModule,
    MatRadioModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatInputModule, MatButtonModule,MatButtonToggleModule,
    MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule,MatCheckboxModule,
    MatSelectModule,MatTooltipModule, NgbModule,MatDatepickerModule,
    MatNativeDateModule,
    NgxPrintModule,
    StripeModule.forRoot("pk_test_TrG92etxjtc8DPRg5tpoBapk000OD7ugFv")
  ],
  providers: [MatSnackBar,
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // provider used to create fake backend
        fakeBackendProvider, MatDatepickerModule,
        [DatePipe]
      ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
