import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { Validators } from '@angular/forms';
import { AdminService } from '../admin.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {CONSTANTS} from '../constants';
import { Customer } from '../constants';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  customerForm: FormGroup;
  inviteForm: FormGroup;
  isLoading:boolean = false;
  customers:Customer[];
  selectedCustomerId:string;

  constructor(private formBuilder: FormBuilder, private adminService:AdminService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.createCustomerForm()
    this.createInviteForm()
    this.getCustomers()
  }

  createCustomerForm(){
    this.customerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      phone: ['', Validators.required],  
      email: ['', Validators.required]
    });
  }

  createInviteForm(){
    this.inviteForm = this.formBuilder.group({
      phone: ['', Validators.required],  
      email: ['', Validators.required]
    });
  }

  getCustomers() {
    this.adminService.getTestCustomers()
        
    .subscribe(result => {
      this.isLoading = false;
        this.customers = result.customers as Customer[];
        
      }
      );
  }
  selectItem(order:Customer) {
    this.customers.forEach(element => {
      element.selected = false
    });
    this.selectedCustomerId = order.customerId +""
    order.selected = true;
  }

  createTestOrder(){
   // alert ("dsfdsf");
   this.isLoading = true;
    this.adminService.createTestOrder(this.selectedCustomerId)
    .subscribe(result => {
      this.isLoading = false;
        if (result.result) {
          this.customerForm.reset();
          this.openSnackBar('Order Created', CONSTANTS.SUCCESS_MESSG_STYLE);
       } else {
        this.openSnackBar('Failed', CONSTANTS.FAILURE_MESSG_STYLE);
       }
    }
    );
  }

  onInviteSubmit() {
    if (this.inviteForm.status == 'VALID') {
      var phone = this.inviteForm.get('phone').value;
      var email = this.inviteForm.get('email').value;

      this.adminService.sendInvite(this.selectedCustomerId, phone, email)
    .subscribe(result => {
      this.isLoading = false;
        if (result.result) {
          this.inviteForm.reset();
          this.openSnackBar('Invite Created', CONSTANTS.SUCCESS_MESSG_STYLE);
       } else {
        this.openSnackBar('Failed', CONSTANTS.FAILURE_MESSG_STYLE);
       }
    }
    );
    }
  }

  onSubmit() {
    console.log('Your form data : ', this.customerForm.value );

    
    if (this.customerForm.status == 'VALID') {
      var firstname = this.customerForm.get('firstname').value;
      var phone = this.customerForm.get('phone').value;
      var email = this.customerForm.get('email').value;
  //    alert(phone);
    //  alert (email);
    this.isLoading = true;
    this.adminService.createCustomer(firstname, phone, email)
    .subscribe(result => {
      this.isLoading = false;
        if (result.result) {
          this.customerForm.reset();
          this.openSnackBar('Customer Created', CONSTANTS.SUCCESS_MESSG_STYLE);
       } else {
        this.openSnackBar('Failed', CONSTANTS.FAILURE_MESSG_STYLE);
       }
    }
    );
    }
  }
    
  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

}
