<p>Change Addition - {{addition.name}}</p>


<form [formGroup]="changeAdditionsForm" >
    <mat-form-field appearance="fill">
        <mat-label >Price</mat-label>
        <input matInput type="number" class="example-right-align"  formControlName="price" required>
        <span matPrefix>$&nbsp;</span>
        
      </mat-form-field>

      <br>
      <button mat-raised-button color="primary" (click)="submit()">Submit</button>&nbsp;
      <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
</form>
