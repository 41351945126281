
export class SelectForTodaysDeliveriesResponse {
    orderId:number;
    trackingURL:string;
}

export class PrebookOrder {
    address: string;
    bookingId: number;
    customerId: number;
    deliverySchedule: string;
    email: string;
    firstname: string;
    isNotified: number;
  	lastname: string;
	orderCreationTime: Date;
    prebookItems:PrebookItem[];
    storeId: number;
	totalSalePrice: number;
    converted: number;
}

export class PrebookItem {
    bookingOrderId: number;
	menuId: number;
	quantity: number;
	bookingPrice: number;
	priceRange: string;
	menuName: string;
}

export class CustomerOrderChanges {
    orderId:number;
    orderItems:CustomerOrderMenu[];
    subject:string;
	message:string;
	refundAmount:number;
    customerId:number;
}

export class Order{

    storeId: number;
    customerOrderId:number;
    customerId:number;
    orderCreationTime:Date;
    deliveryScheduledOn:Date;
    orderProcessStartTime:Date;
    orderReadyTime:Date;
    orderClosedTime:Date;
    customerName:string;
    firstName:string;
    lastName:string;
    phone:string;
    email:string;
    menuId:string;
    items: string;
    specialInstructions: string;
    orderStatus:string;
    totalSalePrice:number;
    paymentMode:string;
    chargeMode:string;
    tax:number;
    transactionFee:number;
    amountChargedToCustomer:number;
    menuList:CustomerOrderMenu[];
    discount: number;
    billable: number;
    takeOut: number;
    tipAmount: number;
    isFutureOrder: number;
    requestedDeliveryDate:Date;
    requestedDeliveryDateString: string;
    reasonForDecline: string;
    address: string;
    deliveryAmount: number;
    deliverySeq:number;
    deliveryAssociate:string;
    packingAssociate:string;
    tableNumber:string;
    deliveryInstructions:String
    processTime:String;
    imageUrl:String;
    isLoading: boolean;
    changedDuringCustomerOrder:number;
}

export class PopupResult{
    result: boolean;
    userMessage: string;
}

export class CustomerOrderMenu{
    customerOrderItemId:number;
    menuItemId:number;
    menuItemName:string;
    menuCode:string;
    specialInstructions:string;
    salePrice:number;
    refundAmt:string;
    count:number;
    additionsNames:string;
    actionType:number;
    status:number=0;
    message:string = 'abc';
    action:number;
}

export class CuttingSummaryItem {
 cutType:string = "-";
 name: string;
 count: number;
 completedCount: number;
}

export class FoodSubItem {
    id:number;
    menuId: number;
    menuCode: string;
    cutName:string;
    cutPrice:number;
    imageUrl:string;
    comments:string;
    count:number;
    customerOrderId:number;
    cutVisibleText:string;
    cutStatus:number;
    source:number;
    deliveryAssociate:string;

}

export class FoodSubItemUpdateRequest {
    id: number;
	status: number;
	source: number;
}

export class PrebookOrderReportItem {
    customerOrderId:number;
    customerOrderIds:string;
    customerId: number;
	menuId: number;
    menuName: string;
    menuCode: string;
	salePrice: number;
	orderCreateTime: Date;
    deliveryScheduledOn: Date;
	orderCloseTime: Date;
	address: string;
	firstname: string;
	lastname: string;
    quantity: number;
    originalPrice:number;
    customerOrderItemId:number;
    isPriceEdited:boolean = false;
    isOnFocus:boolean = false;
    isOnFocusForSalePrice:boolean = false;
}
export class ConsolidateVendorSummaryItem {
    order_number:number;
    customerId: number;
	totlaSalePrice: number;
	totalProcurementPrice:number;
    order_date: Date;
    delivery_date: Date;
	address: string;
	customer_name: string;
	
    item_count: number;
    order_date_str: string;
    delivery_date_str: string;

    details:Array<VendorOrderItem> = new Array();
}
export class VendorOrderItem {
    menuId: number;
    menuName: string;
    quantity: number;
}