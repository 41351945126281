import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})
export class ImageDialogComponent implements OnInit {

  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  dummy:String = "sdfadsfdsafdsafdsfdsaf";

  constructor() { }

  ngOnInit() {
    this.dummy = 'sdfdsfasf32fsdfsd';
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
