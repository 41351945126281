<div class="banner"  style="width: 100%;">
  <h3>Email Message to customers</h3>
</div>
<div style="width: 100%; margin-left: 20px;" >
  <form [formGroup]="emailForm" >
    <mat-form-field appearance="fill">
      <mat-label >Subject</mat-label>
      <input matInput placeholder="Placeholder" formControlName="subject"
       required ng-valid> 
    </mat-form-field> &nbsp;
<br>
    <mat-form-field appearance="fill">
      <mat-label>Message</mat-label>
      <textarea matInput  class="example-right-align"  formControlName="message" 
      required></textarea>
      <span matPrefix>&nbsp;</span>
    </mat-form-field> &nbsp;
    <br>
    <button mat-raised-button color="primary" (click)="onSubmit()">Send</button>&nbsp;
    <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
  </form>
</div>