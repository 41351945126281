import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statuscheck',
  templateUrl: './statuscheck.component.html',
  styleUrls: ['./statuscheck.component.css']
})
export class StatuscheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
