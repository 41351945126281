import { Component,ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from  '@angular/forms';
import {AdminService} from '../admin.service';
import { Validators } from '@angular/forms';
import { PrebookReportCriteria } from '../OrderReportCriteria';
import { PrebookOrderReportItem } from '../order';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ReportDataUpdateComponent } from '../report-data-update/report-data-update.component';
import { SalePriceUpdateComponent } from '../sale-price-update/sale-price-update.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  filters:FormGroup = this._formBuilder.group({
    provisions: false,
    veggies: false,
    frozen: false,
    fresh_cut: false,
  });

  user:User;
  contactForm: FormGroup;
  isLoading:boolean = false;
  disableSearchButton:boolean = false;
  prebookReportItems: Array<PrebookOrderReportItem> = new Array();
  masterPrebookReportItems: Array<PrebookOrderReportItem> = new Array();
  reportSummary: Array<PrebookOrderReportItem> = new Array();
  //disableSearch:boolean = true;
  editMark:string = "../../assets/edit-mark-1.png";

  reportDataForDisplay:any;

  reportSummaryForDisplay:any;
  reportSummaryHeaders: string[] =['menuName','quantity','orgPrice',
                              'salePrice', 'address'];

  reportHeaders: string[] =['customerOrderId','customer-name','address', 'quantity',
                            'orgPrice', 'salePrice','%',
                            'menuName', 'orderCreateTime' ];
  checkMark:string = "../../assets/tick.png";

  totalSales:number;
  totalProfit:number;
  

  constructor(private _formBuilder: FormBuilder,
      private authService: AuthenticationService, private router: Router,
     private adminService:AdminService, private formBuilder: FormBuilder, public dialog: MatDialog){ 
      this.authService.currentUser.subscribe(x => this.user = x);
      this.createContactForm();
  }

  logout() 
  {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit(): void {
  }
  createContactForm(){
    this.contactForm = this.formBuilder.group({
      startDate: ['', Validators.required],  
      endDate: ['', Validators.required]
    });
  }
  onSubmit() {
    var criteria:PrebookReportCriteria = new PrebookReportCriteria();

    criteria.startDate = this.contactForm?.get('startDate').value;
    criteria.endDate = this.contactForm?.get('endDate').value;
    //
    criteria.startDate.setSeconds(0);
    criteria.startDate.setMilliseconds(0);
    criteria.startDate.setMinutes(0);

    criteria.endDate.setSeconds(0);
    criteria.endDate.setMilliseconds(0);
    criteria.endDate.setMinutes(0);
    this.totalSales = 0;
    this.totalProfit = 0;
    this.reportSummary = new Array();
    this.disableSearchButton = true;
    this.adminService.getDailyPandLItems(criteria)
    .subscribe(result => {
      this.isLoading = false;
      this.prebookReportItems = new Array();
      let map = new Map<number, PrebookOrderReportItem>(); 
     
      this.prebookReportItems = result.items as PrebookOrderReportItem[];
        this.reportDataForDisplay = new MatTableDataSource(this.prebookReportItems);
        this.prebookReportItems.forEach(item => {
          this.totalSales = this.totalSales + (item.salePrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          const array = item.address.split(',');
          if (array[array.length-2].toUpperCase() == 'NJ') {
            item.address = array[array.length-3];
          } else {
            item.address = array[array.length-2];
          }
          map.get(item.menuId)
          console.log("before");
          if (map.get(item.menuId) === undefined) {
            map.set(item.menuId, this.clone(item));
          } else {
            let fromMap:PrebookOrderReportItem = map.get(item.menuId) as PrebookOrderReportItem;
            fromMap.quantity = fromMap.quantity + item.quantity;
            fromMap.address = fromMap.address + ", " + item.address;
          }
          console.log(map.get(item.menuId));
        });
        map.forEach((value,key) =>{
          this.reportSummary.push(value);
        })
        this.reportSummaryForDisplay = new MatTableDataSource(this.reportSummary);
        this.masterPrebookReportItems = this.prebookReportItems;
        this.disableSearchButton = false;
        this.onFilterChange();
      }
      );
  }

  public clone(source:PrebookOrderReportItem): PrebookOrderReportItem {
    var clonedItem:PrebookOrderReportItem = new PrebookOrderReportItem();
    clonedItem.customerOrderId = source.customerOrderId;
    clonedItem.customerId = source.customerId;
    clonedItem.menuId = source.menuId;
    clonedItem.menuName = source.menuName;
    clonedItem.salePrice = source.salePrice;
    clonedItem.orderCreateTime = source.orderCreateTime;
    clonedItem.orderCloseTime = source.orderCloseTime;
    clonedItem.address = source.address;
    clonedItem.firstname = source.firstname;
    clonedItem.lastname = source.lastname;
    clonedItem.quantity = source.quantity;
    clonedItem.originalPrice = source.originalPrice;
     return clonedItem
 }
/*
  public clone(): any {
    var cloneObj = new (this.constructor() as any);
    for (var attribut in this) {
        if (typeof this[attribut] === "object") {
            cloneObj[attribut] = this[attribut].clone();
        } else {
            cloneObj[attribut] = this[attribut];
        }
    }
    return cloneObj;
}*/

  onMouseEnter(x:PrebookOrderReportItem){
    console.log(" Yahooo " + x.customerOrderId);
    x.isOnFocus = true; 
  }

  onMouseLeave(y:PrebookOrderReportItem){
    console.log(" Google " + y.customerOrderId);
    y.isOnFocus = false; 
  }

  onMouseEnterForSalePrice(x:PrebookOrderReportItem){
    console.log(" Yahooo " + x.customerOrderId);
    x.isOnFocusForSalePrice = true; 
  }

  onMouseLeaveForSalePrice(y:PrebookOrderReportItem){
    console.log(" Google " + y.customerOrderId);
    y.isOnFocusForSalePrice = false; 
  }

  showPriceEditScreen(x:PrebookOrderReportItem) {
    var  bodyMessage:Array<string> = new Array();
       
      bodyMessage.push("test");
      
       const dialogRef = this.dialog.open( ReportDataUpdateComponent, {
         width: '600px',
         disableClose: true,
         data: {
          preBookItem: x,
         }
         
       });
   
       dialogRef.afterClosed().subscribe(result => {
         if (result == true) {
           this.onSubmit();
           //alert ("refresh me");
           //this.getMenuInformation();
         } else {
           //alert ("Dont reresh me");
         }
       });
  }

  showSalePriceEditScreen(x:PrebookOrderReportItem) {
    var  bodyMessage:Array<string> = new Array();
       
      bodyMessage.push("test");
      
       const dialogRef = this.dialog.open( SalePriceUpdateComponent, {
         width: '600px',
         disableClose: true,
         data: {
          preBookItem: x,
         }
         
       });
   
       dialogRef.afterClosed().subscribe(result => {
         if (result == true) {
           this.onSubmit();
           //alert ("refresh me");
           //this.getMenuInformation();
         } else {
           //alert ("Dont reresh me");
         }
       });
  }

  onFilterChange() {
    this.prebookReportItems = new Array();
    //alert (this.filters.get("provisions")?.value);
    var provisionsSelected:boolean = this.filters.get("provisions")?.value;
    var veggiesSelected:boolean = this.filters.get("veggies")?.value;
    var frozenSelected:boolean = this.filters.get("frozen")?.value;
    var freshCut:boolean = this.filters.get("fresh_cut")?.value;
    if (provisionsSelected == false && veggiesSelected == false 
      && frozenSelected == false && freshCut == false) {
        return;
    }
  //  alert (veggiesSelected);
  this.totalSales = 0;
  this.totalProfit = 0;
    this.masterPrebookReportItems.forEach(item=>{
      if (provisionsSelected == true) {
       // alert ("0 : " + item.menuCode);
          if (item.menuCode == 'Provisions') {
            this.totalSales = this.totalSales + (item.salePrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          
            this.prebookReportItems.push(item);
          }
      } else if (veggiesSelected == true)  {
    //    alert ("1 : " + item.menuCode);
        if (item.menuCode == 'Veggies') {
          this.totalSales = this.totalSales + (item.salePrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          
          this.prebookReportItems.push(item);
        }
      } else if (frozenSelected == true)  {
    //    alert ("2 : " + item.menuCode);
        if (item.menuCode == 'Frozen') {
          this.totalSales = this.totalSales + (item.salePrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          
          this.prebookReportItems.push(item);
        }
      } else if (freshCut == true)  {
   //     alert ("3 : " + item.menuCode);
        if (item.menuCode == 'Fresh Cut') {
          this.totalSales = this.totalSales + (item.salePrice * item.quantity);
          this.totalProfit = this.totalProfit + ((item.salePrice - item.originalPrice) * item.quantity);
          
          this.prebookReportItems.push(item);
        }
      }


    });
    this.reportDataForDisplay = new MatTableDataSource(this.prebookReportItems);
  }

  getClass(element:PrebookOrderReportItem) {
    if ((element.salePrice - element.originalPrice) /element.originalPrice > 0) {
      return 'green';
    }  else {
      return 'red';
    }
    
  }
}
