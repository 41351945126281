import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { Validators } from '@angular/forms';
import {AdminService} from '../admin.service';
import { Customer } from '../customers';
import {MatTableDataSource} from '@angular/material/table';
import { CustomerChangeComponent } from '../customer-change/customer-change.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})

export class CustomersComponent implements OnInit {
  isLoading:boolean = false;
  searchByItems: Array<string> = [ 'Customer Id', 'Order Id', 'Phone'];
  customSearchForm: FormGroup;
  customers:Customer[];
  dataSource:any;
  searchValue:string = "Search By"
  displayedColumns: string[] =['Customer Id', 'Customer Name', 'Phone', 'Email', 'Address'];
  constructor(private adminService: AdminService, private formBuilder: FormBuilder,
    public dialog: MatDialog) {
    this.createCustomContactForm();
   }

  ngOnInit(): void {
  }

  onCustomSearch() {
    var searchById:string = this.customSearchForm.get('searchById').value;
      this.searchValue = this.customSearchForm.get("searchByValue").value;

     
      if (searchById == 'Order Id' ) {
        var orderId:number = + this.searchValue;
        this.getCustomerAttributesByOrderId(orderId)
      } else if (searchById == 'Customer Id'){ 
        var customerId:number = +this.searchValue;
        this.getCustomerAttributes(customerId)
      } else if (searchById == 'Phone'){ 
        var phone:string =  this.searchValue;
        this.getCustomerAttributesByPhone(phone)
      } 
  }
  getCustomerAttributesByOrderId(orderId:number) {
    this.adminService.getCustomerAttributesByOrderId(orderId).subscribe(result => {
      this.isLoading = false;
        this.customers = result.items as Customer[];
        this.dataSource = new MatTableDataSource(this.customers);
          this.customers.forEach(item => {
            //  alert(item.customerAttributes.addressDetails[0].city);
         });
         
       }
      );
  }
  getCustomerAttributesByPhone(phone:string) {
    this.adminService.getCustomerAttributesByPhone(phone).subscribe(result => {
      this.isLoading = false;
        this.customers = result.items as Customer[];
        this.dataSource = new MatTableDataSource(this.customers);
          this.customers.forEach(item => {
            //  alert(item.customerAttributes.addressDetails[0].city);
         });
         
       }
      );
  }

  getCustomerAttributes(customerId:number) {
    this.adminService.getCustomerAttributes(customerId).subscribe(result => {
      this.isLoading = false;
        this.customers = result.items as Customer[];
        this.dataSource = new MatTableDataSource(this.customers);
          this.customers.forEach(item => {
            //  alert(item.customerAttributes.addressDetails[0].city);
         });
         
       }
      );
  }

  createCustomContactForm(){
    this.customSearchForm = this.formBuilder.group({
      searchById:['', Validators.required],
      searchByValue: ['', Validators.required]  
    //  endDate: ['', Validators.required]
    });
  }

  showUpdateScreen(customer:Customer, id:number) {
 
    var  bodyMessage:Array<string> = new Array();
     
    bodyMessage.push("test");
    
     const dialogRef = this.dialog.open( CustomerChangeComponent, {
       width: '500px',
       disableClose: true,
       data: {
        cust: customer,
        addressId:id,
        displayMode: "CHANGE",
       }
       
     });
 
     dialogRef.afterClosed().subscribe(result => {
       if (result == true) {
         this.onCustomSearch();
       } else {
       }
     });
   }

}
