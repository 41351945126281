import { Injectable} from '@angular/core';
import { Order } from './order';
import { ExpenseFrequency, ExpenseItem, ExpenseType } from './accounting';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { stringify } from 'querystring';
import { Wrapper, RequestStatus } from './constants';
import { environment } from '../environments/environment';
import {AuthenticationService} from './authentication.service';
import { User } from './_models/user';
import { SimpleResponse } from './SimpleResponse';
import { AnimationDurations } from '@angular/material/core';
import { EmailMessage, MenuItem, MenuItemAdditions } from './_models/menu-changes';
import { ConversionRequest, GenerateDeliveryRoute } from './super-admin/DeliveryRoute';
import { ReportDataUpdate } from './OrderReportCriteria';



@Injectable({
  providedIn: 'root'
})

export class OrderService {
 /*
  private orderUrl = 'http://localhost:8080/api/orders/1/CURRENT';  // URL to web api
  private markCompleteUrl = "http://localhost:8080/api/order/1/{orderid}/COMPLETED";
  private markStartedUrl = "http://localhost:8080/api/order/1/{orderid}/STARTED";
  private markOnHoldUrl = "http://localhost:8080/api/order/1/{orderid}/PLACED";
  private deleteUrl = "http://localhost:8080/api/order/1/{orderid}/DELETED";


 private orderUrl = 'http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/orders/1/CURRENT';  // URL to web api
 private markCompleteUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/order/1/{orderid}/COMPLETED";
 private markStartedUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/order/1/{orderid}/STARTED";
 private markOnHoldUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/order/1/{orderid}/PLACED";
 private deleteUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/order/1/{orderid}/DELETED";
*/

private orderUrl = environment.baseUrl + 'orders/{storeid}/';  // URL to web api
private markCompleteUrl = environment.baseUrl + 'order/{storeid}/{orderid}/COMPLETED';
private markStartedUrl = environment.baseUrl + 'order/{storeid}/{orderid}/STARTED';
private markOnHoldUrl = environment.baseUrl + 'order/{storeid}/{orderid}/PLACED';
private markRejectedUrl = environment.baseUrl + 'order/reject/{storeid}/{orderid}/DECLINED';
private markCancelleddUrl = environment.baseUrl + 'order/reject/{storeid}/{orderid}/CANCELLED';

private updateDeliveryInstructionsURL = environment.baseUrl + 'admin/updateDeliveryInstructions/{orderid}';

private deleteUrl = environment.baseUrl + 'order/{storeid}/{orderid}/DELETED';
private getOrderByOrderId =  environment.baseUrl + 'order/{storeId}/{orderId}';

private updateMenuURL = environment.baseUrl + 'menu/admin/updateMenu';
private createMenuURL = environment.baseUrl + 'menu/admin/createMenu';
private createAdditionURL = environment.baseUrl + 'menu/admin/createAddition';
private generateDeliveryRouteURL = environment.baseUrl + 'menu/admin/generateRoute';
private setPackingAssociateURL = environment.baseUrl + 'menu/admin/updatePackingAssociate';
private markTodaysRoutesURL = environment.baseUrl + 'menu/admin/selectForTodaysDeliveries';
private uploadImageUrl = environment.baseUrl + 'menu/admin/image/update';

private convertToOrderURL = environment.baseUrl + 'prebook/convertToOrder';
private emailMessageURL = environment.baseUrl + 'prebook/emnailCustomers';
private updatePandLRecordURL = environment.baseUrl + 'reports/updatePandLRecord';
private updateSalePriceURL = environment.baseUrl + 'reports/updateSalePrice';
private GET_EXPENSE_ITEM_LIST = environment.baseUrl + 'accounting/expense/get';
private GET_EXPENSE_TYPE_LIST = environment.baseUrl + 'accounting/expensetype/get';
private GET_EXPENSE_FREQ_LIST = environment.baseUrl + 'accounting/expensefrequency/get';
private CREATE_EXPENSE_ITEM = environment.baseUrl + 'accounting/expense/create';

private user:User;
displayedColumns: string[] =['Order ID', 'Customer Name', 
  'Order Items', 'Status', 'Total Amt', 'Time', 'Payment Mode', 'Order Type', 'Phone'];
 private url:string;

   httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*',
      'Allow-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS',
      'Access-Control-Allow-Headers':'Origin, X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization'

    })
  };

  constructor( private http: HttpClient, private authService: AuthenticationService) { 
   
    this.authService.currentUser.subscribe(x => this.user = x);
    if (this.user.id == 0) {
      this.user.id = 25;
    }
    this.orderUrl = this.orderUrl.replace('{storeid}', this.user.id+'');
    this.markCompleteUrl = this.markCompleteUrl.replace('{storeid}', this.user.id+'');
    this.markStartedUrl = this.markStartedUrl.replace('{storeid}', this.user.id+'');
    this.markOnHoldUrl = this.markOnHoldUrl.replace('{storeid}', this.user.id+'');
    this.markRejectedUrl = this.markRejectedUrl.replace('{storeid}', this.user.id+'');
    
    this.markCancelleddUrl = this.markCancelleddUrl.replace('{storeid}', this.user.id+'');
    
    this.deleteUrl = this.deleteUrl.replace('{storeid}', this.user.id+'');
  //  this.getOrderByOrderId = this.getOrderByOrderId.replace('{storeid}', this.user.id+'');

   // alert(this.user.id);
  }

 /*
  getOrders(): Observable<Order[]> {
    return of (ORDERS);
   // return this.http.get<Order[]>(this.orderUrl)
  }
*/
  /** GET heroes from the server */
  getOrders (status:string): Observable<RequestStatus> {
    return this.http.get<RequestStatus>(this.orderUrl + status);
  }
  
  markOrderComplete(orderId:number): Observable<SimpleResponse> {
    //url: string ;
    this.url = this.markCompleteUrl.replace('{orderid}', orderId+'');
    return this.http.put<SimpleResponse>(this.url,true, this.httpOptions);
  }

  markProcessed(orderId:number): Observable<boolean> {
    //url: string ;
    this.url = this.markStartedUrl.replace('{orderid}', orderId+'');
    return this.http.put<boolean>(this.url,true, this.httpOptions);
  }

  markRejected(orderId:number, message:string): Observable<SimpleResponse> {
    this.url = this.markRejectedUrl.replace('{orderid}', orderId+'');
    return this.http.put<SimpleResponse>(this.url, message, this.httpOptions);
  }

  markCancelled(orderId:number, message:string): Observable<SimpleResponse> {
    this.url = this.markCancelleddUrl.replace('{orderid}', orderId+'');
    return this.http.put<SimpleResponse>(this.url, message, this.httpOptions);
  }

  updateDeliveryInstructions(orderId:number, message:string): Observable<SimpleResponse> {
    this.url = this.updateDeliveryInstructionsURL.replace('{orderid}', orderId+'');
    alert(this.url);
    return this.http.post<SimpleResponse>(this.url, message, this.httpOptions);
  }
 

  confirmOrder(orderId:number, duration:number): Observable<SimpleResponse> {
    //url: string ;
    this.url = this.markStartedUrl.replace('{orderid}', orderId+'');
    this.url = this.url +"/"+duration;
    return this.http.put<SimpleResponse>(this.url,true, this.httpOptions);
  }
  

  markOnHold(orderId:number): Observable<boolean> {
    //url: string ;
    this.url = this.markOnHoldUrl.replace('{orderid}', orderId+'');
    return this.http.put<boolean>(this.url,true, this.httpOptions);
  }

  callDelete(orderId:number): Observable<SimpleResponse> {
    //url: string ;
    this.url = this.deleteUrl.replace('{orderid}', orderId+'');
    return this.http.put<SimpleResponse>(this.url,true, this.httpOptions);
    };
  

    getOrderDetails (storeId:number, orderId:number): Observable<RequestStatus> {
      this.url = this.getOrderByOrderId.replace('{storeId}', storeId +'');
      this.url = this.url.replace('{orderId}', orderId+'');

      return this.http.get<RequestStatus>(this.url);

    }

    getExpenseItems(): Observable<Array<ExpenseItem>> {
      return this.http.get<Array<ExpenseItem>>(this.GET_EXPENSE_ITEM_LIST);
      
    }
    getExpenseTypes(): Observable<Array<ExpenseType>> {
      return this.http.get<Array<ExpenseType>>(this.GET_EXPENSE_TYPE_LIST);
      
    }
    getExpenseFrequency(): Observable<Array<ExpenseFrequency>> {
      return this.http.get<Array<ExpenseFrequency>>(this.GET_EXPENSE_FREQ_LIST);
      
    }

    createExpenseItem(item:ExpenseItem): Observable<SimpleResponse> {
       return this.http.post<SimpleResponse>(this.CREATE_EXPENSE_ITEM, item, this.httpOptions);
    }

    updateMenu(menu:MenuItem): Observable<SimpleResponse> {
   //   this.url = this.updateMenu;
      return this.http.put<SimpleResponse>(this.updateMenuURL, menu, this.httpOptions);
    }

    createMenu(menu:MenuItem): Observable<SimpleResponse> {
      //   this.url = this.updateMenu;
         return this.http.post<SimpleResponse>(this.createMenuURL, menu, this.httpOptions);
       }

   createAddition(addtn:MenuItemAdditions): Observable<SimpleResponse> {
        return this.http.post<SimpleResponse>(this.createAdditionURL, addtn, this.httpOptions);
    }

    generateDeliveryRoute(data:GenerateDeliveryRoute): Observable<SimpleResponse> {
      return this.http.post<SimpleResponse>(this.generateDeliveryRouteURL, data, this.httpOptions);
    }

    convertToOrder(data:ConversionRequest): Observable<SimpleResponse> {
      return this.http.post<SimpleResponse>(this.convertToOrderURL, data, this.httpOptions);
    }
    sendCustomerEmail(data:EmailMessage): Observable<SimpleResponse> {
      return this.http.post<SimpleResponse>(this.emailMessageURL, data, this.httpOptions);
    }

    setPackingAssociate(data:GenerateDeliveryRoute): Observable<SimpleResponse> {
      return this.http.post<SimpleResponse>(this.setPackingAssociateURL, data, this.httpOptions);
    }

    
  
    markTodaysRoutes(data:GenerateDeliveryRoute): Observable<Wrapper> {
      return this.http.post<Wrapper>(this.markTodaysRoutesURL, data, this.httpOptions);
    }

    print(data:string){
      var url:string = "https://127.0.0.1:41951/DYMO/DLS/Printing/GetPrinters/renderLabel";
          
      return this.http.post(this.url, data);
    }

    uploadImage(formData:FormData):Observable<RequestStatus> {
      let url = this.uploadImageUrl;
      let myHeaders = new HttpHeaders();
      myHeaders.append('Content-Type', 'multipart/form-data');
      let options = {
        headers:myHeaders
      };
  
      return this.http.post<RequestStatus>(url, formData, {headers:myHeaders});
   }

   updatePandLRecord(record:ReportDataUpdate): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.updatePandLRecordURL, record, this.httpOptions);
  }

  updateSalePrice(record:ReportDataUpdate): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(this.updateSalePriceURL, record, this.httpOptions);
  }

}
