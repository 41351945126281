
<p>payment works!</p>

<div *ngIf="invalidError" style="color:red">
    {{ invalidError.message }}
  </div>
   
  <stripe-card 
    #stripeCard
    (catch) = "onStripeError($event)"
    [(invalid)] = "invalidError"
    (cardMounted) = "cardReady = true"
    (tokenChange) = "setStripeToken($event)"
    (sourceChange) = "setStripeSource($event)"
  ></stripe-card>
  <br>
   
  <button type="button" (click)="stripeCard.createToken(extraData)">createToken</button>
  