import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { User } from './_models/user';
import { RequestStatus } from './constants';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post(`${environment.baseUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                var myUser:User = user as User;
                //user = user as User;
               // myUser.authdata = window.btoa(username + ':' + password);
               myUser.authdata = window.btoa('test' + ':' + 'test');
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(myUser);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    updateStoreStatus(storeId:number, status:number)  {
        var url:string = `${environment.baseUrl}updateStoreStatus/`;
        url = url + storeId + "/" + status ;
      //  alert(url);
       // return this.http.post(`${environment.baseUrl}/users/authenticate`, { username, password })
       return this.http.get<RequestStatus>(url)
    }
}