<!--
<button class="button button-primary" (click)="subscribeToNotifications()">
  Subscribe
</button>

<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="/">Home</a>
      <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav>
X - {{currentUser?.id}}Y {{currentUser}}
-->

<mat-toolbar  color="primary"  style="height:90px" class="fadeshow1" *ngIf="currentUser?.id < 100 && currentUser" >
    <mat-toolbar-row style="height: 200px;" >
      <button mat-icon-button>
        <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
      </button>
      <button mat-mini-fab color="warn"  *ngIf="currentUser" (click)="registerForWebNfns()"  matTooltip="Sign up for Notifications">
        <mat-icon matPrefix style="vertical-align: top;" >alarm_on</mat-icon>
      </button>
      <span class="menu-spacer">&nbsp;&nbsp;</span>
      <h1 style="color: yellow;padding-right: 20px;"  *ngIf="currentUser">{{currentUser.storename}}</h1>
      <span class="menu-spacer"></span>
      
      <div>
        <a mat-button [routerLink]="'/orders'" routerLinkActive="test"> <mat-icon matPrefix style="vertical-align: top;" >fastfood</mat-icon>Orders </a>
        <a mat-button [routerLink]="'/dailySpecials'" routerLinkActive="test"><mat-icon matPrefix style="vertical-align: top;" >favorite</mat-icon>Daily Specials</a>
        <a mat-button [routerLink]="'/menuAdmin'" routerLinkActive="test">Menu Changes</a>
        <a mat-button [routerLink]="'/customers'" routerLinkActive="test">Customers</a>
        <a mat-button [routerLink]="'/accounting'" routerLinkActive="test">Accounting</a>
        <a mat-button [routerLink]="'/about'" routerLinkActive="test">About iPerkz</a>
         <a *ngIf="currentUser?.id==0" mat-button  [routerLink]="'/superadmin'" routerLinkActive="test">Admin</a>
        <a  *ngIf="currentUser" mat-button (click)="logout()">
          <mat-icon  style="vertical-align:top" >lock icon</mat-icon>Logout</a>
       </div>
       <span class="example-spacer"></span>
       <span>{{build}}</span> 
       <span  class="example-icon">
          <img valign="bottom" src="./assets/icons/transparent_logo.png" width="80px" height="80px" />
        </span>
    </mat-toolbar-row>


  </mat-toolbar>
  
  <mat-sidenav-container>
    <mat-sidenav #sidenav>
      <mat-nav-list>
  
        <a mat-list-item [routerLink]="'/orders'"> Orders </a>
        <a mat-list-item [routerLink]="'/dailySpecials'">Daily Specials</a>
        <a mat-list-item [routerLink]="'/menuAdmin'">Menu Changes</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div style="height: 88vh;">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  