// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
//open  The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:8080/api/',
   baseUrl: 'https://www.iperkz.com/api/',
 
  version: 'LOCAL',
  firebase: {
    apiKey: "AIzaSyBMrNCfn65rXmMB6R949F4sT6R6QM4y_qM",
    authDomain: "iperkz-77e5f.firebaseapp.com",
    databaseURL: "https://iperkz-77e5f.firebaseio.com",
    projectId: "iperkz-77e5f",
    storageBucket: "iperkz-77e5f.appspot.com",
    messagingSenderId: "934949116876",
    appId: "1:934949116876:web:9a8f9d3b193bd260341b69",
    measurementId: "G-035DB3M10N"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
