export class Category {
    categoryName: string;
    categoryId: number;
    
    /*
    constructor(categoryName: string, categoryId: number) {
      this.categoryName = categoryName;
      this.categoryId = categoryId;
    }
    */
}
  