import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  url: string = "https://qrco.de/bbcj9T";
  urlSafe: SafeResourceUrl;
  slideIndex: number = 0;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  }

  openFacebook() {
    window.open("https://www.facebook.com/iPerkz-102070848198792/?modal=admin_todo_tour");
  }

  openPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=com.appisoft.perkz");
  }

  openAppStore() {
    window.open("https://apps.apple.com/us/app/iperkz/id1512501611?uo=4");
  }


}
