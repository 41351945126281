  <div >
    <h1 class="alignleft">Today's Specials</h1>
    <span class="alignright">
      <div *ngIf="specials && specials.length > 0">
        <button mat-raised-button color="primary" (click)="toggleDisplayOrder()" [disabled]="isSubmitting">
          {{ isSorting ? 'Cancel Display Order' : 'Sort Display Order' }}
        </button>
      </div>
      <div *ngIf="enableNotificationBtn == false"> 
        <button mat-raised-button diabled (click)="sendNotifications()"  matTooltip="Select Daily Specials first" >
        Send Notifications</button>
      </div>
      <div *ngIf="enableNotificationBtn == true"> 
        <button mat-raised-button color="primary" (click)="sendNotifications()"  
        matTooltip="Send Notification to Customers" >
        Send Notifications</button>
      </div>
    </span>
  

<div>
  <table  cdkDropList 
  #destList="cdkDropList" 
  [cdkDropListData]="dataSource"
  class="movie-list" 
  (cdkDropListDropped)="onDrop($event)" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="setSendButtonStatusMasterCheckbox()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                     (change)="setSendButtonStatus($event, row)"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container> 
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>Menu</th>
      <td mat-cell *matCellDef="let element">{{element.menuItemName}}{{element.suffix ? ' / ' + element.suffix : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{element.menuItemDesc}}</td>
    </ng-container>
    <ng-container matColumnDef="salePrice">
      <th mat-header-cell *matHeaderCellDef>Original / Sale Price</th>
      <td mat-cell *matCellDef="let element">${{element.origPrice | number:'.2'}} / ${{element.salePrice | number:'.2'}}</td>
    </ng-container>
    <ng-container matColumnDef="menuCategory">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{element.menuCategory}}</td>
    </ng-container>
    <ng-container matColumnDef="mealType">
      <th mat-header-cell *matHeaderCellDef>Meal Type</th>
      <td mat-cell *matCellDef="let element">{{element.mealType}}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{element.availableStartTime | date:'EE, MMM dd' }}</td>
    </ng-container>
    <ng-container matColumnDef="picture">
      <th mat-header-cell *matHeaderCellDef>Picture</th>
      <td mat-cell *matCellDef="let element">
        <input type="file" style="display: none" #file (change)="uploadImage($event, element)" />
        <div  *ngIf="element.isLoading">
          <button mat-button>
            <img src="assets/loading.gif"  width="35px" height="35px"/>
          </button>
        </div>
        <div *ngIf="!element.isLoading"  matTooltip="Click to Change Image">
            <button mat-button (click)="file.click()" >
              <img src="{{element.imageUrl}}"  width="35px" height="35px" />
            </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Delete</th>
      <td mat-cell *matCellDef="let element"  matTooltip="Delete">
      <button mat-mini-fab color="warn" (click)="delete(element)">
      <mat-icon>delete_forever</mat-icon>
      </button>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
   
    <tr  mat-row *matRowDef="let row;columns: displayedColumns" [cdkDragDisabled]="!isSorting" cdkDrag></tr>
  
  </table>
  <div class="information" *ngIf="!isLoading && specials?.length == 0">
   <mat-icon >info</mat-icon>
    No Daily Specials
  </div>
  <div class="loading-icon-loc" *ngIf="isLoading">
    <img src="assets/loading.gif"  width="70px" height="70px"/>
  </div>
  <div *ngIf="isSorting" class="submit-button">
  <button mat-raised-button color="primary" (click)="submitDisplayOrder()" [disabled]="isSubmitting"> Submit Order</button>
  </div>
</div> 
<br>
<div *ngIf="error">{{ error }}</div>
<div id="formdiv">
<form  *ngIf="showForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label >Menu Title</mat-label>
    <input matInput placeholder="Placeholder" formControlName="menuItemName" required ng-valid> 
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label >Suffix</mat-label>
    <input matInput placeholder="Placeholder" formControlName="suffix" required ng-valid> 
  </mat-form-field>
  <br>
  
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="menuItemDesc"></textarea>
    </mat-form-field>&nbsp;
    <mat-form-field appearance="fill">
      <mat-label>Menu Code</mat-label>
      <mat-select  formControlName="menuCode" required >
        <mat-option  *ngFor="let item of menuCodeList" value="{{item}}">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>Select Category</mat-label>
    <mat-select  formControlName="menuCategory" required >
      <mat-option  *ngFor="let meal of categoryList" value="{{meal}}">{{meal}}</mat-option>
    </mat-select>
  </mat-form-field>
   &nbsp;
   <mat-form-field appearance="fill">
    <mat-label>Select Sub Category</mat-label>
    <mat-select  formControlName="menuSubCategory" required >
      <mat-option  *ngFor="let category of subCategoryList[currentCategory]" value="{{category}}">{{category}}</mat-option>
    </mat-select>
  </mat-form-field>
  
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Original Price</mat-label>
    <input matInput type="number" class="example-right-align"  formControlName="origPrice" 
    (keyup)="copyValueToSalePrice($event)" (change)="copyValueToSalePrice($event)" required>
    <span matPrefix>$&nbsp;</span>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label>Sale Price</mat-label>
    <input matInput type="number" class="example-right-align"  formControlName="salePrice" required>
    <span matPrefix>$&nbsp;</span>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label>Quantity</mat-label>
    <input matInput class="example-right-align" (ngModelChange)="process($event)" 
    value="Not Applicable" formControlName="quantity" required>
    <span matPrefix>#&nbsp;</span>
  </mat-form-field>

  <br>
<button mat-raised-button color="primary" type="submit">Create</button>
</form> 

</div>


