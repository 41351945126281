import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { SimpleResponse } from './SimpleResponse';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionServiceService {

  private subscriptionUrl = environment.baseUrl + 'webpush/';

  constructor( private http: HttpClient) { }

  storeWebSubscription(storeWebSubscription:PushSubscription, storeId:number): Observable<SimpleResponse> {
    alert(storeWebSubscription);
     return this.http.post<SimpleResponse>(this.subscriptionUrl + storeId, storeWebSubscription.toJSON());
  }
}
