<br>
<div>

</div>

<div *ngIf="isLoading==true" style="text-align: center;margin-top:10px;">
  <img src="/assets/loading.gif" width="50px" height="50px" />
</div>
<mat-tab-group style="width: 100%;" (selectedTabChange)="tabChanged($event)" >
  <mat-tab label = "{{links[0]}}">
    <br>
 
    <div style="width: 50%; float: left; margin-left: 20px;" >
      <div style="width: 100%; float: left; margin-left: 20px;" >
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        &nbsp;
        <mat-form-field appearance="fill">
            <mat-label>Choose Create Start date</mat-label>
            <input matInput [matDatepicker]="stardate" formControlName="startDate" required>
            <mat-datepicker-toggle matSuffix [for]="stardate"></mat-datepicker-toggle>
            <mat-datepicker #stardate></mat-datepicker>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="fill">
            <mat-label>Choose Create end date</mat-label>
            <input matInput [matDatepicker]="enddate" formControlName="endDate" required>
            <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
        </mat-form-field>
        &nbsp;
        <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit" >Search</button> &nbsp;
        
        </form>
        <button mat-raised-button color="primary" style="height:33px;float: right;margin-right: 140px;" type="submit" (click)= "getTodays()" >Search for Todays Orders</button> &nbsp;
        
      </div>
      &nbsp;
      <div
        cdkDropList 
        [cdkDropListData]="orders"
        [cdkDropListConnectedTo]="[todayList]"
              class="movie-list" 
              (cdkDropListDropped)="onDrop($event)">
              <div [ngClass]="{'movie-block-href_gray': order.tableNumber.length > 3, 'movie-block-href_1': order.tableNumber.length < 3, 'movie-block-href_red': getDayDiff(order) > 1}" 
                *ngFor="let order of orders" cdkDrag>
                 <mat-checkbox (click)="$event.stopPropagation()" style="padding-right: 5px;"
                (change)="moveTodaysOrders($event, order)"
                >
                </mat-checkbox>
               
                {{order.address}} ({{order.orderStatus}})
                <a (click)="showUpdateScreen(order)">
                {{order.customerOrderId}}</a>
              </div>
      </div>
    </div>
    <div style="width: 40%; float: left; margin-left: 3px;" >
   
      &nbsp;
     
      
      <div style="margin-top: 0px;"
        cdkDropList 
        #todayList="cdkDropList" 
        [cdkDropListData]="todaysOrders"
        #destList="cdkDropList" 
              class="movie-list" 
              (cdkDropListDropped)="destinationOnDrop($event)">
              <div>
                <span style="font-size: 18px;margin-right: 10px; margin-left:10px; font-weight: bold;">Scheduled Orders</span>
                <button mat-mini-fab color="primary" style="outline: none;" (click)="getTodaysOrders()"  type="submit">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button mat-raised-button color="warn" style="height:33px;vertical-align: top;float: right;margin-top: 3px;margin-right: 3px;"
                (click)="markTodaysRoutes()"  type="submit">Apply Changes <span *ngIf="todaysOrders?.length >0"> - {{todaysOrders?.length}}</span></button> &nbsp;
                
              </div>
              <div class="movie-block-href" *ngFor="let destOrder of todaysOrders" cdkDrag>
                {{destOrder.address}}
                <a (click)="showInstructionsScreen(destOrder)">
                {{destOrder.customerOrderId}}</a>
             </div>
        </div>
   </div>
    
  </mat-tab>
  <mat-tab label = "{{links[1]}}">
    <div style="width: 40%; float: left; margin-left: 20px;" >
      <div
        cdkDropList 
        [cdkDropListData]="sourceTodaysOrders"
        [cdkDropListConnectedTo]="[routeList]"
              class="movie-list" 
              (cdkDropListDropped)="onDrop($event)">
              <div [ngClass]="{'movie-block-href_gray': order.deliveryAssociate.length > 3, 'movie-block-href_1': order.deliveryAssociate.length < 3}" *ngFor="let order of sourceTodaysOrders" cdkDrag>
                <mat-checkbox (click)="$event.stopPropagation()"
                (change)="onSelectionChange($event, order)"
                >
                {{order.address}}
                </mat-checkbox> 
                <a >
                {{order.customerOrderId}}</a>
              </div>
    
      </div>
    </div>
    <div style="width: 40%; float: left; margin-left: 3px;" >
    <p><img *ngIf="isCopiedtoClipboard==true" src="./../../assets/Check-mark-1.jpeg" width="30px" height="30px" onclick="copyValueToClipboard()"/>&nbsp;{{URLtoShare}}&nbsp;<img *ngIf="URLtoShare?.length>0" src="./../../assets/copy-image.png" width="20px" height="20px" (click)="copyValueToClipboard()"/></p>
    
      <mat-form-field appearance="fill">
        <mat-label >Delivery Associate</mat-label>
        <input matInput placeholder="Placeholder" [(ngModel)]="associate" required ng-valid> 
      </mat-form-field>
      &nbsp;
      <button mat-raised-button color="primary" style="height:50px;vertical-align: top;"
      (click)="onGenerateRoute()"  type="submit">Generate Route <span *ngIf="routeOrders?.length >0"> - {{routeOrders?.length}}</span></button> &nbsp;
      
      <div
        cdkDropList 
        #routeList="cdkDropList" 
        [cdkDropListData]="routeOrders"
        #destList="cdkDropList" 
              class="movie-list" 
              (cdkDropListDropped)="destinationOnDrop($event)">
              <div class="movie-block-href" *ngFor="let destOrder of routeOrders" cdkDrag>
                {{destOrder.address}}
                <a >
                {{destOrder.customerOrderId}}</a>
             </div>
        </div>
    </div>
  </mat-tab>
  <mat-tab label = "{{links[2]}}">
    <div>
      <p *ngFor="let trackingItem of trackingUrls">
      {{trackingItem.orderId}} - {{trackingItem.trackingURL}}

      
      </p>
    </div>
  </mat-tab>
 
  <mat-tab label = "{{links[3]}}">
    <form [formGroup]="customSearchForm"  (ngSubmit)="onCustomSearch()">
      <mat-form-field appearance="fill">
        <mat-label>Select Search By</mat-label>
        <mat-select  formControlName="searchById" required >
          <mat-option  *ngFor="let searchBy of searchByItems"  value="{{searchBy}}" >{{searchBy}}</mat-option>
        </mat-select>
     </mat-form-field>
     &nbsp;
     <mat-form-field appearance="fill">
      <mat-label>Select Search Text</mat-label>
      <input matInput  formControlName="searchByValue" required>
   </mat-form-field>
   &nbsp;
      <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit">Search</button> &nbsp;
    </form>   
  &nbsp;

  <div class="bg">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Order ID">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.customerOrderId}}</td>
      </ng-container>
      <ng-container matColumnDef="Customer Name">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.firstName}}, {{element.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="Phone">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.phone}}</td>
      </ng-container>
      <ng-container matColumnDef="Address">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.address}}</td>
      </ng-container>
      <ng-container matColumnDef="CreationDate">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Order Date</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.orderCreationTime | date:'MMM d, y, h:mm:ss a'}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
    </table>
    </div>
  </mat-tab>
  <mat-tab label = "{{links[4]}}">
    <form [formGroup]="perkzSearchForm"  (ngSubmit)="onPerkzSearch()">
      <mat-form-field appearance="fill">
        <mat-label>Select Search By</mat-label>
        <mat-select  formControlName="searchById" required >
          <mat-option  *ngFor="let searchBy of searchCustomerByItems"  value="{{searchBy}}" >{{searchBy}}</mat-option>
        </mat-select>
     </mat-form-field>
     &nbsp;
     <mat-form-field appearance="fill">
      <mat-label>Select Search Text</mat-label>
      <input matInput  formControlName="searchByValue" required>
   </mat-form-field>
   &nbsp;
      <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit">Search</button> &nbsp;
    </form>   
  &nbsp;
  <div *ngIf="customer?.firstName?.length > 1">
  <h1>&nbsp;{{customer.firstName}}&nbsp;{{customer.lastName}} &nbsp;&nbsp; {{customer.phoneNumber}}</h1>
  </div>
  <br>
  <div *ngIf="availableRewards?.length > 0">
    <h2>Available Rewards: {{availableRewards | currency:'USD':'symbol':'1.2-2'}}
      <button mat-mini-fab color="primary" style="height:50px;vertical-align: top;"  (click)="showAddScreen=true">+</button> &nbsp;
  
   </h2>
  </div>
  
  <div *ngIf="showAddScreen == true">
    <h1>Add Screen</h1>
    <form [formGroup]="addPerkzForm"  (ngSubmit)="onAddPerkz()">
      <mat-form-field appearance="fill">
        <mat-label>Order Id</mat-label>
        <input matInput type="number" class="example-right-align"  formControlName="orderId" required>
        <span matPrefix>#&nbsp;</span>
      </mat-form-field>&nbsp; 
      <mat-form-field appearance="fill">
        <mat-label>Amount</mat-label>
        <input matInput type="number" class="example-right-align"  formControlName="amount" required>
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
     &nbsp;
      <mat-form-field appearance="fill" style="width:400px">
        <mat-label>Comment</mat-label>
        <input matInput  formControlName="perkzComment" required>
      </mat-form-field>
      &nbsp;
     <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit">Add</button> &nbsp;
    </form>
  </div>
  <div class="bg">
    <table mat-table [dataSource]="perkzDataSource" class="mat-elevation-z8">
      
      <ng-container matColumnDef="Id">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{element.id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Order Id">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Order Id</th>
        <td mat-cell *matCellDef="let element">{{element.orderId}}</td>
      </ng-container>
      <ng-container matColumnDef="Used Perkz $">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Used Perkz $</th>
        <td mat-cell *matCellDef="let element">{{element.rewardUsed}}</td>
      </ng-container>
      <ng-container matColumnDef="Assigned">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Assigned</th>
        <td mat-cell *matCellDef="let element">{{element.totalReward}}</td>
      </ng-container>
     <br>
      <ng-container matColumnDef="Comments">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Comments</th>
        <td mat-cell *matCellDef="let element">{{element.comments}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedCustomerColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedCustomerColumns"></tr>
    </table>
    </div>
  </mat-tab>
  <mat-tab label = "{{links[5]}}  - {{prebookOrders?.length}}">
    <div style=" float: left; margin-left: 30px;margin-top: 10px;" >
    <button  mat-raised-button color="primary" style="height:30px;vertical-align: top;"
    (click)="onConvertToOrder()"  type="submit" [disabled] ="disableConversionButton">Convert to Order</button> 
      &nbsp;
    <button  mat-raised-button color="primary" style="height:30px;vertical-align: top;"
    (click)="onEmail()"  type="submit">Email</button> 
      </div>
    <div style="width: 100%; float: left; margin-left: 10px;" >
      <div 
          cdkDropList 
        [cdkDropListData]="prebookOrders"
           class="movie-list" 
              (cdkDropListDropped)="onDrop($event)">
              <div [ngClass]="{'movie-block-href_gray': prebook?.converted == 1, 'movie-block-href_1': prebook?.converted == 0}" 
    
               *ngFor="let prebook of prebookOrders" >
               <mat-checkbox  (click)="$event.stopPropagation()"
               (change)="onPrebookSelectionChange($event, prebook)"
               >
               {{prebook.bookingId}}
               </mat-checkbox> 
               <span>{{prebook.firstname}}, {{prebook.lastname}}</span><p style="text-align: left">{{prebook.address}} </p><span> {{prebook.deliverySchedule }}</span> 
               <span style="color:black;" *ngFor="let ttt of prebook.prebookItems">{{getMenuItems(ttt)}}</span>
               <span style="text-align: right">{{prebook.orderCreationTime | date:'MMM d, y, h:mm:ss a'}}</span>
         
              </div>
    
      </div>
    </div>
  </mat-tab>
</mat-tab-group>




