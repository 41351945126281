import { Component, OnInit, Inject} from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrderUpdateData } from '../DialogData';
import { Order } from '../order';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-info-change',
  templateUrl: './order-info-change.component.html',
  styleUrls: ['./order-info-change.component.css']
})
export class OrderInfoChangeComponent implements OnInit {
  customerOrder:Order;
  reason:string = "Reason for Cancellation";

  constructor(public dialogRef: MatDialogRef<OrderInfoChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderUpdateData, private orderService:OrderService) { 

      this.customerOrder = data.orderItem;
    }

  ngOnInit(): void {
  }
  closeWindow() {
    this.dialogRef.close(false); 
  }
  cancelOrder(){
    this.orderService.markCancelled(this.customerOrder.customerOrderId, this.reason).subscribe(result => {
      this.dialogRef.close(true);
      }
      );
  }

  activateOrder(){
    this.orderService.markOnHold(this.customerOrder.customerOrderId).subscribe(result => {
      this.dialogRef.close(true);
      }
      );
  }
}
