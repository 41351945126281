<head>
    <link href='https://fonts.googleapis.com/css?family=Khand:400,300,500,600,700' rel='stylesheet' type='text/css'>
</head>
<body style="background-color: white;">
  <div *ngIf="isLoading==true" style="text-align: center;margin-top:200px;">
    <img src="/assets/loading.gif" width="100px" height="100px" />
  </div>
    <div class="container" *ngIf="isLoading==false">
          
          <div class="banner" > 
            <h1 class="menu-title">{{wrapper?.storeName}}</h1>
            <!--
            <img src="{{wrapper?.logoUrl}}" style="padding-right: 5px;vertical-align: top;vertical-align: top;"
             width="70px" height="70px"/> 
             -->
        
          </div>
         <div style="background-color: white;position: sticky;top: 0;width: 100%;z-index: 400;" > 
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label style="color: blue;">Search</mat-label>
            <input matInput placeholder="Search" id="filter" (keyup)="applyFilter($event)">
            <mat-icon matPrefix color="primary">search</mat-icon>
          </mat-form-field>
        </div>
      <div class="row">
        <div class="col-12">
          
          <div *ngFor="let category of categories,  let i = index" 
            style="margin-top: 20px;padding-left: 5px;padding-right: 5px;">
          <div class="text-center"  >
            <a id="{{i+1}}"></a><h3>{{category.categoryName}}</h3>
          </div>
          <div class="row" *ngFor="let menuItem of category.menuItems,  let j = index">
            <div class="col-sm-6 col-md-4">
              <div class="item">
                <img *ngIf="menuItem.imageUrl != 'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Village_Coffee_Maplewood/generic_food.jpg'" src="{{menuItem.imageUrl}}" style="padding-right: 5px;vertical-align: top;vertical-align: top;"
                width="70px" height="70px"/> 
                <span></span>
                <span>{{menuItem.salePrice | currency:'USD':'symbol':'1.2-2'}}</span>
                <h4><a (click)="showPaymentScreen()">{{menuItem.menuItemName}}</a><br/><small><i>{{menuItem.menuItemDescription}}</i></small></h4>
              </div>
            </div>
          </div>
         </div>
        </div>
        <div style="position: sticky; bottom: 0;background-color:teal;width: 100%;margin-bottom: 15px;"> 
          <h5 style="text-align: center;color:white;font-size: larger;padding-top: 2px;">Use
             <span style="color:yellow;"><b>iPerkz App</b></span> to order.  <span style="color:yellow;">Get $5 off.</span></h5>

            <div style="text-align: center;padding-bottom: 10px;">
              <a>
                <img src="/assets/69569.png" style="padding-right: 5px;" width="40px" height="30px" (click)="openAppStore()"/>
                </a>
              <a>
            <img src="/assets/apple-en.png" width="90px" height="30px" (click)="openAppStore()"/>
            </a>

            <a>
              <img src="/assets/google-en.png" width="90px" height="30px" (click)="openPlayStore()"/>    
            </a>
           </div>
      </div>
    
      </div>
    </div>
    <script src="js/jquery.min.js"></script>
    <script src="js/bootstrap.bundle.min.js"></script>
    <script>
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    </script>
  </body>