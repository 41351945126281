import { Component, OnInit } from '@angular/core';
import {Order, CustomerOrderMenu} from '../order';
import {OrderService} from '../order.service';
import { DialogComponent } from '../dialog/dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { filter } from 'rxjs/operators';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import { CONSTANTS } from '../constants';
import { interval,Subject } from 'rxjs';
import { Router } from '@angular/router';
import {RouterEvent} from '@angular/router';
import {NavigationEnd } from '@angular/router';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
 
  currentView:string = "CURRENT";
  public destroyed = new Subject<any>();

  isLoading:boolean = false;
  hero: Order;
  heros : Order[];
  localHeros : Order[];
  displayedColumns: string[] =['Order ID', 'Customer Name', 
      'Order Items', 'Finish Date', 'Status', 'Total Amt', 'Time', 'Payment Mode', 'Order Type','Phone', 'accept',
       'mark_confirm', 'Delete'];

  dataSource:any;
  selectedHero: Order;
  
  constructor(private orderService:OrderService, public dialog: MatDialog,
    private _snackBar: MatSnackBar, private router: Router) { 
    this.dataSource = new MatTableDataSource(this.heros);
  }

  animal: string;
  name: string;
  result: false;
  response:boolean;
  //audio = new Audio();

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.getOrders(this.currentView);
    });
    this.getOrders(this.currentView);
  }
  refreshMe():void {
    this.dataSource = new MatTableDataSource(null);
    this.getOrders(this.currentView);
  }

  refresh(status:string):void {
    this.currentView = status;
    this.dataSource = new MatTableDataSource(null);
    this.getOrders(status);
  }

  refreshNow():void {
    this.dataSource = new MatTableDataSource(null);
    this.getOrders(this.currentView);
  }

  playAudio(){
   //this.audio.load();
   //this.audio.play();
   var  bodyMessage:Array<string> = new Array();

   // bodyMessage.push(this.getMenuItemsString(order.menuList) + " for ");
    bodyMessage.push("");
 
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"fastfood",
        title: "New Order Request", message: bodyMessage, 
        yesButtonMessage:"Accept",
        noButtonMessage:"Decline", 
        result:this.result
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.audio.pause();
    });
  }
  
  getOrders(status:string): void {
    this.isLoading = true;
  
    this.orderService.getOrders(status)
      .subscribe(result => {
            this.isLoading = false;
            /*
            if (this.heros && this.heros.length > 0) {
              this.checkIfAnyOrders(this.heros, result.payload["CUSTOMER_ORDERS_BY_STATUS"]);
            } 
            */
            this.heros = result.payload["CUSTOMER_ORDERS_BY_STATUS"];
            this.dataSource = new MatTableDataSource(result.payload["CUSTOMER_ORDERS_BY_STATUS"]);
            }
          );
  }

  checkIfAnyOrders(srcArr: Order[], destArr: Order[]){

    destArr.forEach(element => {
      var found:Boolean = false;
      srcArr.forEach(item => {
       if (element.menuId == item.menuId) {
          found = true;
        }
      });
      if (found == false) {
       this.playAudio();
        return;
      }
    });
  }

  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

  callMarkCompleteService(orderId:number):void {
    this.orderService.markOrderComplete(orderId)
    .subscribe(result => {
      
       if (result.messageCode == 2) {
          alert (result.message);
       }
      //  this.response = result;
        this.getOrders('ALL');
        }
      );
  }

  callStartedService(orderId:number):void {
    this.orderService.markProcessed(orderId)
    .subscribe(result => {
        this.response = result;
        this.getOrders('ALL');
        }
      );
  }

  callOnHoldService(orderId:number):void {
    this.orderService.markOnHold(orderId)
    .subscribe(result => {
        this.response = result;
        this.getOrders('ALL');
        }
      );
  }

  onSelect(hero: Order): void {
    this.selectedHero = hero;
  }

  markReadyForPickup(order:Order):void {
    //alert("clicked" + order.customerOrderId);
    var  bodyMessage:Array<string> = new Array();

   bodyMessage.push(this.getMenuItemsString(order.menuList) + " for ");
   bodyMessage.push(order.firstName);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"assets/ready.jpg",
        title: "Ready for Pickup?", message: bodyMessage, 
        yesButtonMessage:"Ready",
        noButtonMessage:"No", 
        result:this.result
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.callMarkCompleteService(order.customerOrderId);
        // alert('Mark complete ' + order.customerOrderId);
      }
    });
  }

 

  markConfirmed(order:Order):void {
    //alert("clicked" + order.customerOrderId);
    var  bodyMessage:Array<string> = new Array();

   bodyMessage.push(this.getMenuItemsString(order.menuList) + " for ");
   bodyMessage.push(order.firstName);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"thumb_up",
        title: "Confirm?", message: bodyMessage, 
        yesButtonMessage:"Confirm",
        noButtonMessage:"No", 
        result:this.result
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.callStartedService(order.customerOrderId);
        // alert('Mark complete ' + order.customerOrderId);
      }
    });
  }

  markOnHold(order:Order):void {
    //alert("clicked" + order.customerOrderId);
    var  bodyMessage:Array<string> = new Array();

    bodyMessage.push(this.getMenuItemsString(order.menuList) + " for ");
    bodyMessage.push(order.firstName);
 
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"thumb_down",
        title: "Already Confirmed, Stop?", message: bodyMessage, 
        yesButtonMessage:"Stop",
        noButtonMessage:"No", 
        result:this.result
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.callOnHoldService(order.customerOrderId);
        // alert('Mark complete ' + order.customerOrderId);
      }
    });
  }

  delete(order:Order):void {
   var  bodyMessage:Array<string> = new Array();
   bodyMessage.push(this.getMenuItemsString(order.menuList) + " for ");
   bodyMessage.push(order.firstName);
   const dialogRef = this.dialog.open(DialogComponent, {
      width: '350px',
      data: {
        titleIconText:"cancel_presentation",
        title: "Cancel?", message: bodyMessage, 
        yesButtonMessage:"Cancel",
        noButtonMessage:"No", 
        result:this.result
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.callDelete(order.customerOrderId);
        // alert('Mark complete ' + order.customerOrderId);
      }
    });
  }

  callDelete(orderId:number):void {
    this.orderService.callDelete(orderId)
    .subscribe(returnedData => {
       // this.response = result;
        if (returnedData.messageCode == 2) {
        alert (returnedData.message);
        }
        this.getOrders('ALL');
        }, 
        error => {
          this.openSnackBar('Operation failed: Server Down ',CONSTANTS.FAILURE_MESSG_STYLE)
        }
      );
  }


  getMenuItemsString(list:CustomerOrderMenu[]):String {
    var menuItems:string = "";
    list.forEach(function(menu){
      menuItems = menuItems + menu.menuItemName + " " + menu.specialInstructions;
    });
   // alert(menuItems);
   return menuItems;
  }
  
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toUpperCase();
    //filterValue = filterValue.toUpperCase();
    
   // this.heros.filter = filterValue.trim().toLowerCase();
   var test:string;
   this.localHeros = this.heros.filter (
    
     item=> 
      item.specialInstructions.toUpperCase().indexOf(filterValue) != -1 ||
      item.firstName.toUpperCase().indexOf(filterValue) != -1  ||
      item.lastName.toUpperCase().indexOf(filterValue) != -1  ||
      item.customerOrderId.toString().indexOf(filterValue) != -1 ||
      item.phone.toUpperCase().indexOf(filterValue) != -1  ||
      this.checkMenuItems(item.menuList, filterValue) 
      )
      
  
   this.dataSource = new MatTableDataSource(this.localHeros);
  }

  checkMenuItems(list:CustomerOrderMenu[], filterValue:string):boolean {
    var menuItems:string;
    list.forEach(function(menu){
      menuItems = menuItems + menu.menuItemName + " " + menu.specialInstructions;
    });
   // alert(menuItems);
   return menuItems.toUpperCase().indexOf(filterValue) != -1 ;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

}
