import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OrderService} from '../order.service';
import {MenuUpdateData} from '../DialogData';
import { FormGroup, FormBuilder} from  '@angular/forms';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MenuCategory, MenuItem } from '../_models/menu-changes';
import { AdminService } from '../admin.service';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {CONSTANTS} from '../constants';
import { Category } from '../Category';

@Component({
  selector: 'app-menu-info-change',
  templateUrl: './menu-info-change.component.html',
  styleUrls: ['./menu-info-change.component.css']
})
export class MenuInfoChangeComponent implements OnInit {
  menuUpdateForm: FormGroup;
  menu:MenuItem;
  displayMode:string;
  isLoading:boolean = false;
  CatagoriesList:Array<MenuCategory>;
  storeId:number;
  submitButtonLabel:string;
  mealTypeId:number;
  public categoryList: Category[] = [];
  public subCategoryList: any = {};
  private categoryIdMap: any = {};
  private subCategoryIdMap: any = {};
  public menuCodeList: any = ['Fresh Cut'];
  public currentCategoryID:number;
  public currentSubCategoryID:number;
  public currentCategory:string = "";
  public error="";
  public showForm:boolean = false;
  public isSubmitting:boolean = false;
  public itemStatus:string[] = ['CREATED', 'DELETED', 'OUT_OF_STOCK'];
  public currentSubCategory: any = {};


  constructor(public dialogRef: MatDialogRef<MenuInfoChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MenuUpdateData, private orderService:OrderService,
    private formBuilder: FormBuilder, private adminService:AdminService, private _snackBar: MatSnackBar) {
      this.menu = data.menu;
      this.displayMode = data.displayMode;

      if (this.displayMode == 'NEW') {
        this.storeId = data.storeId;
        this.submitButtonLabel = 'Create';
      } else {
        this.submitButtonLabel = 'Update';
        this.mealTypeId = data.mealTypeId;
        this.CatagoriesList = data.catagoriesList;
      }
      this.getCategoriesList();
     }

  ngOnInit(): void {
   
  }

  createMenuUpdateForm(){
    if (this.displayMode == "NEW") {
      this.menu.startTime = this.getCurrentTime();
      this.menu.endTime = this.getEndTime();
      this.menuUpdateForm = this.formBuilder.group({
        menuItemName: [this.menu.menuItemName, Validators.required],  
        suffix: [this.menu.suffix],
        menuCode: [this.menu.menuCode, Validators.required],  
        menuItemDiscription: [this.menu.menuItemDescription, Validators.required],  
        orgPrice :[this.menu.orgPrice, Validators.required],
        salePrice :[this.menu.salePrice, Validators.required],
        availableStartTime :[this.menu.startTime, Validators.required],
        availableEndTime :[this.menu.endTime, Validators.required]
        });
    } else {
      this.currentCategoryID = this.menu.categoryId;
      this.currentSubCategoryID = this.menu.subCategoryDisplaySeq;
      let category = this.categoryList.find(cat => cat.categoryId === Number(this.menu.categoryId));
      this.currentCategory = category?.categoryName || '';
      if(!this.menuCodeList.includes(this.menu.menuCode)){
        this.menuCodeList.push(this.menu.menuCode);
      }
      this.showForm = true;
      this.menuUpdateForm = this.formBuilder.group({
      menuID: [this.menu.menuId, Validators.required],
      menuItemName: [this.menu.menuItemName, Validators.required],  
      suffix: [this.menu.suffix],
      menuCode: [this.menu.menuCode, Validators.required],  
      menuItemDiscription: [this.menu.menuItemDescription, Validators.required],  
      orgPrice :[this.menu.orgPrice, Validators.required],
      salePrice :[this.menu.salePrice, Validators.required],
      status: [this.menu.status, Validators.required],
      });
    }

   }

  onSubmit() {
    this.isSubmitting = true;
    this.menuUpdateForm?.get('menuSubCategory')?.setErrors(null);
    this.menuUpdateForm?.get('suffix')?.setErrors(null);
    
    if (this.menuUpdateForm.status == 'VALID') {
      if (this.displayMode == 'NEW') {
        this.create();
      } else {
        this.update();
      }
      this.isSubmitting = false;
    } else {
      this.openSnackBar('Please fill all the required fields and types', CONSTANTS.FAILURE_MESSG_STYLE);
      this.isSubmitting = false;
      return false;
    }
  }

  update() {
    var menuToUpdate:MenuItem = new MenuItem();
    menuToUpdate.menuId = this.menu.menuId;
    menuToUpdate.menuItemName = this.menuUpdateForm.get('menuItemName')?.value;
    menuToUpdate.suffix = this.menuUpdateForm.get("suffix")?.value || '';
    menuToUpdate.menuCode = this.menuUpdateForm.get('menuCode')?.value;
    menuToUpdate.menuItemDescription = this.menuUpdateForm.get('menuItemDiscription')?.value;
    menuToUpdate.salePrice = this.menuUpdateForm.get('salePrice')?.value;
    menuToUpdate.orgPrice = this.menuUpdateForm.get('orgPrice')?.value;
    menuToUpdate.mealCategory= this.currentCategory;
    menuToUpdate.categoryId = this.currentCategoryID;
    menuToUpdate.status = this.menuUpdateForm.get('status')?.value;
    menuToUpdate.subCategoryDisplaySeq = this.currentSubCategoryID;
  
    this.orderService.updateMenu(menuToUpdate)
    .subscribe(result => {
       if (result.result == true) {
        this.openSnackBar('Menu Updated Successfully', CONSTANTS.SUCCESS_MESSG_STYLE);
        this.dialogRef.close(true);
       } else {
        this.openSnackBar('Failed to update Menu', CONSTANTS.FAILURE_MESSG_STYLE);
       }
      }
      );
  }

  create(){
    var menuToUpdate:MenuItem = new MenuItem();
    menuToUpdate.storeId = this.storeId;
    menuToUpdate.menuId = this.menu.menuId;
    menuToUpdate.menuItemName = this.menuUpdateForm.get('menuItemName')?.value;
    menuToUpdate.suffix = this.menuUpdateForm.get("suffix")?.value || '';
    menuToUpdate.menuCode = this.menuUpdateForm.get('menuCode')?.value;
    menuToUpdate.menuItemDescription = this.menuUpdateForm.get('menuItemDiscription')?.value;
    menuToUpdate.salePrice = this.menuUpdateForm.get('salePrice')?.value;
    menuToUpdate.orgPrice = this.menuUpdateForm.get('orgPrice')?.value;
    menuToUpdate.mealCategory= this.currentCategory;
    menuToUpdate.categoryId = this.currentCategoryID;
    menuToUpdate.subCategoryDisplaySeq = this.currentSubCategoryID;
   
    var startDt:Date = new Date(this.menuUpdateForm.get('availableStartTime')?.value);
    var endDt:Date = new Date(this.menuUpdateForm.get('availableEndTime')?.value);

    startDt.setSeconds(0);
    startDt.setMilliseconds(0);
    endDt.setSeconds(0);
    endDt.setMilliseconds(0);
    
    menuToUpdate.startTime=startDt.getHours() +':'+startDt.getMinutes();
    menuToUpdate.endTime=endDt.getHours() + ':' + endDt.getMinutes();
    if (endDt <= startDt) {
      this.openSnackBar('End Time should be greater than Start Time', CONSTANTS.FAILURE_MESSG_STYLE);
      return false;
    }

    this.orderService.createMenu(menuToUpdate)
    .subscribe(result => {
       if (result.result == true) {
        this.openSnackBar('Menu Created Successfully', CONSTANTS.SUCCESS_MESSG_STYLE);
        this.dialogRef.close(true);
       } else {
      this.openSnackBar('Failed to create Menu', CONSTANTS.FAILURE_MESSG_STYLE);
       }
      }
      );
  }

  getSubCategoryName(subCategoryID: number): string {
    const subCategoryName = Object.keys(this.subCategoryIdMap).find(key => this.subCategoryIdMap[key] === subCategoryID);
    return subCategoryName ? subCategoryName : '';
  }

  getCategoryName(CategoryID: number): string {
    const Category = Object.keys(this.categoryIdMap).find(key => this.categoryIdMap[key] === CategoryID);
    return Category ? Category : '';
  }

  closeWindow(){
    this.dialogRef.close(false);   
  }

  onCategoryChange(val:any): void {
      this.currentCategoryID = Number(val);
      let category = this.categoryList.find(cat => cat.categoryId === Number(val));
      this.currentCategory = category?.categoryName || '';
      console.log(this.currentCategory, this.currentCategoryID);
  }

  onSubCategoryChange(val:any): void {
      this.currentSubCategoryID = Number(val);
    
  }

  removeMenuCreation(message:string){
    this.menuUpdateForm = this.formBuilder.group({});
    this.showForm = false;
    this.error = message;
  }

  getCurrentTime(): string {
    // Implement your logic to get the current time
    // For example:
    const currentTime = new Date();
    return currentTime.toISOString();
  }

  getEndTime(): string {
    // Implement your logic to calculate the end time
    // For example:
    const endTime = new Date();
    endTime.setDate(endTime.getDate()+7);
    endTime.setHours(endTime.getHours() + 1);
    return endTime.toISOString();
  }

  //get Categories
  getCategories() {
  this.isLoading = true; 
    this.adminService.getAllMenuCategories(this.storeId +"", "-1")
          .subscribe(result =>  {
            this.isLoading = false; 
            if (result.items != null && result.items.length > 0) {
              this.CatagoriesList = result.items as MenuCategory[];
            }
            
          });
  }  

  getCategoriesList():void{
    this.adminService.getCategories().subscribe(result => {
      let payload = result?.items;
      if(payload!=null && payload.length > 0){
        const res = payload[0];
      const categoryIDsMap = res["categoryIDsMap"];
      const subCategoryIDsMap = res["subCategoryIDsMap"];
      const catAndSubCat = res["categories"];
      this.categoryIdMap = categoryIDsMap;
      this.subCategoryIdMap = subCategoryIDsMap;
      this.categoryList = catAndSubCat.map((item: any) => {
        var  catItem : Category = new Category();
        catItem.categoryName = item?.categoryName,
        catItem.categoryId = categoryIDsMap[item?.categoryName]
        return catItem;
    });
      this.categoryList.sort((a,b)=>a?.categoryName.localeCompare(b?.categoryName));

            // Create a new object of subcategories with id and name
      this.subCategoryList = {};
      catAndSubCat.forEach((item: any) => {
        this.subCategoryList[item?.categoryName] = item.subcategories.map((subCat: string) => {
          return {
            subCategoryId: subCategoryIDsMap[subCat],
            subCategoryName: subCat
          };
        });
        this.subCategoryList[item?.categoryName].sort((a, b) => a?.subCategoryName.localeCompare(b?.subCategoryName));
      });
      this.menuCodeList = this.menuCodeList.concat(this.categoryList.map((cat: any) => cat.categoryName));
      this.showForm = true;
      this.createMenuUpdateForm();
     }
     else{
      this.categoryIdMap = {};
      this.subCategoryIdMap = {};
      this.categoryList = [];
      console.error('Failed to get Categories');
      this.openSnackBar('There are no Categories List', CONSTANTS.FAILURE_MESSG_STYLE);
     }
    }, error => {
      console.error('Failed to get Categories', error);
      this.showForm = false;
      this.removeMenuCreation('Hiding the DailySpcials Form because could not able to get the categories and subcategories list from the DB');
      this.openSnackBar('Failed to get Categories', CONSTANTS.FAILURE_MESSG_STYLE);
    });
  }


  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }
}
