<div class="banner">
    <h3>Change Customer Address</h3>
</div>
<div style="width: 100%; margin-left: 20px;" >
    <form [formGroup]="customerUpdateForm" >
        <mat-form-field appearance="fill">
            <mat-label >Tag</mat-label>
            <input matInput placeholder="Placeholder" formControlName="tag"
             required ng-valid> 
        </mat-form-field> &nbsp;
        <br>
        <mat-form-field appearance="fill">
            <mat-label >Address Line One</mat-label>
            <input matInput placeholder="Placeholder" formControlName="addressOne"
             required ng-valid> 
        </mat-form-field> &nbsp;  
        <br>
        <mat-form-field appearance="fill">
            <mat-label >Address Line Two</mat-label>
            <input matInput placeholder="Placeholder" formControlName="addressTwo"
             required ng-valid> 
        </mat-form-field> &nbsp;  
        <br>
        <mat-form-field appearance="fill">
            <mat-label >City</mat-label>
            <input matInput placeholder="Placeholder" formControlName="city"
             required ng-valid> 
        </mat-form-field> &nbsp;
        <br>
        <mat-form-field appearance="fill">
            <mat-label >State</mat-label>
            <input matInput placeholder="Placeholder" formControlName="state"
             required ng-valid> 
        </mat-form-field> &nbsp;
        <br>
        <mat-form-field appearance="fill">
            <mat-label >Zip Code</mat-label>
            <input matInput placeholder="Placeholder" formControlName="zip"
             required ng-valid> 
        </mat-form-field> &nbsp;
        <br>
    <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>&nbsp;
    <button mat-raised-button color="primary" (click)="onCancel()">Close</button>&nbsp;
   
    </form>
</div>