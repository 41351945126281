import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../DialogData';
import {PopupResult} from '../order';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  myTextarea: string = "";

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    if (this.data.showTextArea == true ) {
       var pResult: PopupResult = new PopupResult();
       pResult.result = false;
       pResult.userMessage = ''  
       this.dialogRef.close(pResult);
    } else {
      this.dialogRef.close(false);
    }
  }
  
  onYesClick(): void {
    if (this.data.showTextArea == true ) {
      var pResult: PopupResult = new PopupResult();
      pResult.result = true;
      pResult.userMessage = this.myTextarea;  
      this.dialogRef.close(pResult);
   } else {
     this.dialogRef.close(true);
   }
  }

}
