<mat-toolbar  color="primary"  style="height:40px;float: right;" >
    <img src="../../assets/scooter-with-box.png" width="40px;" height="40px;">
    <span class="example-spacer"></span>
   
            <div style="float:right;margin-right: 20px; max-height: 20px;" >
                <span style="color:yellow">{{user.firstName}}&nbsp; {{user.lastName}}</span>&nbsp;&nbsp;
                <a (click)="logout()" style="vertical-align:top;font-size: 18px;"> <mat-icon  color="blue" style="font-size: 22px;" >lock icon</mat-icon>Logout</a>
            </div>
</mat-toolbar>

&nbsp;


<mat-tab-group style="width: 100%;" (selectedTabChange)="onTabChange($event)"  >
  <mat-tab label="Today's Orders" >
    <br>
    <h1 style="text-align: center;"> Today's Order</h1>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button color="primary" 
    style="height:50px;vertical-align: top;" (click)="onRefresh()">Refresh</button> &nbsp;
    <br>
    <p style="margin-left: 20px;margin-top:5px; font-size:19px;color: gray;"># of items: {{this.consolidatedByOrders?.length}} </p>
    <table mat-table
    [dataSource]="dataSource" multiTemplateDataRows
    class="mat-elevation-z8">
<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay,let i = index">
 <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell style="font-size:18px;" *matCellDef="let element" style="font-size:18px;">   {{element[column]}} </td>
 
</ng-container>

<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
<ng-container matColumnDef="expandedDetail">
  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
    <div class="example-element-detail"
         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
         <div class="example-element-diagram"  *ngFor="let itemx of element.details">
          <div class="example-element-position" style="font-size:18px;"> {{itemx.menuName}} </div>
          <div class="example-element-position" style="font-size:18px;"> Number of items: {{itemx.quantity}} </div>
        </div>
    </div>
  </td>
 </ng-container>

<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
<tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
   class="example-element-row"
   [class.example-expanded-row]="expandedElement === element"
   (click)="expandedElement = expandedElement === element ? null : element">
</tr>
<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
  </mat-tab>

  <mat-tab label = "Today's Summary">
      <div>
        <br>
        <h1 style="text-align: center;"> Summary Report</h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button color="primary" 
        style="height:50px;vertical-align: top;" (click)="onRefresh()">Refresh</button> &nbsp;
        <br>
        <p style="margin-left: 20px;margin-top:5px; font-size:19px;color: gray;"># of items: {{this.reportSummary?.length}} </p>
          <table mat-table [dataSource]="reportSummaryForDisplay"  class="mat-elevation-z8" >
            
            <ng-container matColumnDef="customerOrderIds">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Order#</th>
              <td mat-cell *matCellDef="let element"  style="font-size:18px;">
              {{element.customerOrderIds}}</td>
            </ng-container>
            <ng-container matColumnDef="menuName">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Item</th>
              <td mat-cell *matCellDef="let rowItem" style="font-size: 18px;" >
              {{rowItem.menuName}}</td>
              </ng-container>

              <ng-container matColumnDef="address">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Cities</th>
                <td mat-cell *matCellDef="let rowItem" style="font-size: 18px;">
                {{rowItem.address}}</td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Count</th>
                  <td mat-cell *matCellDef="let rowItem" style="font-size: 18px;">
                    {{rowItem.quantity}}</td>
                </ng-container>

                <ng-container matColumnDef="salePrice">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Sale Price</th>
                  <td mat-cell *matCellDef="let rowItem" style="font-size: 18px;">
                    {{rowItem.salePrice | currency:'USD':'symbol':'1.2-2'}}</td>
                </ng-container>
                <ng-container matColumnDef="orgPrice">
                  <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Org. Price</th>
                  <td mat-cell *matCellDef="let rowItem" style="font-size: 18px;">
                    {{rowItem.originalPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                </ng-container>
              <tr mat-header-row *matHeaderRowDef="reportSummaryHeaders; sticky: true"></tr>
              <tr mat-row *matRowDef="let row;columns: reportSummaryHeaders"></tr>
          </table>
        </div>     
  </mat-tab>

  <mat-tab label = "Reports">
    <p></p>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

      <mat-form-field appearance="fill">
          <mat-label>Choose a start date</mat-label>
          <input matInput [matDatepicker]="stardate" formControlName="startDate" required>
          <mat-datepicker-toggle matSuffix [for]="stardate"></mat-datepicker-toggle>
          <mat-datepicker #stardate></mat-datepicker>
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="fill">
          <mat-label>Choose a end date</mat-label>
          <input matInput [matDatepicker]="enddate" formControlName="endDate" required>
          <mat-datepicker-toggle matSuffix [for]="enddate"></mat-datepicker-toggle>
          <mat-datepicker #enddate></mat-datepicker>
      </mat-form-field>
      &nbsp;
      <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit" [disabled]="disableSearchButton">Search</button> &nbsp;
      <span *ngIf="totalSales>0" style="margin-left: 5px;font-size: 18px; font-weight: bold;color: black;">Total Sales:</span><span *ngIf="totalSales>0" style="margin-left: 2px;font-size: 22px; color: green;">{{totalSales | currency:'USD':'symbol':'1.2-2'}}</span> 
      <!--
      <span *ngIf="totalProfit>0" style="margin-left: 20px;font-size: 18px; font-weight: bold;color: black;">Total Profit:</span><span *ngIf="totalProfit>0" style="margin-left: 2px;font-size: 22px; color: green;">{{totalProfit | currency:'USD':'symbol':'1.2-2'}}</span> 
      <span *ngIf="totalProfit>0" style="margin-left: 20px;font-size: 18px; font-weight: bold;color: black;">70% of Profit:</span><span *ngIf="totalProfit>0" style="margin-left: 2px;font-size: 22px; color: green;">{{(totalProfit * 0.7) | currency:'USD':'symbol':'1.2-2'}}</span> 
      -->
  </form>

    <div>
      <p style="margin-left: 20px;margin-top:5px; font-size:19px;color: gray;"># of items: {{this.prebookReportItems?.length}} </p>
        <table mat-table [dataSource]="reportDataForDisplay"  class="mat-elevation-z8" >

          <ng-container matColumnDef="customerOrderId">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Order#</th>
            <td mat-cell *matCellDef="let element"  style="font-size:18px;">
            {{element.customerOrderId}}</td>

          </ng-container>
            <ng-container matColumnDef="customer-name">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Customer</th>
              <td mat-cell *matCellDef="let element" style="font-size:18px;" >
              {{element.firstname}}, {{element.lastname}}</td>

            </ng-container>
            <ng-container matColumnDef="address">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >City</th>
              <td mat-cell *matCellDef="let element" style="font-size:18px;">
              {{element.address}}</td>
            </ng-container>
            <ng-container matColumnDef="orderCreateTime" >
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Ordered On</th>
              <td mat-cell *matCellDef="let element" style="font-size:18px;">
              {{element.orderCreateTime  | date:'MMM d, y, h:mm a'}}</td>
            </ng-container>
            <ng-container matColumnDef="orderCloseTime">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef   >Delivered On</th>
                <td mat-cell *matCellDef="let element" style="font-size:18px;">
                {{element.orderCloseTime |  date:'MMM d, y, h:mm a'}}</td>
              </ng-container>
              <ng-container matColumnDef="quantity">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Count</th>
                <td mat-cell *matCellDef="let element" style="font-size:18px;">
                  {{element.quantity}}</td>
              </ng-container>
              <ng-container matColumnDef="salePrice">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">Sale Price</th>
                <td mat-cell *matCellDef="let element" style="font-size:18px;">
                  {{element.salePrice | currency:'USD':'symbol':'1.2-2'}}</td>
              </ng-container>
              <ng-container matColumnDef="orgPrice">
                <th class="w-order-no" mat-header-cell *matHeaderCellDef  ><img src="../../assets/dollar-sign.png" width="25px" height="22px">iPerkz Price</th>
                <td mat-cell *matCellDef="let element" style="font-size:18px;">
                  {{element.originalPrice | currency:'USD':'symbol':'1.2-2'}}</td>
              </ng-container>
            <ng-container matColumnDef="menuName">
              <th class="w-order-no" mat-header-cell *matHeaderCellDef  >Sale Item</th>
              <td mat-cell *matCellDef="let element" style="font-size:18px;">
                {{element.menuName}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="reportHeaders; sticky: true"></tr>
              <tr mat-row *matRowDef="let row;columns: reportHeaders"></tr>
          </table>
    </div>
    </mat-tab>

</mat-tab-group>