
export class DailySpecial{
    menuId:number;
    menuCode: string;
    suffix: string;
    storeId: number;
    menuItemName: string;
    menuItemDesc: string;
    origPrice: number;
    salePrice: number;
    menuSubCategory: string;
    mealType: string;
    menuCategory: string;
    availableStartTime: Date;
    availableEndTime:Date;
    startTime:string;
    endTime:string;
    imageUrl:string;
    isLoading:boolean = false;
    quantity:number;
    subCategoryDisplaySeq:number;
}