import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../DialogData';
import {PopupResult} from '../order';

@Component({
  selector: 'app-grocery-order-packer-selection',
  templateUrl: './grocery-order-packer-selection.component.html',
  styleUrls: ['./grocery-order-packer-selection.component.css']
})
export class GroceryOrderPackerSelectionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GroceryOrderPackerSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }
  onclick (name:string){
    var pResult: PopupResult = new PopupResult();
      pResult.result = true;
      pResult.userMessage = name;  
      this.dialogRef.close(pResult);
  }
}
