import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { MenuCategory } from '../_models/menu-changes';
import { CustomerViewWrapper } from '../constants';
import { Router, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { validateVerticalPosition } from '@angular/cdk/overlay';


@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css', './bootstrap.min.css']
})
export class CustomerViewComponent implements OnInit {
  isLoading:boolean = false;
  categories:Array<MenuCategory>;
  masterCategories:Array<MenuCategory>;
  wrapper:CustomerViewWrapper;
  storeId:number;
 

  constructor(private adminService:AdminService, public router : Router) { }

  ngOnInit(): void {
    var urlTree:UrlTree = this.router.parseUrl(this.router.url);
    this.storeId = urlTree.queryParams["store"] as number;
    this.getMenu();
  }

  getMenu() {
    this.isLoading = true;
    this.adminService.getAllMenuItemsByCategory(this.storeId)
      .subscribe(result =>  {
        this.isLoading = false; 
        this.wrapper = result;
        if (result.categories != null && result.categories.length > 0) {
          this.categories = result.categories as MenuCategory[];
          this.masterCategories = result.categories;
      //    alert("success " + this.categories?.length);
        }
      });  
  }

  openPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=com.appisoft.perkz");
  }

  openAppStore() {
    window.open("https://apps.apple.com/us/app/iperkz/id1512501611?uo=4");
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toUpperCase();
    var myClonedArray:Array<MenuCategory> = []; 
    this.masterCategories.forEach(val => myClonedArray.push(Object.assign({}, val)));
   
    myClonedArray.forEach(category => {
      var filetedMenu = category.menuItems.filter(element => 
        element.menuItemName.toUpperCase().indexOf(filterValue) != -1 
      );
      category.menuItems = filetedMenu;
      /*
      this.categories.forEach(item => {
        if (item.categoryName == category.categoryName ){
          item.menuItems = filetedMenu;
        } 
      });
      */
    });
    this.categories = myClonedArray;
       
  }

  showPaymentScreen() {
    this.router.navigate(['/payment']);
  }
}
