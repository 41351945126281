import { Component, OnInit, ɵCompiler_compileModuleAndAllComponentsAsync__POST_R3__ } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import {AdminService} from '../admin.service';
import {MatRadioChange} from '@angular/material/radio'
import {MatTabChangeEvent} from '@angular/material/tabs'
import {MenuCategory, MenuItem, MenuItemAdditions} from '../_models/menu-changes'
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import {MatDialog} from '@angular/material/dialog';
import { MenuInfoChangeComponent } from '../menu-info-change/menu-info-change.component';
import { AddAdditionComponent } from '../add-addition/add-addition.component';
import {ChangeAdditionComponent} from '../change-addition/change-addition.component';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { MenuCriteria } from '../OrderReportCriteria';
import {CONSTANTS} from '../constants';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DailyspecialsService } from '../dailyspecials.service';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import Cache from "../Cache"


@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit  {
  categorySelected:string = "ALL";

  isLoading:boolean = false;
  totalMenuItemsLoading:boolean = false;
  stores:Array<Object>;
  CatagoriesList:Array<MenuCategory>;
  MenuList:Array<MenuItem>;
  MasterMenuList:Array<MenuItem>;
  MealTypeMenuList:Array<MenuItem>;
  MealTypeMenuListCopy:Array<MenuItem>;
  menuItemsByCategory:Array<MenuItem>;

  selectedMenu:MenuItem = new MenuItem();
  selectedCategory:MenuCategory = new MenuCategory();

  public categoryList: string[] = [];
  public currentCategory: string = 'Select Category';
  public currentSubCategory: string = 'Select Sub Category';
  public subCategoryList: any = {};
  public sortOptions: any[] = [
  {value: 1, label: 'Display Order'},
  {value: 2, label: 'Alphabetically'},
  {value: 3, label: 'Price'},
  {value: 4, label: 'Menu Creation Time'} ];
  public sortOption:number = this.sortOptions[0]?.value || 1;
  private categoryIdMap: any = {};
  private subCategoryIdMap: any = {};

  links = ['Categories', 'Menu Order', 'Menu Additions', 'Web Menu (Scan)'];
  activeLink = this.links[0];
  background = '';
  mealType:number = 1;

  storeId = -1;
  isOrderedChanges:boolean = false;

  

  ngOnInit(): void {
  }

  constructor (private adminService:AdminService,  public dialog: MatDialog,
    private _snackBar: MatSnackBar, private dailyspecials:DailyspecialsService,) {
    this.getAllStores();
    this.getCategoriesList();
  }

  // Transfer Items Between Lists
  MoviesList = [
    'The Far Side of the World',
    'Morituri',
    'Napoleon Dynamite',
    'Pulp Fiction',
    'Blade Runner',
    'Cool Hand Luke',
    'Heat',
    'Juice'
  ];

  MoviesWatched = [
  ];

  onDrop(event: CdkDragDrop<string[]>) {
    
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }  

  sourceOnDrop(event: CdkDragDrop<string[]>) {
    
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }  

  destinationOnDrop(event: CdkDragDrop<MenuItem[]>) {
    if (event.isPointerOverContainer == false) {
      console.log("item removed");
      var sourceMenuItem:MenuItem = event.previousContainer.data[event.currentIndex];
      const index =  event.container.data.indexOf(sourceMenuItem);
      if (index > -1) {
        event.container.data.splice(index, 1);
      }

      return;
    }
   // alert(event.t);
    if (event.previousContainer === event.container) {
      console.log("item reorderd");
      this.isOrderedChanges = true;
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log("item ADDED");
       //don't allow duplicates 
       //Start
      var menuId:number = (event.previousContainer.data[event.previousIndex]).menuId;
       var  contains:Boolean = false;
       if ( event.container.data != null) {
        event.container.data.forEach(element => {
          //alert (element.menuItemName)
          if (element.menuId == menuId ) {
            //alert (element.menuId + " ==" + menuId);
            contains = true;
          }
        });
        if ( contains == true) {
            return ;
        }
      }
       //end
       
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }  

  applyChanges():void{
    //first check if the radio button is set to display order or value 1
    // then if yes make a call, else 
    //return a error message saying first set the display order option button
    console.log(this.sortOption, this.sortOptions[0]?.value);
    if(this.sortOption === this.sortOptions[0]?.value){
      this.changeMenuOrder();
    }
    else{
      console.error('First set the display order option button');
      this.openSnackBar('First set the display order option button', CONSTANTS.FAILURE_MESSG_STYLE);
    }
  }
 
  getAllStores(): void {
    
    this.isLoading = true;
    this.adminService.getAllStores()
        .subscribe(result =>  {
          this.isLoading = false; 
          if (result.items != null && result.items.length > 0) {
            this.stores = result.items;
          }
          
        });  
  }

  public doFilter = (value: string) => {
    //alert(this.MasterMenuList)
    this.MenuList = this.MasterMenuList.filter(val=>{
      const lowerCaseMenuItemName = val.menuItemName.toLowerCase();
      const lowerCaseValue = value.toLowerCase();
      if(lowerCaseMenuItemName.includes(lowerCaseValue)){
        return true;
      }

    //   // Check the Levenshtein distance
    // const distance = this.levenshtein(lowerCaseMenuItemName, lowerCaseValue);
    // const maxLength = Math.max(lowerCaseMenuItemName.length, lowerCaseValue.length);
    // const similarity = (maxLength - distance) / maxLength;

    // // Consider it a match if the similarity is above a certain threshold (e.g., 0.8)
    // return similarity > 0.8;

    });
  }

  // Levenshtein distance algorithm
private levenshtein(a: string, b: string) {
  const matrix:any = [];

  let i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  let j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
      }
    }
  }

  return matrix[b.length][a.length];
}
  
  storeSelectionChange(event) {
    this.isLoading = true;
    this.storeId = event.value;
    if (this.activeLink == this.links[0]) {
      this.getCategoriesInformation(event.value);
    } else if (this.activeLink == this.links[1]) {
      this.isLoading = true;
      this.totalMenuItemsLoading = true;
      this.MenuList = new Array();
      this.MasterMenuList = new Array();

      //getting all the menuitems for the store id
      this.adminService.getAllMenuItemsByCategory(this.storeId )
          .subscribe(result =>  {
            if (result.categories != null && result.categories.length > 0) {
              this.MasterMenuList = [];
              this.MenuList = [];
              result.categories.forEach(element => {
                element.menuItems.forEach(menuItem => {
                  this.MenuList.push(menuItem);
                  this.MasterMenuList.push(menuItem);
               });
              });
            }
            else{
              this.MenuList = [];
            }
            this.totalMenuItemsLoading = false;
          }, error => {
            this.totalMenuItemsLoading = false;
          });

      //Why this code?
      this.adminService.getAllMenuItems(this.storeId , this.mealType)
          .subscribe(result =>  {
            this.isLoading = false;
            if (result.items != null && result.items.length > 0) {
             // this.MealTypeMenuList = result.items as MenuItem[];
            } 
          });
    } else if (this.activeLink == this.links[2]) {
      this.isLoading = true;
        this.adminService.getAllMenuItems(this.storeId , -1)
            .subscribe(result =>  {
              this.isLoading = false; 
              if (result.items != null && result.items.length > 0) {
                this.MenuList = result.items as MenuItem[];
              }
              
            });  
    } else if (this.activeLink == this.links[3]) {
      this.getCategoriesInformation(this.storeId + "");
    }
  }

  mealTypeSelectionChange(event: MatRadioChange) {
    if (this.activeLink == this.links[0]) {
        this.mealType = event.value;
        this.isLoading = true;
        this.adminService.getAllMenuCategories(this.storeId + "", this.mealType + "")
            .subscribe(result =>  {
              this.isLoading = false; 
              
              if (result.items != null && result.items.length > 0) {
                this.CatagoriesList = result.items as MenuCategory[];
              }
              
            });  

        

      } else if (this.activeLink == this.links[1]) {
          this.mealType = event.value;
          this.isLoading = true;
          this.adminService.getAllMenuItems(this.storeId , this.mealType)
              .subscribe(result =>  {
                
                if (result.items != null && result.items.length > 0) {
                  this.MealTypeMenuList = result.items as MenuItem[];
                }
                this.isLoading = false; 
              }); 
          console.log("begore getting categories NATHAN")    
          this.getCategories();
         
      } else  if (this.activeLink == this.links[2]) {
        
      }
  }

  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

  onCategoryChange(value:string): void {
    this.currentCategory = value;
  }

  onSubCategoryChange(value:string): void {
    this.currentSubCategory = value;
    this.getMenuItemsByCatAndSubCat(this.categoryIdMap[this.currentCategory], this.subCategoryIdMap[this.currentSubCategory]);
  }

  getCategoriesList():void{
    this.adminService.getCategories().subscribe(result => {
      let payload = result?.items;
      if(payload!=null && payload.length > 0){
        const res = payload[0];
        const categoryIDsMap = res["categoryIDsMap"];
        const subCategoryIDsMap = res["subCategoryIDsMap"];
        this.categoryIdMap = categoryIDsMap;
        this.subCategoryIdMap = subCategoryIDsMap;
        const catAndSubCat = res["categories"];
      this.categoryList = catAndSubCat.map((item: any) => item.categoryName);
      this.categoryList.sort()
      catAndSubCat.forEach((item: any) => {
        this.subCategoryList[item?.categoryName] = item?.subcategories;
        this.subCategoryList[item?.categoryName].sort();
      });
      this.onCategoryChange(this.categoryList[0]);
      this.onSubCategoryChange(this.subCategoryList[this.categoryList[0]][0]);
     }
     else{
      console.error('Failed to get Categories');
      this.openSnackBar('Categories List is null', CONSTANTS.FAILURE_MESSG_STYLE);
     }
    }, error => {
      console.error('Failed to get Categories', error);
      this.openSnackBar('Failed to get Categories', CONSTANTS.FAILURE_MESSG_STYLE);
    });
  }

  getMenuItemsByCatAndSubCat(cat: number, subCat: number): void {
    this.isLoading = true;
    try{
      let storeID = this.storeId;
      this.adminService.getMenuByCategory(25, cat, subCat).subscribe(result => {
        let payload = result?.items;
        console.log(result);
        if(payload!=null && payload.length > 0){
          this.MealTypeMenuList = payload as MenuItem[];
          this.MealTypeMenuListCopy = JSON.parse(JSON.stringify(payload)) as MenuItem[];
        }
        else{
          this.MealTypeMenuList.length = 0;
          this.MealTypeMenuListCopy.length = 0;
          this.openSnackBar('Menu Items List is null', CONSTANTS.FAILURE_MESSG_STYLE);
        }
        this.sortOption = this.sortOptions[0]?.label;
      }, error => {
        console.error('Failed to get Menu Items', error);
        this.openSnackBar('Failed to get Menu Items', CONSTANTS.FAILURE_MESSG_STYLE);
      }
      );
    }
    catch(e){
      console.error('Failed to get Menu Items', e);
      this.openSnackBar('Failed to get Menu Items', CONSTANTS.FAILURE_MESSG_STYLE);
    }
    this.isLoading = false;
  }

  onSortOptionChange(option: number): void {
    switch (option) {
      case 1:
        this.MealTypeMenuList = JSON.parse(JSON.stringify(this.MealTypeMenuListCopy));
        break;
      case 2:
        this.MealTypeMenuList.sort((a, b) => a.menuItemName.localeCompare(b.menuItemName));
        break;
      case 3:
        this.MealTypeMenuList.sort((a, b) => (a.salePrice < b.salePrice)?1:-1);
        break;
      case 4:
        this.MealTypeMenuList.sort((a, b) => (new Date(a.startTime).getTime() > new Date(b.startTime).getTime())?1:-1);
        break;
      default:
        console.error('Invalid option:', option);
        this.openSnackBar('Invalid option', CONSTANTS.FAILURE_MESSG_STYLE);
    }
  }

  uploadImage(event, item:MenuItem) {
    //alert ("1 ::" + item.menuItemName);
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var num: number = Math.round(file.size / 1024);
      if (num > 2000) {
       // alert("File size exceed maximum allowed size of 2 MB");
        this.openSnackBar('File size exceed maximum allowed size of 2 MB','Failure');
        return;
      }
      var splitFileName: string[] = file.name.split(".");
      if (splitFileName?.length != 2) {
        this.openSnackBar('Invalid File','Failure');
        return;
      }

      var validFileExtn:boolean = false;
      var fileExtension:string = splitFileName[1];
      for(var extn of CONSTANTS.ALLOWED_FILE_TYPES ) {
        if (fileExtension == extn) {
          validFileExtn = true;
          break;
        }
      }
      if ( validFileExtn == false) {
        this.openSnackBar('Invalid File','Failure');
        //var str:string = 'Allowed File extensions are :';
        //str = str + CONSTANTS.ALLOWED_FILE_TYPES.toString();
       //this.openSnackBar(str , '');
        return;
      }

      
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append("item", item.menuId + "");
      //item.isLoading = true;
      this.dailyspecials.uploadImage(formData).subscribe (
        result=> {
        //  item.isLoading = false;
          var imageUrl:string = result.payload["IMAGE_URL"];
          item.imageUrl = imageUrl;
        }
      );
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    // alert(event.index);
    this.activeLink = this.links[event.index];
    if (event.index == 1) {   
      this.isLoading = true;
      this.MenuList = new Array();
      this.MasterMenuList = new Array();

      this.adminService.getAllMenuItemsByCategory(this.storeId )
          .subscribe(result =>  {
            this.isLoading = false; 
            if (result.categories != null && result.categories.length > 0) {
              result.categories.forEach(element => {
                //alert(element.menuItems.length)
                element.menuItems.forEach(menuItem => {
                  this.MenuList.push(menuItem);
                  this.MasterMenuList.push(menuItem);
               });
               
              });
             
            }
            
          });
      this.isLoading = true;
      this.adminService.getAllMenuItems(this.storeId , this.mealType)
          .subscribe(result =>  {
            this.isLoading = false; 
            if (result.items != null && result.items.length > 0) {
              this.MealTypeMenuList = result.items as MenuItem[];
            }
            
          });
          
    } else if ((event.index == 2)) {
        this.isLoading = true;
        this.adminService.getAllMenuItems(this.storeId , -1)
            .subscribe(result =>  {
              this.isLoading = false; 
              this.MenuList = new Array();
              this.MasterMenuList = new Array();
              if (result.items != null && result.items.length > 0) {
                this.MenuList = result.items as MenuItem[];
                this.MasterMenuList = result.items as MenuItem[];
              }
              
            });  
           
    } else  if ((event.index == 3)) {
      this.getCategoriesInformation(this.storeId + "");
    }
  }

  getCategoriesInformation(storeId:string) {
    this.isLoading = true;
    this.adminService.getAllMenuCategories(storeId, this.mealType + "")
    .subscribe(result =>  {
      this.isLoading = false; 
      //alert(result.items.length + " --")
      
      if (result.items != null && result.items.length > 0) {
        this.CatagoriesList = result.items as MenuCategory[];
      }
      
    });  
  }

  getMenuInformation() {
    this.isLoading = true;
          this.MenuList = new Array();
          this.MasterMenuList = new Array();
          this.adminService.getAllMenuItemsByCategory(this.storeId )
              .subscribe(result =>  {
                this.isLoading = false; 
                if (result.categories != null && result.categories.length > 0) {
                  result.categories.forEach(element => {
                     //alert(element.menuItems.length)
                    element.menuItems.forEach(menuItem => {
                      this.MenuList.push(menuItem);
                      this.MasterMenuList.push(menuItem);
                   });
                   
                  });
                 
                }
                
              });  

          this.isLoading = true;
          this.adminService.getAllMenuItems(this.storeId , this.mealType)
              .subscribe(result =>  {
                this.isLoading = false; 
                if (result.items != null && result.items.length > 0) {
                  this.MealTypeMenuList = result.items as MenuItem[];
                } else {
                  this.MealTypeMenuList = new Array<MenuItem>()
                }
                
              });
  }
  
  itemAt() {
   // alert(this.CatagoriesList[1].categoryName);
  }

  changeCategoriesOrder() {
    this.isLoading = true;
    this.adminService.changeCategoryOrder(this.CatagoriesList, this.storeId, this.mealType)
        .subscribe(result =>  {
          this.isLoading = false; 
          
        });  
  }

  changeMenuOrder() {
    if (this.categorySelected != 'ALL') {
      alert("Cannot apply menu changes on Partial List");
    } else {
      this.isLoading = true;
      this.MealTypeMenuList =  this.MealTypeMenuList.sort( this.compare );
      this.MealTypeMenuList =  this.MealTypeMenuList.sort( this.compare );
      this.adminService.changeMenuOrder(this.MealTypeMenuList, 25, this.categoryIdMap[this.currentCategory], this.subCategoryIdMap[this.currentSubCategory])
        .subscribe(result =>  {
          this.isLoading = false; 
          //nathan
          this.isOrderedChanges = false;
        }); 
    }
  }

  changeMenuOrderForCategory(){
    this.isLoading = true;
    
    this.adminService.changeMenuOrderForCategory(this.menuItemsByCategory, this.storeId)
        .subscribe(result =>  {
          this.isLoading = false; 
          
        }); 
  }

  selectMenu(menuItem:MenuItem) {
    this.selectedMenu = menuItem;
   // alert(menuItem.menuId);
  }

  selectCategory(categoryItem:MenuCategory) {
    this.selectedCategory = categoryItem;
    var criteria: MenuCriteria = new MenuCriteria();
    criteria.storeId = this.storeId;
    criteria.categoryName = categoryItem.categoryName;

    this.adminService.getMenuItemsByCriteria(criteria)
    .subscribe(result =>  {
      this.isLoading = false; 
      this.menuItemsByCategory = result.items as MenuItem[];
      //alert (this.menuItemsByCategory.length)
    });
  }

  showUpdateScreen(menuItem:MenuItem) {
 
   var  bodyMessage:Array<string> = new Array();
    
   bodyMessage.push("test");
   
    const dialogRef = this.dialog.open( MenuInfoChangeComponent, {
      width: '500px',
      disableClose: true,
      data: {
       menu: menuItem,
       displayMode: "CHANGE",
       mealTypeId: this.mealType,
       catagoriesList: this.CatagoriesList
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //alert ("refresh me");
        this.getMenuInformation();
      } else {
        //alert ("Dont reresh me");
      }
    });
  }

  addNew() {
    const dialogRef = this.dialog.open( MenuInfoChangeComponent, {
      width: '500px',
      disableClose: true,
      data: {
       menu: new MenuItem(),
       displayMode: "NEW",
       storeId: this.storeId,
       mealTypeId: this.mealType,
       //catagoriesList: this.catagoriesList
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //alert ("refresh me");
        this.getMenuInformation();
      } else {
        //alert ("Dont reresh me");
      }
    }); 
  }

  onDeleteAdditions(addition:MenuItemAdditions) {
    this.isLoading = true;
    addition.storeId = this.storeId;
    this.adminService.deleteAddition(addition)
        .subscribe(result =>  {
          this.isLoading = false; 
          
        });  
    
  }

  onAddAdditions(menuItem:MenuItem) {
   // alert("test:::" + menuItem.menuItemName);
    const dialogRef = this.dialog.open( AddAdditionComponent, {
      width: '500px',
      disableClose: true,
      data: {
       menu: menuItem,
       displayMode: "NEW",
       storeId: this.storeId
      }
      
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //alert ("refresh me");
        this.getMenuInformation();
      } else {
        //alert ("Dont reresh me");
      }
    }); 
  }


  onChangePrice(addition:MenuItemAdditions) {
    //alert("clicked");
    /*
    this.isLoading = true;
    addition.storeId = this.storeId;
    this.adminService.changeAdditionPrice(addition)
        .subscribe(result =>  {
          this.isLoading = false; 
          
        });  
    */
   const dialogRef = this.dialog.open( ChangeAdditionComponent, {
    width: '500px',
    disableClose: true,
    data: {
     addition: addition,
     displayMode: "NEW",
     storeId: this.storeId
    }
    
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result == true) {
      //alert ("refresh me");
      //this.getMenuInformation();
    } else {
      //alert ("Dont reresh me");
    }
  }); 
  }

  getCategories() {
    console.log("inside getCategories");
    this.isLoading = true; 
      this.adminService.getAllMenuCategories(this.storeId +"", this.mealType+"")
            .subscribe(result =>  {

              
              console.log("BEFORE");
              if (result.items != null && result.items.length > 0) {
                console.log("result.items :: " + result.items.length);
                this.CatagoriesList = result.items as MenuCategory[];
                console.log("catagoriesList :: " + this.CatagoriesList[0].categoryName);
              }
              this.isLoading = false; 
            });
    } 

    categoryFilter(event){
     // alert(this.CatagoriesList.length)
     this.isLoading = true; 
     this.adminService.getAllMenuItems(this.storeId , this.mealType)
     .subscribe(result =>  {
      this.isLoading = false; 
       if (result.items != null && result.items.length > 0) {

         this.MealTypeMenuList = result.items as MenuItem[];
         if ( event.value != 'ALL') {
          this.MealTypeMenuList = this.MealTypeMenuList.filter(
            val=>val.mealCategory.includes(event.value)
          )
         }
       }
      
     }); 
     
    }

    compare( a:MenuItem , b:MenuItem ) {
      if ( a.menuItemName < b.menuItemName ){
        return -1;
      }
      if ( a.menuItemName > b.menuItemName ){
        return 1;
      }
      return 0;
    }


    
}
