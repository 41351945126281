import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CustomerUpdateData } from '../DialogData';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {CONSTANTS} from '../constants';
import { Customer, Address, UpdateCustomerAttributesRequest, StoreSummary, CustomerAttributes } from '../customers';
import { FormGroup, FormBuilder} from  '@angular/forms';
import { Validators } from '@angular/forms';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-customer-change',
  templateUrl: './customer-change.component.html',
  styleUrls: ['./customer-change.component.css']
})

export class CustomerChangeComponent implements OnInit {

  customer:Customer;
  addressList:Array<Address>;
  addressId:number;
  address:Address;
  customerUpdateForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<CustomerChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerUpdateData,
    private adminService: AdminService,
    private formBuilder: FormBuilder, 
    private _snackBar: MatSnackBar) {
      this.customer = data.cust
      this.addressList = data.cust.customerAttributes.addressDetails;
      this.addressId = data.addressId;
      this.addressList.forEach(element => {
        if (element.id == this.addressId) {
          this.address = element;
        }
      });

      this.customerUpdateForm = this.formBuilder.group({
        tag: [this.address.tag, Validators.required], 
        city: [this.address.city, Validators.required], 
        addressOne: [this.address.fieldOne, Validators.required], 
        addressTwo: [this.address.fieldTwo, Validators.required], 
        state: [this.address.state, Validators.required], 
        zip: [this.address.zip, Validators.required], 
        });
    
     }

  ngOnInit(): void {
  }
 
  onSubmit() {
    this.address.city = this.customerUpdateForm.get('city')?.value;
    this.address.tag = this.customerUpdateForm.get('tag')?.value;
    this.address.fieldOne = this.customerUpdateForm.get('addressOne')?.value;
    this.address.fieldTwo = this.customerUpdateForm.get('addressTwo')?.value;
    this.address.state = this.customerUpdateForm.get('state')?.value;
    this.address.zip = this.customerUpdateForm.get('zip')?.value;
    this.addressList.forEach(element => {
      if (element.id == this.addressId) {
        element = this.address;
      }
    });
    
    var attr: CustomerAttributes = new CustomerAttributes();
    attr.addressDetails =  this.addressList;

    var request:UpdateCustomerAttributesRequest = new UpdateCustomerAttributesRequest();
    request.customerId = this.customer.customerId;
    request.storeSummary = new StoreSummary();
    request.customerAttributes = attr;

    this.adminService.getModifyCustomerAddressURL(request)
    .subscribe(result => {
       if (result.result == true) {
        this.dialogRef.close(true);
       } else {
       }
      }
      );
      
     
  }
  onCancel() {
    this.dialogRef.close(true);
  }
}
