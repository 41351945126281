import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import { Order } from '../order';
import {AdminService} from '../admin.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {CONSTANTS} from '../constants';
import { OrderService } from '../order.service';
import { DailyspecialsService } from '../dailyspecials.service';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css']
})
export class RouteComponent implements OnInit {

  constructor(private adminService:AdminService, public route: ActivatedRoute,
    private orderService:OrderService,
    private dailySplService:DailyspecialsService,
    private _snackBar: MatSnackBar) { }
  trackingId: string;
  deliveryAssociate: string;
  isLoading:boolean = false;
  orders : Order[];
  dataSource:any;
  
  displayedColumns: string[] =['Seq No', 'Order ID', 'Customer Name',  'Phone', 'Address', 'button',
   'Time taken', 'picture'];

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        this.trackingId = params.id;
        this.deliveryAssociate = params.associate;
      }
    );
    this.getOrdersByTrackingId();

  }
  getDayDiff(passedOrder:Order): number {
    const msInDay =  60 * 60 * 1000;
    const startDt = new Date(passedOrder.orderCreationTime);
    const endDt = new Date(passedOrder.orderClosedTime);
    var result = Math.round( 
      (Math.abs(endDt.getTime() - startDt.getTime()) / msInDay ) * 100
    )/100;
   
    return result;
   
  }

  getOrdersByTrackingId(){
    this.isLoading = true;
    this.adminService.getCustomerOrdersByTrackingId(this.trackingId, this.deliveryAssociate)
    
      .subscribe(result => {
        this.isLoading = false;
          this.orders = result.items as Order[];
          if (this.orders.length > 0) {
                      this.deliveryAssociate = this.orders[0].deliveryAssociate
           }
          this.dataSource = new MatTableDataSource(this.orders);
          this.orders.forEach(item => {
           item.processTime = this.getDayDiff(item).toString() ;
          });
          }
         
          
        );
  }

  callDelivered(customerOrderId) {
    this.isLoading = true;
    this.adminService.setOrderStatusDelivered(customerOrderId)
    
      .subscribe(result => {
        this.isLoading = false;
            if (result.result == true) {
              this.getOrdersByTrackingId();
            }
          }
        );
  }

  uploadImage(event, item:Order) {
    
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      var num:number = Math.round(file.size/1024);
      if (num > 10000) {
       // alert("File size exceed maximum allowed size of 2 MB");
        this.openSnackBar('File size exceed maximum allowed size of 10 MB','Failure');
        return;
      }
      var splitFileName:string[] = file.name.split(".");
      if (splitFileName?.length != 2) {
        this.openSnackBar('Invalid File','Failure');
        return;
      }

      var validFileExtn:boolean = false;
      var fileExtension:string = splitFileName[1];
      for(var extn of CONSTANTS.ALLOWED_FILE_TYPES ) {
        if (fileExtension == extn) {
          validFileExtn = true;
          break;
        }
      }
      if ( validFileExtn == false) {
        this.openSnackBar('Invalid File','Failure');
        //var str:string = 'Allowed File extensions are :';
        //str = str + CONSTANTS.ALLOWED_FILE_TYPES.toString();
       //this.openSnackBar(str , '');
        return;
      }

      
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append("item", item.customerOrderId + "");
      item.isLoading = true;
     
      this.orderService.uploadImage(formData).subscribe (
        result=> {
          item.isLoading = false;
         var imageUrl:string = result.payload["IMAGE_URL"];
          item.imageUrl = imageUrl;
          //  item.imageUrl = "./../../assets/Check-mark-1.jpeg";
        }
      );
      
    }
  }

  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

}
