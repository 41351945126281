<div style="width: 100%; overflow: hidden;background-color: white">
    <div class="relative">
        <br>
        <h2>iPerkz is convenient Mobile App to order food !!
        </h2>
        <img src="/assets/QR.png" width="200px" height="200px"/>
        <br><br>
       
        <p style="font-size: 17px; text-align: center;">Download <b>iPerkz App</b> and <br>receive <b>$5 OFF</b> on your first order
        </p>
   
        <br>
      
        <p style="text-align: left;font-size: 16px;padding-left: 20px;">
           <mat-icon matPrefix> thumb_up</mat-icon> &nbsp; <b>Benefits</b>
        </p>
        <p style="text-align: left;font-size: 16px;padding-left: 20px;">
            No Service Fee &#9733; Get Notifications &#9733; Track Expense &#9733;View Today's specials
         </p>
         <br>
         <p style="text-align: left;font-size: 16px;padding-left: 20px;">
            <mat-icon matPrefix>attach_money</mat-icon> &nbsp; <b>Payment Options</b>
         </p>
         <p style="text-align: left;font-size: 16px;padding-left: 20px;">
            Cash  &#9733;Card
             
          </p>

          <div  style="text-align: left;margin-left: 20px;">
            <a>
                <img src="/assets/apple-en.png" width="170px" height="50px" (click)="openAppStore()"/>
            </a>
            <a>
                <img src="/assets/google-en.png" width="170px" height="50px" (click)="openPlayStore()"/>    
            </a>
                <img src="/assets/facebook.png" width="50px" height="50px" 
        style="float: right;margin-right: 20px;" (click)="openFacebook()"/>
        </div>
    </div>
    <div class="carousel">
        <ngb-carousel>
            <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="/assets/image_1.png" alt="Angular Carousel 1" width="300px" height="600px">
            </div>
            <!--
            <div class="carousel-caption">
                <h3>Title Goes Here</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            -->
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="/assets/image_2.png" alt="Angular Carousel 2"  width="300px" height="600px">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="/assets/image_3.png" alt="Angular Carousel 2"  width="300px" height="600px">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="/assets/image_4.png" alt="Angular Carousel 2"  width="300px" height="600px">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="/assets/image_5.png" alt="Angular Carousel 2"  width="300px" height="600px">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>