<div *ngIf="hero">

    <h2>{{hero.specialInstructions }} Details</h2>
    <div><span>id: </span>{{hero.storeId}}</div>
    <div>
      <label>name:
        <input [(ngModel)]="hero.specialInstructions" placeholder="name"/>
      </label>
    </div>
  
  </div>