<br>
<br>
<h1> &nbsp;{{deliveryAssociate}}, {{trackingId}}</h1>
<div *ngIf="isLoading==true" style="text-align: center;margin-top:10px;">
  <img src="/assets/loading.gif" width="50px" height="50px" />
</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Seq No">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Delivery Order</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.deliverySeq}}</td>
  </ng-container>
  <ng-container matColumnDef="Order ID">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.customerOrderId}}</td>
  </ng-container>
  
  <ng-container matColumnDef="Customer Name">
    <th class="w-customer" mat-header-cell *matHeaderCellDef>Customer</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.firstName}}, {{element.lastName}}</td>
  </ng-container>
  
  <ng-container matColumnDef="Phone">
    <th mat-header-cell *matHeaderCellDef>Phone & Instructions</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.phone}} - {{element.deliveryInstructions}}</td>
  </ng-container>
  <ng-container matColumnDef="Address">
    <th mat-header-cell *matHeaderCellDef>Address</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.address}}</td>
  </ng-container>
 
  <ng-container matColumnDef="button">
    <th mat-header-cell *matHeaderCellDef>Completed At</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <span *ngIf="element.orderClosedTime != null" >{{element.orderClosedTime | date:'h:mm a':'UTC'}}</span>
    <button *ngIf="element.orderClosedTime == null" mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="button" (click)="callDelivered(element.customerOrderId)">Delivered</button>  
  </td>
  </ng-container>
  
  <ng-container matColumnDef="Time taken">
    <th mat-header-cell *matHeaderCellDef>Process Time</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
     {{ element.processTime}} hours</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>

  <ng-container matColumnDef="picture">
    <th mat-header-cell *matHeaderCellDef>Picture</th>
    <td mat-cell *matCellDef="let element">
      <input type="file" style="display: none" #file (change)="uploadImage($event, element)" />
      <div  *ngIf="element.isLoading">
        <button mat-button>
          <img src="assets/loading.gif"  width="35px" height="35px"/>
        </button>
      </div>
      <div *ngIf="!element.isLoading"  matTooltip="Click to Change Image">
          <button mat-button (click)="file.click()" >
            <img src="{{element.imageUrl}}"  width="35px" height="35px" />
          </button>
      </div>
    </td>
  </ng-container>
</table>
