<br>
<div>

    <div class="aligncenter">
       <img  class="center" src="../../assets/scooter-with-box.png"  width="150" height="130"/>
        <div >
            
            <form [formGroup]="loginForm"  (ngSubmit)="onSubmit()">
                <h1  class="center">Welcome to iPerkz</h1>
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput type='text' placeholder="Username" formControlName="username" 
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" required ng-valid> 
                    <button mat-icon-button matSuffix >
                        <mat-icon>account_box</mat-icon>
                       </button>
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline">
                    <mat-label >Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control
                    " [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                     required ng-valid>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                         <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                </mat-form-field>
                <br>
                <button mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary"  mat-raised-button type='submit'
                >
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Login&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>