<p></p>
<div style="padding-left: 20px;">
<form [formGroup]="expenseForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill">
      <mat-label >Expense Info</mat-label>
      <input matInput placeholder="Placeholder" formControlName="name" required ng-valid> 
    </mat-form-field>
    &nbsp;
    <mat-form-field appearance="fill">
        <mat-label >Description</mat-label>
        <input matInput placeholder="Placeholder" formControlName="description" required ng-valid> 
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Select Type</mat-label>
        <mat-select  formControlName="type" required >
          <mat-option  *ngFor="let expType of expenseTypes" value="{{expType.id}}">{{expType.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Select Frequency</mat-label>
        <mat-select  formControlName="frequency" required >
          <mat-option  *ngFor="let expFreq of expenseFrequency" value="{{expFreq.id}}">{{expFreq.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Amount</mat-label>
        <input matInput type="number" class="example-right-align"  formControlName="amount"  required>
        <span matPrefix>$&nbsp;</span>
      </mat-form-field>
    <br>
    
  <button mat-raised-button color="primary"  type="submit">Create</button>
  </form> 
</div>  
<div style="padding-left: 20px;padding-right: 10px; padding-top: 10px;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
        
          <ng-container matColumnDef="name">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element">{{element.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="desc">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{element.description}}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>type</th>
            <td mat-cell *matCellDef="let element">{{element.expenseTypeString}}
            </td>
          </ng-container>
          <ng-container matColumnDef="frequency">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>frequency</th>
            <td mat-cell *matCellDef="let element">{{element.frequencyString}}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th class="w-order-no" mat-header-cell *matHeaderCellDef>$</th>
            <td mat-cell *matCellDef="let element">{{element.amount | currency:'USD':'symbol':'1.2-2'}}
            </td>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
      </table>
  </div> 