import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar,MatSnackBarConfig} from '@angular/material/snack-bar';
import { AdminService } from '../admin.service';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EmailData } from '../DialogData';
import { OrderService } from '../order.service';
import { FormGroup, FormBuilder} from  '@angular/forms';
import { Validators } from '@angular/forms';
import { EmailMessage } from '../_models/menu-changes';
import { PrebookOrder } from '../order';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  emailForm: FormGroup;
  preOrders:Array<PrebookOrder> = new Array();
  selectedEmailIds:Array<string> = new Array();
  constructor(public dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailData, private orderService:OrderService,
    private formBuilder: FormBuilder, private adminService:AdminService, private _snackBar: MatSnackBar) { 
      this.emailForm = this.formBuilder.group({
        subject: ['', Validators.required],  
        message: ['', Validators.required]
        });

       this.preOrders = data.preOrders;
    }

  ngOnInit(): void {
  }
  onSubmit() {
    //this.dialogRef.close(true);
    var message = new EmailMessage();
    message.subject = this.emailForm?.get('subject').value;
    message.message = this.emailForm?.get('message').value;
   // message.customerIds = [];
    //message.emailIds = [];
    this.selectedEmailIds = new Array();
    this.preOrders.forEach(element => {
      this.selectedEmailIds.push(element.email);
    });
    if (this.preOrders.length == 1) {
      message.firstname = this.preOrders[0].firstname;
    }
    message.emailIds = this.selectedEmailIds;
    this.orderService.sendCustomerEmail(message)
    .subscribe(result => {
       if (result.result == true) {
        this.dialogRef.close(true);
       } else {
       }
      }
      );
  }

  closeWindow(){
    this.dialogRef.close(true);
  }

}
