<div class="banner">
    <h2 >Update Original Price</h2>
</div>

<div style="width: 100%; margin-left: 20px;" >
    <h4>{{reportRow.menuName}} - {{reportRow.menuCode}}</h4>
    
    <form [formGroup]="reportDataUpdateForm" >
        <mat-form-field appearance="fill">
            <mat-label >Original Price</mat-label>
            <input matInput type="number" class="example-right-align"  formControlName="orgPrice" required>
            <span matPrefix>$&nbsp;</span>
        </mat-form-field> &nbsp;
        <mat-form-field appearance="fill">
            <mat-label>Change for Date</mat-label>
            <input matInput [matDatepicker]="changefordate" formControlName="changeForDate" value = "reportRow.deliveryScheduledOn" required >
            <mat-datepicker-toggle matSuffix [for]="changefordate"></mat-datepicker-toggle>
            <mat-datepicker #changefordate></mat-datepicker>
            
        </mat-form-field>     
        <mat-form-field appearance="fill">
             <input matInput style="height: 3px;" class="example-right-align"  disabled="true" required>
             <mat-checkbox matInput formControlName="changeMasterRecord">
                Update Master Record
            </mat-checkbox>
        </mat-form-field> &nbsp;
    <br>
    <button mat-raised-button color="primary" (click)="onSubmit()">Update</button>&nbsp;
    <button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
    </form>
</div>