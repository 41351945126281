import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder} from  '@angular/forms';
import {OrderService} from '../order.service';
import { MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {MenuUpdateData} from '../DialogData';
import { AdminService } from '../admin.service';
import { MenuItem, MenuItemAdditions } from '../_models/menu-changes';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-add-addition',
  templateUrl: './add-addition.component.html',
  styleUrls: ['./add-addition.component.css']
})
export class AddAdditionComponent implements OnInit {
  addAdditionsForm: FormGroup;
  addition:MenuItemAdditions;
  menu:MenuItem;
  storeId:number;
  selectionTypeList = ['CHECKBOX', 'RADIO', 'SELECTED', 'NOT SELECTED'];
  constructor(public dialogRef: MatDialogRef<AddAdditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MenuUpdateData, private orderService:OrderService,
    private formBuilder: FormBuilder, private adminService:AdminService, private _snackBar: MatSnackBar) {
      this.menu = data.menu;
      this.storeId = data.storeId;

      this.createAdditionsAddForm()
    }


    createAdditionsAddForm(){
     
      this.addAdditionsForm = this.formBuilder.group({
        additionsName: ['', Validators.required],
        description:  ['', Validators.required],
        price: ['', Validators.required],
        parent: ['', Validators.required],
        selectionType: ['', Validators.required]
        });
    
     }

  ngOnInit(): void {
  }

  submit(){
    var addtn: MenuItemAdditions = new MenuItemAdditions();
    addtn.menuId = this.menu.menuId
    addtn.name =  this.addAdditionsForm.get('additionsName').value;
    addtn.description = this.addAdditionsForm.get('description').value;
    addtn.price = this.addAdditionsForm.get('price').value;
    addtn.selectionType = this.addAdditionsForm.get('selectionType').value;
    addtn.parentId = this.addAdditionsForm.get('parent').value
    addtn.storeId = this.storeId;

   // alert(addtn.selectionType );
   this.orderService.createAddition(addtn)
   .subscribe(result => {
      if (result.result == true) {
       this.dialogRef.close(true);
      } else {
      }
     }
     );

  }
  closeWindow(){
    this.dialogRef.close(false);   
  }

  // 
}
