import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../DialogData';
import {Order, CustomerOrderMenu} from '../order';
import {OrderService} from '../order.service';
import {AuthenticationService} from '../authentication.service';
import { User } from '../_models/user';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {
  isLoading:boolean = false;
  order:Order;
  showError:boolean = false;
  errorMessage:string;
  user:User;
  //result: false;
  result1: number;
  constructor(public dialogRef: MatDialogRef<OrderConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private orderService:OrderService, 
    private authService: AuthenticationService, public dialog: MatDialog,) {
      //this.authService.currentUser.subscribe(x => this.user = x);
      this.user = authService.currentUserValue
    //  alert(this.user.storeType)
     }


  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => this.user = x);
 //   alert(this.user.firstName)
   this.getOrderDetails(this.data.storeId, this.data.orderId);

  }

  getOrderDetails(storeId:number, orderId:number): void {
    this.isLoading = true;
  
    this.orderService.getOrderDetails(storeId, orderId)
      .subscribe(result => {
            this.isLoading = false;
            var orderArr:Order[] = result.payload["CUSTOMER_ORDER"];
            if (orderArr?.length > 0 ) {
              this.order = orderArr[0];
            }
            }
          );
  }

  rejectOrder() {
    var  bodyMessage:Array<string> = new Array();

    bodyMessage.push("Reason for Rejection");
     const dialogRef = this.dialog.open(DialogComponent, {
       width: '350px',
       data: {
         titleIconText:"pan_tool",
         title: "Reject?", message: bodyMessage, 
         yesButtonMessage:"Confirm",
         noButtonMessage:"No", 
         result:true,
         showTextArea:true,
         usermessage:'test'
       }
     });
 
     dialogRef.afterClosed().subscribe(result => {
       console.log('The dialog was closed' + result);
      
       if (result.result == true) {
        if (result.userMessage) {

        } else {
          result.userMessage = "None";
        }
        this.orderService.markRejected( this.order.customerOrderId, result.userMessage)
        .subscribe(returnValue => {
          if (returnValue.messageCode == 2) {
             alert (returnValue.message);
          }
          this.dialogRef.close(false);
           }
         );
      }
       
     });
     
  }

  playAudio(){
    let audio = new Audio();
    audio.src = "../assets/click.mp3";
    audio.load();
    audio.play();
  }

  confirm (duration:number):void {
  //  
  this.playAudio();
      this.showError = false;
  
      this.orderService.confirmOrder(this.data.orderId, duration)
      .subscribe(result => {
          //this.response = result;
          //this.getOrders('ALL');
            if (result.result == false) {
              this.showError = true;
             
              this.errorMessage = result.message;
              //alert(this.errorMessage + "ff");
            } else {
              this.dialogRef.close(false);
            }
          }
        );


        
  }

  close () :void {
    this.dialogRef.close(false);   
  }
}
