import { Component, OnInit, Inject } from '@angular/core';
import { OrderUpdateData } from '../DialogData';
import { Order } from '../order';
import { OrderService } from '../order.service';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-special-instructions',
  templateUrl: './special-instructions.component.html',
  styleUrls: ['./special-instructions.component.css']
})
export class SpecialInstructionsComponent implements OnInit {
  customerOrder:Order;
  deliveryInstructions:string = "";
  constructor(public dialogRef: MatDialogRef<SpecialInstructionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderUpdateData, private orderService:OrderService) { 
      this.customerOrder = data.orderItem;
    }

  ngOnInit(): void {
  }

  closeWindow() {
    this.dialogRef.close(false); 
  }
  updateInstructions(){
    this.orderService.updateDeliveryInstructions(this.customerOrder.customerOrderId, this.deliveryInstructions).subscribe(result => {
      this.dialogRef.close(true);
      }
      );
  }


}
