import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS }
 from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';

import { User } from '../_models/user';

const users: User[] = [
    { id: 1, username: 'wok', password: 'wok123',  storename: 'Wok Republic Cafe', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625, 
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Store-Owner/wok.png', storeType:1 },
    { id: 2, username: 'villagecoffee', password: 'village123',  storename: 'Village Coffee', firstName: 'Test', lastName: 'User', 
                chargeMode: 'CUSTOMER', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Village_Coffee_Maplewood/village_coffee.png', storeType:1  },
    { id: 3, username: 'krishna', password: 'krishna123',  storename: 'Krishna Flowers', firstName: 'Test', lastName: 'User', 
                chargeMode: 'CUSTOMER', chargeRate:2.9, transactionFee:0.3, salesTaxes: 7.0,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Village_Coffee_Maplewood/village_coffee.png', storeType:2  },
    { id: 4, username: 'cnb', password: 'cnb123',  storename: 'Cait & Abby', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Village_Coffee_Maplewood/village_coffee.png', storeType:1  },
    { id: 5, username: 'sankalp', password: 'sankalp123',  storename: 'Sankalp', firstName: 'Test', lastName: 'User', 
                chargeMode: 'CUSTOMER', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Sankalp_Images/Sankalp+Logo.PNG', storeType:1  },
    { id: 6, username: 'swagath', password: 'swagath123',  storename: 'Swagath Gourmet', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Swagath_Images/Swagath+Logo.PNG' , storeType:1 },
    { id: 7, username: 'hotbreads', password: 'hotbreads123',  storename: 'Hot Breads', firstName: 'Test', lastName: 'User', 
                chargeMode: 'CUSTOMER', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Swagath_Images/Swagath+Logo.PNG', storeType:1  },
    { id: 8, username: 'mithaas', password: 'mithaas123',  storename: 'Mithaas', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Mithaas/Mithaas_logo1.png', storeType:1  },
    { id: 10, username: 'pakvaan', password: 'pakvaan123',  storename: 'Pakvaan', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Pakvaan_Images/Pakvaan_Logo.png' , storeType:1 },
    { id: 0, username: 'admin', password: 'adminx123',  storename: 'Admin', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Pakvaan_Images/Pakvaan_Logo.png', storeType:1 },
    { id: 13, username: 'honest.e', password: 'honest123',  storename: 'Honest Edison', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Honest_Images/honest-logo.jpg' , storeType:1 },
    { id: 14, username: 'honest.p', password: 'honest123',  storename: 'Honest Parsippany', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Honest_Images/honest-logo.jpg', storeType:1  },
    { id: 15, username: 'mithaas.p', password: 'mithaas123',  storename: 'Mithaas Piscataway', firstName: 'Test', lastName: 'User', 
                chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
               logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Mithaas/Mithaas_logo1.png' , storeType:1 },
    { id: 16, username: 'mithaas.j', password: 'mithaas123',  storename: 'Mithaas Jersey City', firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Mithaas/Mithaas_logo1.png', storeType:1  },
    { id: 17, username: 'jagdish', password: 'jagdish123',  storename: 'Jagdish', firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Jagdish_Images/Jagdish_Logo.PNG', storeType:1  },
    { id: 18, username: 'kabaab', password: 'kabaab123',  storename: 'Kabaab Factory', firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Kabab_Factory+Images/Kabab_Factory_Logo.PNG' , storeType:1 },
    { id: 19, username: 'themint', password: 'themint123',  storename: 'The Mint', firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Mint_Images/Mint_Logo.png' , storeType:1 },
    { id: 21, username: 'mithaas.nb', password: 'mithaas123',  storename: 'Mithaas North Brunswick', 
            firstName: 'Test', lastName: 'User', 
                              chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                              logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Mithaas/Mithaas_logo1.png', storeType:1  },
    { id: 22, username: 'jerseybagels', password: 'jerseybagels123',  storename: 'Jersey Bagels & Subs', 
                 firstName: 'Test', lastName: 'User', 
                   chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                  logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Jersey_Bagels/Jersy_Bagels.PNG' , storeType:1 },                        
    { id: 24, username: 'sigiri', password: 'sigiri123',  storename: 'Sigiri', 
                  firstName: 'Test', lastName: 'User', 
                    chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                   logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Sigiri_Image.png' , storeType:1 },
    { id: 25, username: 'river', password: 'river123',  storename: 'G4U', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 0.0,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/Jersey_Bagels/Jersy_Bagels.PNG' , storeType:4 },
    { id: 26, username: 'singas', password: 'singas123',  storename: 'Singas Famous Pizza', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },

    { id: 27, username: 'bikanervala.e', password: 'bikanervala123',  storename: 'Bikanervala Edison', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },
    { id: 28, username: 'bikanervala.j', password: 'bikanervala123',  storename: 'Bikanervala Jersey City', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },
    { id: 29, username: 'bikanervala.f', password: 'bikanervala123',  storename: 'Bikanervala Franklin Park', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },
    { id: 30, username: 'fifteen38', password: 'fifteen38123',  storename: 'Fifteen 38-Bar& Resturant', 
                 firstName: 'Test', lastName: 'User', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },
    { id: 100, username: 'sukhadia', password: 'sukhadia@app',  storename: 'Mangoes Investment', 
                 firstName: 'Sukhadia', lastName: '', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:1 },
    { id: 101, username: 'packing', password: 'better',  storename: 'Todays', 
                 firstName: '', lastName: '', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:25 },
    { id: 102, username: 'suma', password: 'suma!2Nathan',  storename: 'Todays', 
                 firstName: '', lastName: '', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:25 },
    { id: 103, username: 'report', password: 'report123',  storename: 'Report', 
                 firstName: 'iPerkz', lastName: 'Report', 
                 chargeMode: 'STORE', chargeRate:2.9, transactionFee:0.3, salesTaxes: 6.625,
                 logo:'https://perkz.s3.us-east-2.amazonaws.com/Perkz-Images/singa_logo.png' , storeType:25 }
       
                                                     ];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/users/authenticate') && method === 'POST':
                    return authenticate();
                case url.endsWith('/users') && method === 'GET':
                    return getUsers();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }    
        }

        // route functions

        function authenticate() {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) return error('Username or password is incorrect');
            return ok({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                storename: user.storename,
                storeType: user.storeType,
                logo: user.logo
            })
        }

        function getUsers() {
            if (!isLoggedIn()) return unauthorized();
            return ok(users);
        }

        // helper functions

        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }))
        }

        function error(message) {
            return throwError({ error: { message } });
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === `Basic ${window.btoa('test:test')}`;
        }
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};