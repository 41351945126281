<h1 mat-dialog-title>
  
  <div *ngIf="data.titleIconText=='assets/ready.jpg'">
    <img src="assets/ready.jpg" width="30px" height="30px" />&nbsp;&nbsp;{{data.title}}
  </div>
  <div *ngIf="data.titleIconText!='assets/ready.jpg'">
    <button mat-mini-fab color="primary">
      <mat-icon matPrefix color="warn" >{{data.titleIconText}}</mat-icon>&nbsp;&nbsp;
   </button>
   {{data.title}}
  </div>
 </h1>
<div mat-dialog-content *ngFor="let item of data.message">
  <p>{{item}}</p>
 </div>
 <div *ngIf="data.showTextArea==true" >
  <mat-form-field [style.fontSize]="14">
    <mat-label>Reason</mat-label>
    <textarea matInput 
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              [(ngModel)]="myTextarea"></textarea>
  </mat-form-field>
 </div>
 <div mat-dialog-actions>
  <button  mat-raised-button color="warn" (click)="onNoClick()">{{data.noButtonMessage}}</button>&nbsp;
  <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial>
    <div *ngIf="data.titleIconText!='assets/ready.jpg'">
      <mat-icon matPrefix >{{data.titleIconText}}</mat-icon>&nbsp;{{data.yesButtonMessage}}
    </div>
    <div *ngIf="data.titleIconText =='assets/ready.jpg'">
      {{data.yesButtonMessage}}
    </div>
  </button>  
</div>