import { Component } from '@angular/core';
import { SubscriptionServiceService } from './subscription-service.service';
import { Router } from '@angular/router';

import { environment } from './../environments/environment';
import {User} from './_models/user'
import { AuthenticationService } from './authentication.service'
import { NgZone } from '@angular/core';  
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import '@firebase/auth';
import {OrderConfirmationComponent} from './order-confirmation/order-confirmation.component';

import {MatDialog} from '@angular/material/dialog';

declare function registerForWebNotifications(storeId): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'perkz-portal';
  build = environment.version;
  currentUser: User;
  result: false;

  ngOnInit() {  
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (orderId, storeId) => this.angularFunctionCalled(orderId, storeId), };  
  } 

  constructor(
    private subscriptionService:SubscriptionServiceService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ngZone: NgZone,
    public dialog: MatDialog,
  
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.registerForWebNfns();
  }

  logout() {
    this.authenticationService.logout();
        this.router.navigate(['/login']);
    //alert("called");
    /*
    this.authenticationService.updateStoreStatus(this.currentUser.id, 0).subscribe(
      result => {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
      }
    )
    */
    
  }

  registerForWebNfns() {
    try  {   
      if (this.currentUser.id > 0) {
        registerForWebNotifications(this.currentUser.id);
      } else {
    //    alert (this.currentUser.id);
      
      }
    } catch (e){
   //     alert ("ignore token");
    }

  }

  playAudio(){
    let audio = new Audio();
    audio.src = "../assets/click.mp3";
    audio.load();
    audio.play();
  }

  angularFunctionCalled(orderId, storeId) {  
    this.playAudio();
    var  bodyMessage:Array<string> = new Array();
    
    bodyMessage.push("test");
     const dialogRef = this.dialog.open(OrderConfirmationComponent, {
       width: '700px',
       disableClose: true,
       data: {
         titleIconText:"assets/ready.jpg",
         title: "Ready for Pickup?", message: bodyMessage, 
         yesButtonMessage:"Ready",
         noButtonMessage:"No", 
         result:this.result,
         storeId: storeId,
         orderId: orderId
       }
       
     });

     dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.router.navigate(['orders']);
    });
    //
  }  


}
