<p>customers works!</p>

<form [formGroup]="customSearchForm"  (ngSubmit)="onCustomSearch()">
    <mat-form-field appearance="fill">
      <mat-label>Select Search By</mat-label>
      <mat-select  formControlName="searchById" required >
        <mat-option  *ngFor="let searchBy of searchByItems"  value="{{searchBy}}" >{{searchBy}}</mat-option>
      </mat-select>
   </mat-form-field>
   &nbsp;
   <mat-form-field appearance="fill">
    <mat-label>Select Search Text</mat-label>
    <input matInput  formControlName="searchByValue" required>
 </mat-form-field>
 &nbsp;
 <button mat-raised-button color="primary" style="height:50px;vertical-align: top;" type="submit">Search</button> &nbsp;
</form> 

<div class="bg">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Customer Id">
        <th class="w-order-no" mat-header-cell *matHeaderCellDef>Customer Id</th>
        <td mat-cell *matCellDef="let element" 
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.customerId}}</td>
      </ng-container>
      <ng-container matColumnDef="Customer Name">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.firstName}}, {{element.lastName}}</td>
      </ng-container>
      <ng-container matColumnDef="Phone">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.phoneNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="Email">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        {{element.email}}</td>
      </ng-container>
      <ng-container matColumnDef="Address">
        <th class="w-customer" mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element"
        [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
        <a (click)="showUpdateScreen(element,addressItem.id)"  *ngFor="let addressItem of element.customerAttributes.addressDetails"  >
            {{addressItem.id}} : {{addressItem.tag}} : {{addressItem.fieldOne}}, {{addressItem.fieldTwo}}, {{addressItem.city}}, {{addressItem.state}}, {{addressItem.zip}} <p></p>
        </a> 
      
    </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
    </table>
    </div>
