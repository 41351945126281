
<div >
  <h1 class="alignleft">Today's Orders  
    <span *ngIf="currentView == 'CURRENT'" style="color: black;">[ New & In Progress ]</span>
    <span *ngIf="currentView == 'ALL'" style="color: black;">[ All (except Cancelled) ]</span>
    <span *ngIf="currentView == 'DELETED'" style="color: black;">[ Cancelled ]</span>


  </h1>

  <span class="alignright" ><button mat-raised-button color="primary" (click)="refreshMe()" matTooltip="Refresh"><mat-icon>refresh</mat-icon>Refresh</button></span>
  <span class="alignright" *ngIf="currentView == 'DELETED'"><button mat-raised-button color="accent" (click)="refresh('DELETED')" matTooltip="Refresh">Cancelled</button></span>
  <span class="alignright" *ngIf="currentView != 'DELETED'"><button mat-raised-button color="primary" (click)="refresh('DELETED')" matTooltip="Refresh">Cancelled</button></span>
  
  <span class="alignright" *ngIf="currentView == 'ALL'"><button mat-raised-button color="accent" (click)="refresh('ALL')" matTooltip="Refresh">All</button></span>
  <span class="alignright" *ngIf="currentView != 'ALL'"><button mat-raised-button color="primary" (click)="refresh('ALL')" matTooltip="Refresh">All</button></span>
  
 
  <span class="alignright" *ngIf="currentView == 'FUTURE'"><button mat-raised-button color="accent" (click)="refresh('FUTURE')" matTooltip="Refresh">Future Orders</button></span>
  <span class="alignright" *ngIf="currentView != 'FUTURE'"><button mat-raised-button color="primary" (click)="refresh('FUTURE')" matTooltip="Refresh">Future Orders</button></span>
  <span class="alignright"  *ngIf="currentView == 'CURRENT'"><button mat-raised-button color="accent" (click)="refresh('CURRENT')" matTooltip="Refresh">Today's Orders</button></span>
  <span class="alignright"  *ngIf="currentView != 'CURRENT'"><button mat-raised-button color="primary" (click)="refresh('CURRENT')" matTooltip="Refresh">Today's Orders</button></span>

</div>


<div>
   <mat-form-field appearance="fill">
    <mat-label >Search</mat-label>
    <input matInput placeholder="Search" id="filter" (keyup)="applyFilter($event)">
    <mat-icon matPrefix>search</mat-icon>
    
  </mat-form-field>
</div>
<div class="bg">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Order ID">
    <th class="w-order-no" mat-header-cell *matHeaderCellDef>Order#</th>
    <td mat-cell *matCellDef="let element" 
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.customerOrderId}}</td>
  </ng-container>
  
  <ng-container matColumnDef="Customer Name">
    <th class="w-customer" mat-header-cell *matHeaderCellDef>Customer</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.firstName}}, {{element.lastName}}</td>
  </ng-container>

  <ng-container matColumnDef="Order Items">
    <th class="w-order-item" mat-header-cell *matHeaderCellDef>Order Items</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <li *ngFor="let menuItem of element.menuList">
      <b>(#{{menuItem.count}})</b> - {{menuItem.menuCode}} -{{menuItem.menuItemName}} <span *ngIf="menuItem.additionsNames?.length >0">with '{{menuItem.additionsNames}}'</span><b> Instructions:</b> 
      <span *ngIf="menuItem.specialInstructions?.length > 0">{{menuItem.specialInstructions}}</span> <span *ngIf="menuItem.specialInstructions?.length == 0">None</span>- {{menuItem.salePrice | currency:'USD':'symbol':'1.2-2'}}
    </li>
    </td>
  </ng-container>
  <ng-container matColumnDef="Finish Date">
    <th class="w-order-item" mat-header-cell *matHeaderCellDef>Pickup/Delivery Date</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.address.trim()}}<br>
    {{element.requestedDeliveryDateString}}
    </td>
  </ng-container>
  <ng-container matColumnDef="Status">
    <th class="w-status" mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <span *ngIf="element.orderStatus == 'PLACED'">New</span>
    <span *ngIf="element.orderStatus == 'STARTED'">In Progress</span>
    <span *ngIf="element.orderStatus == 'COMPLETED'">Completed</span>
    <span *ngIf="element.orderStatus == 'DELETED'">Cancelled</span>
  </td>
  </ng-container>
  <ng-container matColumnDef="Total Amt">
    <th class="w-order-amt" mat-header-cell *matHeaderCellDef>Total Amt</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    Sale Amt&nbsp;&nbsp;&nbsp;: {{element.totalSalePrice | currency:'USD':'symbol':'1.2-2'}}
  <br>
    Taxes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{element.tax | currency:'USD':'symbol':'1.2-2'}}
  
    <span [ngClass]="{'hiddenText': element.chargeMode =='STORE'}"><br>Card Fee&nbsp;&nbsp;&nbsp;: {{element.transactionFee | currency:'USD':'symbol':'1.2-2'}} </span>
    <br>
  Discount : {{ element.discount | currency:'USD':'symbol':'1.2-2'}}
  <br>
  <span><b>Tip :</b> {{ element?.tipAmount | currency:'USD':'symbol':'1.2-2'}}</span>
  <br>
  <span><b>Delivery Charge :</b> {{ element?.deliveryAmount | currency:'USD':'symbol':'1.2-2'}}</span>
  <br>
  <span *ngIf="element.paymentMode == 'CASH'" class="focusedText"> Customer Pays:
    {{ (element.amountChargedToCustomer  -  element.discount ) | currency:'USD':'symbol':'1.2-2'}} </span>
  
  <span *ngIf="element.paymentMode != 'CASH'" class="focusedText"> Pre-paid:  
    <span *ngIf="element?.chargeMode =='STORE'">
    {{((element?.totalSalePrice + element?.tax + element?.tipAmount) -  element.discount +  element?.deliveryAmount) | currency:'USD':'symbol':'1.2-2'}} </span>
  <span  *ngIf="element?.chargeMode =='CUSTOMER'">
   {{ ((element?.totalSalePrice + element?.tax + element?.tipAmount) -  element.discount  + element?.transactionFee + element?.deliveryAmount) | currency:'USD':'symbol':'1.2-2'}} 
    </span>
  </span>
  
</td>
  </ng-container>
  <ng-container matColumnDef="Payment Mode">
    <th mat-header-cell *matHeaderCellDef>Cash/Card</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <span *ngIf="element.paymentMode == 'CASH'" class="focusedText"> {{element.paymentMode}}</span>
    <span *ngIf="element.paymentMode != 'CASH'"> {{element.paymentMode}}</span>
   </td>
  </ng-container>

  <ng-container matColumnDef="Order Type">
    <th mat-header-cell *matHeaderCellDef>Order Type</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
     <span *ngIf="element.takeOut == 0" class="focusedText">Dine In #  {{element.tableNumber}}</span>
    <span *ngIf="element.takeOut == 1">Take Out</span>
    <span *ngIf="element.takeOut == 2">Delivery</span>
   </td>
  </ng-container>
  <ng-container matColumnDef="Time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.orderCreationTime | date:'h:mm a':'UTC'}}</td>
  </ng-container>
  <ng-container matColumnDef="Phone">
    <th mat-header-cell *matHeaderCellDef>Phone</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.phone}}</td>
  </ng-container>
  <ng-container matColumnDef="Email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    {{element.email}}</td>
  </ng-container>
  <ng-container matColumnDef="accept">
    <th mat-header-cell *matHeaderCellDef>Accept</th>
    
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <div *ngIf="element.orderStatus == 'PLACED'">
    <button mat-mini-fab color="primary"  (click)="markConfirmed(element)" matTooltip="Confirm Order">
      <mat-icon matPrefix >done</mat-icon>
    </button>
   </div>
   <div *ngIf="element.orderStatus != 'PLACED'"> 
    <button mat-mini-fab color="primary" disabled (click)="markConfirmed(element)" matTooltip="Confirm Order">
      <mat-icon matPrefix >done</mat-icon>
    </button>
   </div>
   </td>
  </ng-container>
  <ng-container matColumnDef="mark_confirm">
    <th mat-header-cell *matHeaderCellDef>Ready
      for Pickup</th>
    
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <div *ngIf="element.orderStatus == 'PLACED' || element.orderStatus=='STARTED'">
    <button mat-mini-fab color="primary"  (click)="markReadyForPickup(element)" matTooltip="Ready for Pickup">
      <mat-icon matPrefix >done</mat-icon>
    </button>
   </div>
    <div *ngIf="element.orderStatus=='COMPLETED'">
      <button mat-mini-fab disabled matTooltip="Stop?">
        <mat-icon matPrefix >done</mat-icon>
      </button>
     </div>
   </td>
  </ng-container>
  
  
  <ng-container matColumnDef="Mark Processed">
    <th mat-header-cell *matHeaderCellDef>Confirm?</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
      <div *ngIf="element.orderStatus == 'PLACED'">
      <button mat-mini-fab  (click)="markConfirmed(element)" matTooltip="Confirm?">
        <mat-icon matPrefix >thumb_down</mat-icon>
       
      </button>
     </div>
     <div *ngIf="element.orderStatus=='STARTED'">
      <button mat-mini-fab color="primary" (click)="markOnHold(element)" matTooltip="Stop?">
        <mat-icon matPrefix >thumb_up</mat-icon>
      </button>
     </div>
     <div *ngIf="element.orderStatus=='COMPLETED'">
      <button mat-mini-fab disabled matTooltip="Stop?">
        <mat-icon matPrefix >thumb_up</mat-icon>
      </button>
     </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="Delete">
    <th mat-header-cell *matHeaderCellDef>Cancel</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;"
    [ngClass]="{'startedBackground': element.orderStatus=='STARTED', 'placedBackground' : element.orderStatus=='PLACED' ,'completedBackground' : element.orderStatus=='COMPLETED'}">
    <div *ngIf="element.orderStatus !='COMPLETED' && currentView !='DELETED'">
      <button mat-mini-fab color="warn" (click)="delete(element)" matTooltip="Delete">
        <mat-icon>cancel_presentation</mat-icon>
      </button>
    </div>
    <div *ngIf="element.orderStatus =='COMPLETED'">
      <button mat-mini-fab disabled (click)="delete(element)" matTooltip="Delete">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </div>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;columns: displayedColumns"></tr>
</table>
<div class="information" *ngIf="!isLoading && heros?.length == 0">
  <mat-icon >info</mat-icon>
   No Orders
 </div>
<div class="loading-icon-loc" *ngIf="isLoading">
  <img src="assets/loading.gif"  width="70px" height="70px"/>
</div>

</div>