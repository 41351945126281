import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrebookOrderReportItem } from '../order';
import { ReportUpdateData } from '../DialogData';
import { OrderService } from '../order.service';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReportDataUpdate } from '../OrderReportCriteria';

@Component({
  selector: 'app-sale-price-update',
  templateUrl: './sale-price-update.component.html',
  styleUrls: ['./sale-price-update.component.css']
})
export class SalePriceUpdateComponent implements OnInit {
  
  salePriceUpdateForm: FormGroup;
  reportRow:PrebookOrderReportItem;

  constructor(private formBuilder:FormBuilder,public dialogRef: MatDialogRef<SalePriceUpdateComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: ReportUpdateData, 
    private orderService:OrderService) {
      this.reportRow = data.preBookItem;
      this.createSalePriceUpdateForm();
     // 
     }

  createSalePriceUpdateForm(){
    var date:Date = new Date();
   /*
    var items = dateText?.split('-');
    var formattedDate = items[1] + '/'+items[2]+ '/' + items[0];
   */
     this.salePriceUpdateForm = this.formBuilder.group({
         salePrice: [this.reportRow.salePrice,  Validators.required]
     });
   }

  ngOnInit(): void {
  }

  onSubmit() {
    var updateItem:ReportDataUpdate = new ReportDataUpdate();
     updateItem.menuId = this.reportRow.menuId;
     updateItem.salePrice = this.salePriceUpdateForm.get('salePrice').value;
    this.orderService.updateSalePrice(updateItem).subscribe (
        result=> {
          this.dialogRef.close(true);   
        }
      );
  }

  closeWindow(){
    this.dialogRef.close(false);   
  }

}
