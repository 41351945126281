import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RefundData } from '../DialogData';
import { FormGroup,FormControl, FormBuilder} from  '@angular/forms';
import {MatSnackBar,MatSnackBarConfig} from '@angular/material/snack-bar';
import { CustomerOrderChanges, CustomerOrderMenu, Order } from '../order';
import { Validators } from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatRadioChange} from '@angular/material/radio'
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {CONSTANTS} from '../constants';
import { exit } from 'process';
import { AdminService } from '../admin.service';
import { AuthenticationService } from '../authentication.service';
import { User } from '../_models/user';

@Component({
  selector: 'app-refund-component',
  templateUrl: './refund-component.component.html',
  styleUrls: ['./refund-component.component.css']
})
export class RefundComponentComponent implements OnInit {
  subject:string = "Changes to your Order #";
  message:string = "message";
  order:Order;
  messageForm: FormGroup;
  dataSource:any;
  isLoading:boolean = false;
  refundAmount:number = 0.0 ;
  displayedColumns: string[] =[ 'select' ,  'Order ID',  'Sale Price', 'count','action','message', 'price'
  ];
  selection = new SelectionModel<CustomerOrderMenu>(true, []);
  userType:string = 'Admin';

 changesArr: Array<CustomerOrderMenu> = new Array();
 user:User;

  constructor(public dialogRef: MatDialogRef<RefundComponentComponent>, private adminService:AdminService,
    @Inject(MAT_DIALOG_DATA) public data: RefundData, 
    private formBuilder: FormBuilder, private _snackBar: MatSnackBar, private authService: AuthenticationService) {
      this.messageForm = this.formBuilder.group({
        subject: ['', Validators.required],  
        message: ['', Validators.required],
        refund: [{value: '0.0', disabled: true}, Validators.required]
        });
        this.authService.currentUser.subscribe(x => this.user = x);
        if (this.user.id == 102) {
          this.userType = "Admin";
        } else if (this.user.id == 101) {
          this.userType = "Regular";
        }
      this.order = data.order;
      /*
      this.order.menuList.forEach(element => {
       // Number(this.refundAmount.toFixed(2))
        element.refundAmt = (element.count * element.salePrice).toFixed(2);
      });
      */
      this.dataSource = new MatTableDataSource(this.order.menuList);
        this.processDynamicChanges();
      /*
      this.subject =  "Changes to your Order #" + this.order.customerOrderId;
      this.message = this.getRefundAmount();
      this.message = this.message + "Followind changes were made to your Order \n";
      this.message = this.message + this.generateMessageText();
          */
     }

  ngOnInit(): void {
  }


  onPriceChanged(row:CustomerOrderMenu) {
    // alert("test " + row.menuItemName);
    this.processDynamicChanges();
    /*
       this.subject =  "Changes to your Order #" + this.order.customerOrderId;
       this.message = this.getRefundAmount();
       this.message = this.message + "Followind changes were made to your Order \n";
       this.message = this.message + this.generateMessageText();
       */
   }

  onMessageChange(row:CustomerOrderMenu) {
   // alert("test " + row.menuItemName);
      this.processDynamicChanges();
  }

  processDynamicChanges() {
      this.subject =  "Changes to your Order #" + this.order.customerOrderId;
      var emailMess:string = this.generateMessageText();
      this.message = this.getRefundAmount();
      this.message = this.message + "<p>Following changes were made to your Order </p>\n";
      this.message = this.message + emailMess;
      this.messageForm?.get('subject')?.setValue(this.subject);
      this.messageForm?.get('message')?.setValue(this.message);
      this.messageForm?.get('refund')?.setValue(Number(this.refundAmount.toFixed(2)));
  
  }
  getRefundAmount():string {
    return "Refund Amount : $" + Number(this.refundAmount.toFixed(2)) + "\n\n";
  }
  generateMessageText():string {
    var totalRefund:number = 0.0;
    var emailContent:string = "";
    if (this.order.menuList.length == 0) {
      emailContent = "";
      return emailContent;
    }
    var atleastOneChangeExists:boolean = false;
    this.order.menuList.forEach(row => {
     
      
      if (row.status == 1) {
        atleastOneChangeExists = true;
        emailContent+= "   <li>";
        emailContent+= row.menuItemName;
        emailContent+= " : ";
        if (row.action == 1 || row.action == 2) {
          emailContent+= row.message;
          
          totalRefund = totalRefund + Number(row.refundAmt);
        } 
        emailContent+= "   </li>\n";
      }
     
    }
  );
  if (atleastOneChangeExists) {
    emailContent= "\n<ul>\n" + emailContent;
    emailContent= emailContent + "</ul>\n";
   
  }
  //totalRefund = totalRefund.toFixed(2);
  this.refundAmount = Number(totalRefund.toFixed(2)) ;
    
  return emailContent;
  }

  closeWindow(){
    this.dialogRef.close(true);
  }

  onSendCustomer () {
    var list:Array<CustomerOrderMenu> = new Array();
    var message:string = "NONE";
   
    this.order.menuList.forEach(row => {
      if (row.status == 1) {
        //list.push(row.customerOrderId )
        /*
        if (row.action != 1 && row.action != 2) {
          message = "Please select what changed for item : " + row.menuItemName;
         } else if (row.action == 1 || row.action == 2) {
          if (row.message.length == 0) {
            message = "please enter message for item : " + row.menuItemName;
             }
        }
        */
        list.push(row);
      }
    });
        this.isLoading = true;
        var changes:CustomerOrderChanges = new CustomerOrderChanges();
        changes.orderId = this.order.customerOrderId;
        changes.orderItems = list;
        if (this.userType == 'Admin') {

          changes.subject = this.messageForm?.get('subject').value;
          changes.message = this.messageForm?.get('message').value;
          changes.refundAmount = this.messageForm?.get('refund').value;
          changes.customerId = this.order.customerId;
         // alert (changes.customerId);
        }
      
         this.adminService.approveOrderChanges(changes)
      
             .subscribe(result => {
              this.isLoading = false;
              this.dialogRef.close(true);
             }
            );
          
   
    
   return list;
  }

  onSubmitForApproval() {
    var list:Array<CustomerOrderMenu> = new Array();
    var message:string = "NONE";
    if (this.order.changedDuringCustomerOrder > 1) {
      this.openSnackBar("Already Approved. Cannpt change now.",CONSTANTS.FAILURE_MESSG_STYLE);
      return;
    }
    this.order.menuList.forEach(row => {
      if (row.status == 1) {
        //list.push(row.customerOrderId )
        if (row.action != 1 && row.action != 2) {
          message = "Please select what changed for item : " + row.menuItemName;
         } else if (row.action == 1 || row.action == 2) {
          if (row.message.length == 0) {
            message = "please enter message for item : " + row.menuItemName;
             }
        }
        list.push(row);
      }
    });
  /*
    if (list?.length == 0) {
      this.openSnackBar("You don't have any changes to submit for Approval.",CONSTANTS.FAILURE_MESSG_STYLE);
    } else {
      */
     // if (message  === 'NONE') {
        //this.openSnackBar("Do submit",CONSTANTS.FAILURE_MESSG_STYLE);
        this.isLoading = true;
        var changes:CustomerOrderChanges = new CustomerOrderChanges();
        changes.orderId = this.order.customerOrderId;
        changes.orderItems = list;
        if (this.userType == 'Admin') {

          changes.subject = this.messageForm?.get('subject').value;
          changes.message = this.messageForm?.get('message').value;
          alert (changes.subject);
        }
         this.adminService.setOrderChanges(changes)
      
             .subscribe(result => {
              this.isLoading = false;
              this.dialogRef.close(true);
             }
            );
     /* } else {
        this.openSnackBar(message,CONSTANTS.FAILURE_MESSG_STYLE);
      }
    }
    */
    
   return list;
  }

  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }

  isAllSelected() {

   
    if (this.dataSource == null) {
      return;
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  setSendButtonStatusMasterCheckbox(){
     /*
    if(this.selection.hasValue() ) {
      this.disableReportsButton  = false;
    } else {
      this.disableReportsButton  = true;
    }
    */
    return this.selection.hasValue() && this.isAllSelected();
  }

  setSendButtonStatus(ev:Event, row:any){
   /*
    this.changesArr = new Array();
    this.dataSource.data.forEach(row => {
      if (this.selection.isSelected(row) ) {
        this.changesArr.push(row );
      }
    });
    var selCt:number = 0;
    if ( this.selection.isSelected(row)) {
      selCt = this.changesArr.length-1;
    } else {
      selCt = this.changesArr.length+1;
    }
    */
    if ( row.status == 1)
      {
        row.status = 0
      }  else {
        row.status = 1
      }

    if (ev) {
      this.selection.toggle(row) 
    }else {
      return null;
    } 

    this.processDynamicChanges();
    /*
      this.subject =  "Changes to your Order #" + this.order.customerOrderId;
      this.message = this.getRefundAmount();
      this.message = this.message + "Followind changes were made to your Order \n";
      this.message = this.message + this.generateMessageText();
  */
  }
  checkboxLabel(row?: CustomerOrderMenu): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.menuItemId}`;
  }

  masterToggle() {
    if (this.dataSource == null) {
      return;
    }
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  actionChanged(event: MatRadioChange, row:CustomerOrderMenu) {
    row.action = event.value;
    if (event.value == 1) {
      row.message = "Not Available. Refunded.";
      if (Number(row.refundAmt ) == 0 ) {
        row.refundAmt = (row.count * row.salePrice).toFixed(2);
    //    alert(" equal to zero");
      } else {
  //      alert(" not equal to zero");
      }
    } else if (event.value == 2) {
      row.message = "";
    }
   this.processDynamicChanges();
    /*
    this.subject =  "Changes to your Order #" + this.order.customerOrderId;
    this.message = this.getRefundAmount();
    this.message = this.message + "Followind changes were made to your Order \n";
    this.message = this.message + this.generateMessageText();
    */

  //  alert(event.value);
    /*
    if (this.activeLink == this.links[0]) {
        this.mealType = event.value;
        this.isLoading = true;
        this.adminService.getAllMenuCategories(this.storeId + "", this.mealType + "")
            .subscribe(result =>  {
              this.isLoading = false; 
              
              if (result.items != null && result.items.length > 0) {
                this.CatagoriesList = result.items as MenuCategory[];
              }
              
            });  

        

      } else if (this.activeLink == this.links[1]) {
          console.log("entered");
          this.mealType = event.value;

          this.isLoading = true;
          this.adminService.getAllMenuItems(this.storeId , this.mealType)
              .subscribe(result =>  {
                
                if (result.items != null && result.items.length > 0) {
                  this.MealTypeMenuList = result.items as MenuItem[];
                }
                this.isLoading = false; 
              }); 
          console.log("begore getting categories NATHAN")    
          this.getCategories();
         
      } else  if (this.activeLink == this.links[2]) {
        
      }
      */
  }
}
