import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../_models/user';
import { UserService } from '../user.service';

import firebase from '@firebase/app';
import '@firebase/auth';
import {AuthenticationService} from '../authentication.service';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements OnInit {
  loading = false;
  users: User[];
  private user:User;

  constructor(private userService: UserService, private authService: AuthenticationService) { 
    this.authService.currentUser.subscribe(x => this.user = x);
  }
  webToken: String = "yyy";

  ngOnInit(): void {

  //  registerForWebNotifications(this.user.id);
    //registerForWebNotifications(1);
    this.loading = true;
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.loading = false;
      this.users = users;

    });
    //  this.dothis();

    // alert(this.webToken);
  }
  /*
    dothis() {
     var config = {
        apiKey: "AIzaSyBMrNCfn65rXmMB6R949F4sT6R6QM4y_qM",
        authDomain: "iperkz-77e5f.firebaseapp.com",
        databaseURL: "https://iperkz-77e5f.firebaseio.com",
        storageBucket: "iperkz-77e5f.appspot.com",
        messagingSenderId: "934949116876",
    };
    firebase.initializeApp(config);
    navigator.serviceWorker.register('/assets/firebase-messaging-sw.js')
      const messaging = firebase.messaging();
  
    
      messaging
          .requestPermission()
          .then(function () {
             // MsgElem.innerHTML = "Notification permission granted." 
              console.log("Notification permission granted.");
  
              // get the token in the form of promise
              return messaging.getToken()
          })
          .then(function(token) {
            alert(token);
             // TokenElem.innerHTML = "token is : " + token
          })
          .catch(function (err) {
             // ErrElem.innerHTML =  ErrElem.innerHTML + "; " + err
              console.log("Unable to get permission to notify.", err);
          });
  
      messaging.onMessage(function(payload) {
          console.log("Message received. ", payload);
         // NotisElem.innerHTML = NotisElem.innerHTML + JSON.stringify(payload) 
      });
  }
  
   // firebase.initializeApp(config);
  */
}
