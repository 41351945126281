import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReportUpdateData } from '../DialogData';
import { OrderService } from '../order.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Order, PrebookOrderReportItem } from '../order';
import { DatePipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { ReportDataUpdate } from '../OrderReportCriteria';

@Component({
  selector: 'app-report-data-update',
  templateUrl: './report-data-update.component.html',
  styleUrls: ['./report-data-update.component.css']
})
export class ReportDataUpdateComponent implements OnInit {

  reportDataUpdateForm: FormGroup;
  menuId:number;
  customerOrderItemId:number;
  changeForDate:Date;
  reportRow:PrebookOrderReportItem;

  constructor(private datePipe:DatePipe,
    public dialogRef: MatDialogRef<ReportDataUpdateComponent>, 
     @Inject(MAT_DIALOG_DATA) public data: ReportUpdateData, 
     private orderService:OrderService,
     private formBuilder:FormBuilder,
     private _snackBar: MatSnackBar) { 
      this.reportRow = data.preBookItem;
      this.createMenuUpdateForm();

  }

  ngOnInit(): void {
  }

  createMenuUpdateForm(){
     var date:Date = new Date();
    /*
     var items = dateText?.split('-');
     var formattedDate = items[1] + '/'+items[2]+ '/' + items[0];
    */
      this.reportDataUpdateForm = this.formBuilder.group({
          orgPrice: [this.reportRow.originalPrice,  Validators.required],  
          changeForDate: [date, Validators.required],
          changeMasterRecord: [false, Validators.required] 
      });
    }

    closeWindow(){
      this.dialogRef.close(false);   
    }

    onSubmit() {
      /*
      alert(this.reportDataUpdateForm.get('orgPrice').value);
      alert(this.reportDataUpdateForm.get('changeMasterRecord').value);
      alert(this.datePipe.transform(this.reportDataUpdateForm.get('changeForDate').value , 'yyyy-MM-dd', 'en-US'));
     */
      var updateItem:ReportDataUpdate = new ReportDataUpdate();
      updateItem.changeMasterRecord = this.reportDataUpdateForm.get('changeMasterRecord').value;
      updateItem.menuId = this.reportRow.menuId;
      updateItem.orgPrice = this.reportDataUpdateForm.get('orgPrice').value;
      updateItem.changeForDate = this.reportDataUpdateForm.get('changeForDate').value;
      this.orderService.updatePandLRecord(updateItem).subscribe (
        result=> {
          this.dialogRef.close(true);   
        }
      );
      
    }

    onUpdateMasterRecord (){

    }
}
