<h1>{{customerOrder.customerOrderId}} - {{customerOrder.firstName}}</h1>

<mat-form-field appearance="fill">
    <mat-label >Reason for Cancellation</mat-label>
    <input matInput placeholder="Input" [(ngModel)]="reason"  ng-valid> 
  </mat-form-field>
    
&nbsp;<button mat-raised-button color="primary" (click)="cancelOrder()">Cancel</button>&nbsp;
<button mat-raised-button color="primary" (click)="activateOrder()">Process</button>
<br>
<button mat-raised-button color="primary" (click)="closeWindow()">Close</button>
