import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DailySpecial } from './dailyspecial';
import { SimpleResponse } from './SimpleResponse';
import { RequestStatus, Wrapper } from './constants';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DailyspecialsService {
  /*
  private dailySpecialUrl = 'http://localhost:8080/api/dailyspecial/';  // URL to web api
  private createUrl = 'http://localhost:8080/api/dailyspecial';
  private uploadImageUrl = "http://localhost:8080/api/dailyspecial/image";
  private deleteUrl = "http://localhost:8080/api/dailyspecial/{storeId}/{menuId}";
  private sendNotificationsUrl = "http://localhost:8080/api/dailyspecial/notifications";


  private dailySpecialUrl = 'http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/dailyspecial/';  // URL to web api
  private createUrl = 'http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/dailyspecial';
  private uploadImageUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/dailyspecial/image";
  private deleteUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/dailyspecial/{storeId}/{menuId}"
  private sendNotificationsUrl = "http://ec2-18-222-172-237.us-east-2.compute.amazonaws.com:8080/api/dailyspecial/notifications";
  */
   
 private dailySpecialUrl = environment.baseUrl + 'dailyspecial/';
 private createUrl = environment.baseUrl + 'dailyspecial';
 private uploadImageUrl = environment.baseUrl + 'dailyspecial/image';
 private deleteUrl = environment.baseUrl + 'dailyspecial/{storeId}/{menuId}';
 private sendNotificationsUrl = environment.baseUrl + 'dailyspecial/notifications/{storeId}';
 private categoriesUrl = environment.baseUrl + 'categories';
 private displayOrderUrl = environment.baseUrl + 'dailyspecial/{storeId}';
 

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Accept': '*/*',
    })
  };


  constructor( private http: HttpClient) { }

  createDailySpecial(dailyspecial:DailySpecial): Observable<SimpleResponse> {
    //url: string ;
   
    return this.http.post<SimpleResponse>(this.createUrl, dailyspecial);
  }

  updateDisplayOrder(storeId, data:any): Observable<SimpleResponse> {
    this.displayOrderUrl = this.displayOrderUrl.replace('{storeId}', storeId+'');
    return this.http.put<SimpleResponse>(this.displayOrderUrl, data);
  }

  getDailySpecials (storeId:string): Observable<RequestStatus> {
    return this.http.get<RequestStatus>(this.dailySpecialUrl+storeId);
  }

  getCategories(): Observable<Wrapper> {
    return this.http.get<Wrapper>(this.categoriesUrl);
  }

  uploadImage(formData:FormData):Observable<RequestStatus> {
     let url = this.uploadImageUrl;
     let myHeaders = new HttpHeaders();
     myHeaders.append('Content-Type', 'multipart/form-data');
     let options = {
       headers:myHeaders
     };

     return this.http.post<RequestStatus>(url, formData, {headers:myHeaders});
  }

  callDelete(storeId:number, menuId:number): Observable<boolean> {
    //url: string ;
    var tempUrl = this.deleteUrl.replace('{storeId}', storeId+'');
    tempUrl = tempUrl.replace('{menuId}', menuId+'');
    return this.http.put<boolean>(tempUrl,true, this.httpOptions);
  }

  sendNotifications(arr:Array<number>, storeId: number) {
    var tempUrl = this.sendNotificationsUrl.replace('{storeId}', storeId+'');
    return this.http.post<SimpleResponse>(tempUrl, arr);
  }
}
