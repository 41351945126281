import { Component, OnInit } from '@angular/core';
import { ExpenseItem, ExpenseFrequency, ExpenseType } from '../accounting';
import { OrderService } from '../order.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import {CONSTANTS} from '../constants';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AccountingComponent implements OnInit {

  isLoading: boolean = false;
  expenseItems: ExpenseItem[];
  expenseTypes: ExpenseType[];
  expenseFrequency: ExpenseFrequency[];
  dataSource: any = null;
  displayedColumns: string[] = ['name', 'desc', 'type', 'frequency', 'amount'];
  expenseForm: FormGroup;

  constructor(private orderService: OrderService, private formBuilder: FormBuilder,
    public dialog: MatDialog, private _snackBar: MatSnackBar) {
    console.log("before getexpenseItems")
    this.getExpenseTypes();
    this.getExpenseFrequency();
    this.getExpenseItems();
    this.createExpenseForm();
  }

  ngOnInit(): void {

  }

  getExpenseItems(): void {
    console.log("entered getexpenseItems")
    this.isLoading = true;
    console.log("calling getexpenseItems")
    this.orderService.getExpenseItems()
      .subscribe(result => {
        this.isLoading = false;
        if (result != null) {
          this.expenseItems = result;
          this.dataSource = new MatTableDataSource<ExpenseItem>(this.expenseItems);
        } else {
          this.expenseItems = [];
          this.dataSource = new MatTableDataSource<ExpenseItem>(this.expenseItems);
        }

      });
  }

  getExpenseTypes(): void {
    this.isLoading = true;
    this.orderService.getExpenseTypes()
      .subscribe(result => {
        this.isLoading = false;
        if (result != null) {
          this.expenseTypes = result;
        } else {
          this.expenseTypes = [];
        }

      });
  }

  getExpenseFrequency(): void {
    this.isLoading = true;
    this.orderService.getExpenseFrequency()
      .subscribe(result => {
        this.isLoading = false;
        if (result != null) {
          this.expenseFrequency = result;
        } else {
          this.expenseFrequency = [];
        }

      });
  }


  createExpenseForm() {
    this.expenseForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['',],
      type: [''],
      frequency: ['', Validators.required],
      amount: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.expenseForm.status == 'VALID') {
      var expenseItem: ExpenseItem = new ExpenseItem();

      expenseItem.name = this.expenseForm.get('name').value;
      expenseItem.description = this.expenseForm.get('description').value;
      expenseItem.expenseType = this.expenseForm.get('type').value;
      expenseItem.frequency = this.expenseForm.get('frequency').value;
      expenseItem.amount = this.expenseForm.get('amount').value;
      console.log(expenseItem);
      this.orderService.createExpenseItem(expenseItem)
        .subscribe(result => {
          if (result.result == true) {
            //this.contactForm.reset();
            this.openSnackBar('Expense Item Created', CONSTANTS.SUCCESS_MESSG_STYLE)
          } else {
            this.openSnackBar('Failed to create Daily Special', CONSTANTS.FAILURE_MESSG_STYLE)
          }
          this.getExpenseItems();
        }
        );
    } else {
      return;
    }

  }


  openSnackBar(message: string, panelClass: string) {
    
    var matConfig:MatSnackBarConfig = new MatSnackBarConfig();
    matConfig.verticalPosition = "top";
    matConfig.horizontalPosition ="center";
    matConfig.duration = 4000;
    matConfig.panelClass  = [panelClass];
    matConfig.data =message;
    this._snackBar.openFromComponent(SnackbarComponent, matConfig);
  }
}

