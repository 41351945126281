import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OrdersComponent} from './orders/orders.component';
import { DailyspecialsComponent } from './dailyspecials/dailyspecials.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component'
import {AuthGuard} from './_helpers/auth.guard'
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import {AboutComponent} from './about/about.component';
import {CustomerViewComponent} from './customer-view/customer-view.component';
import {SuperAdminComponent} from './super-admin/super-admin.component';
import { TestComponent} from './test/test.component';
import { AccountingComponent } from './accounting/accounting.component';
import {StatuscheckComponent} from './statuscheck/statuscheck.component';
import {PaymentComponent} from './payment/payment.component';
import { RouteComponent } from './route/route.component';
import { TodaysOrdersComponent } from './todays-orders/todays-orders.component'; 
import { Customer } from './constants';
import { CustomersComponent } from './customers/customers.component';
import {InvestorReportComponent } from './investor-report/investor-report.component';
import { ReportComponent } from './report/report.component';
const routes: Routes = [

  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] , runGuardsAndResolvers:'always' },
  { path: 'dailySpecials', component: DailyspecialsComponent, canActivate: [AuthGuard] },
  { path: 'menuAdmin', component: MenuAdminComponent, canActivate:[AuthGuard]},
  { path: 'customers', component: CustomersComponent, canActivate:[AuthGuard]},
  { path: 'customerView', component: CustomerViewComponent, canActivate:[AuthGuard]},
  { path: 'statuscheck', component: StatuscheckComponent},
  { path: 'accounting', component: AccountingComponent},
  
  {path: 'about', component: AboutComponent, canActivate:[AuthGuard]},
  {path: 'tracking', component: RouteComponent},
  {path: 'error', component:OrdersComponent, canActivate:[AuthGuard]},
  {path:'superadmin', component:SuperAdminComponent, canActivate:[AuthGuard]},
  {path:'test', component:TestComponent, canActivate:[AuthGuard]},
  {path:'payment', component:PaymentComponent, canActivate:[AuthGuard]},
  {path:'sales', component:InvestorReportComponent, canActivate:[AuthGuard]},
  {path:'report', component:ReportComponent, canActivate:[AuthGuard]},
  {path:'todays', component:TodaysOrdersComponent, canActivate:[AuthGuard]},
  
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
